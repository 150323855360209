import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Search from '../assets/SVG/Vector.svg';
import axios from '../api/axios';
import useAuth from '../hooks/useAuth';
import SelectedUserCard from '../components/card/SelectedUserCard';
import Button from '../shared/Button';
import IOSDialog from '../shared/IOSDialog';
import BackBtn from '../shared/BackBtn';
import SelectableUserCard from '../components/card/SelectableUserCard';

const ReadyToMeet = () => {
  const [searchInput, setSearchInput] = useState('');
  const [userList, setUserList] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedUsersLists, setSelectedUsersLists] = useState([[], [], [], []]); // Four arrays for four days
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDay, setCurrentDay] = useState(0); // Track the currently selected day
  const navigate = useNavigate();
  const location = useLocation();

  const USER_SEARCH_URL = '/user/searchLimitPage';
  const { getAccessToken } = useAuth();
  const scrollElement = useRef(null);

  const goBack = () => {
    navigate(-1);
  };

  const getProfile = async () => {
    if (selectedUsersLists.some(list => list.length === 0)) return setShowDialog(true);
    const token = getAccessToken();
    const queryParams = new URLSearchParams(location.search);
    const platform = queryParams.get('platform');

    try {
      const res = await axios.post(
        '/ready-to-meet',
        { day1: selectedUsersLists[0], day2: selectedUsersLists[1], day3: selectedUsersLists[2], day4: selectedUsersLists[3], platform },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            platform: platform === 'android' ? 'android' : undefined,
          },
        }
      );
      if (res.data.result === 1) navigate(-1);
    } catch (err) {
      console.log('Error', err);
    }
    return null;
  };

  const handleChange = (value) => {
    setCurrentPage(1);
    setSearchInput(value);
  };

  const handleUserClick = (user) => {
    setSelectedUsersLists((prevSelectedUsersLists) => {
      const newList = [...prevSelectedUsersLists];
      const dayList = newList[currentDay];
      const userIndex = dayList.findIndex((u) => u.id === user.id);
      if (userIndex === -1) {
        newList[currentDay] = [...dayList, user];
      } else {
        newList[currentDay] = dayList.filter((u) => u.id !== user.id);
      }
      return newList;
    });
  };

  const fetchUsers = async () => {
    const token = getAccessToken();
    const searchInfo = {
      page: currentPage,
      limit: 50,
      keyword: searchInput,
      role: 'affiliate',
    };
    try {
      const res = await axios.post(USER_SEARCH_URL, searchInfo, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res && res.data && res.data.result === 1) {
        if (currentPage === 1) {
          setUserList(res.data.users);
        } else {
          setUserList((prevUsers) => [...prevUsers, ...res.data.users]);
        }
      }
    } catch (err) {
      console.log('Error', err);
    }
  };

  const handleScroll = (scroller = scrollElement.current) => {
    if (
      scroller.target.scrollTop + scroller.target.clientHeight >=
      scroller.target.scrollHeight
    ) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    fetchUsers();
    scrollElement.current.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [searchInput]);

  useEffect(() => {
    if (currentPage > 1) {
      fetchUsers();
    }
  }, [currentPage]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceDroppableIndex = parseInt(result.source.droppableId.split('-')[1], 10);
    const destinationDroppableIndex = parseInt(result.destination.droppableId.split('-')[1], 10);

    setSelectedUsersLists((prevSelectedUsers) => {
      const newSelectedUsersList = [...prevSelectedUsers];
      const sourceList = Array.from(newSelectedUsersList[sourceDroppableIndex]);
      const [movedItem] = sourceList.splice(result.source.index, 1);
      const destinationList = Array.from(newSelectedUsersList[destinationDroppableIndex]);

      if (sourceDroppableIndex === destinationDroppableIndex) {
        sourceList.splice(result.destination.index, 0, movedItem);
        newSelectedUsersList[sourceDroppableIndex] = sourceList;
      } else {
        destinationList.splice(result.destination.index, 0, movedItem);
        newSelectedUsersList[sourceDroppableIndex] = sourceList;
        newSelectedUsersList[destinationDroppableIndex] = destinationList;
      }

      return newSelectedUsersList;
    });
  };

  const fillRandomUsers = () => {
    setSelectedUsersLists((prevSelectedUsersLists) => {
      const newList = [...prevSelectedUsersLists];
      const shuffledUsers = userList.sort(() => 0.5 - Math.random());
      for (let i = 0; i < 4; i+= 1) {
        newList[i] = shuffledUsers.slice(0, 10);
      }
      return newList;
    });
  };

  const copyCurrentDayToAll = () => {
    setSelectedUsersLists((prevSelectedUsersLists) => {
      const newList = [...prevSelectedUsersLists];
      for (let i = 0; i < 4; i+= 1) {
        if (i !== currentDay) {
          newList[i] = [...newList[currentDay]];
        }
      }
      return newList;
    });
  };

  return (
    <div className="h-screen overflow-y-scroll" ref={scrollElement}>
      <header className="flex items-center px-6 py-15px bg-white overflow-hidden font-sans gap-3">
        <BackBtn handleClick={() => goBack()} />
        <h2 className="text-black text-2xl font-bold">Ready to meet</h2>
      </header>
      <div className="mt-5 mx-5">
        <div className="border-0.5px px-4 py-3 rounded-3xl w-full font-semibold text-sm textbox-highlight">
          <div className="flex justify-between items-stretch w-full">
            <input
              type="search"
              className="text-sm font-normal w-11/12 text-black text-opacity-40 focus:outline-none"
              placeholder=" search for a user"
              value={searchInput}
              onChange={(e) => handleChange(e.target.value)}
            />
            <button type="button">
              <img src={Search} alt={Search} />
            </button>
            <Button
              className={`btn border border-[#e7002c] flex justify-center items-center text-center font-bold px-2 py-1 rounded-sm text-[#e7002c]${selectedUsersLists.flat().length === 0 ? ' opacity-50' : ''
                }`}
              type="button"
              onClick={getProfile}
              disabled={selectedUsersLists.flat().length === 0}
            >
              Finish
            </Button>
          </div>
        </div>
        <div className="flex justify-around mt-4">
          {[1, 2, 3, 4].map((day) => (
            <button
            type='button'
              key={day}
              className={`py-2 px-4 rounded-lg ${
                currentDay === day - 1 ? 'bg-gray-300' : 'bg-gray-200'
              }`}
              onClick={() => setCurrentDay(day - 1)}
            >
              Day {day}
            </button>
          ))}
        </div>
        <div className="flex justify-around mt-4">
          <Button type="button" onClick={fillRandomUsers}>
            Fill Random Users
          </Button>
          <Button type="button" onClick={copyCurrentDayToAll}>
            Copy to All Days
          </Button>
        </div>
        <div className="mt-4">
          <h3 className="font-semibold">Day {currentDay + 1}</h3>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`droppable-${currentDay}`}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex flex-row overflow-x-auto w-full h-full gap-4 py-4 snap-x snap-mandatory"
                >
                  {selectedUsersLists[currentDay].map((user, index) => (
                    <Draggable key={user.id} draggableId={user.id} index={index}>
                      {(providedTwo) => (
                        <div
                          ref={providedTwo.innerRef}
                          {...providedTwo.draggableProps}
                          {...providedTwo.dragHandleProps}
                          className="flex-none w-20 h-25 rounded-md overflow-hidden snap-start shadow-md"
                        >
                          <SelectedUserCard
                            id={user.id}
                            name={user.name}
                            about={user.firstname}
                            avatar={user.avatar}
                            user={user}
                            onRemoveClick={() => handleUserClick(user)}
                          />
                          <p className="text-xs text-center">{user.firstname}</p>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="mt-4">
          {userList.map((user) => (
            <SelectableUserCard
              key={user.id}
              id={user.id}
              name={user.name}
              about={user.firstname}
              avatar={user.avatar}
              onClick={() => handleUserClick(user)}
            />
          ))}
        </div>
      </div>
      <IOSDialog
        isOpen={showDialog}
        update={() => setShowDialog(false)}
        title="Select users for each day"
      >
        <div className="mt-0.5 px-4 pb-5 text-[13px] text-center">
          You have to select at least 1 user for each day to continue.
        </div>
        <button
          onClick={() => setShowDialog(false)}
          type="button"
          className="border-t-0.5px w-full border-gray-divider py-3 text-[#007AFF] text-lg leading-5"
        >
          OK
        </button>
      </IOSDialog>
    </div>
  );
};

export default ReadyToMeet;
