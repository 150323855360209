import { io } from "socket.io-client";
import API_HOST from "../Environment/Environment";

const socket = io(API_HOST, {
  autoConnect: false,
  reconnectionAttempts: Infinity, // Retry forever until connected
  reconnectionDelay: 1000,        // Start with 1 second delay
  reconnectionDelayMax: 5000,     // Maximum delay between retries is 5 seconds
  timeout: 20000,                 // 20 seconds timeout
  transports: ['polling','websocket', 'webtransport'],     
});

// Add event listeners for better error handling and debugging
socket.on('connect_error', (error) => {
  console.error('Connection Error:', error);
});

socket.on('reconnect_failed', () => {
  console.error('Reconnection Failed');
});

socket.on('disconnect', (reason) => {
  console.log('Disconnected:', reason);
  // Optionally, add custom logic to handle disconnection
  // For example, notify the user or attempt to reconnect manually
});

socket.on('connect', () => {
  console.log('Socket connected');
 // fetchUserList();
});

socket.on('reconnect', (attempt) => {
  console.log('Reconnected after attempt:', attempt);
});

socket.on('reconnecting', (attempt) => {
  console.log('Reconnecting attempt:', attempt);
});

export default socket;
