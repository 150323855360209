import React, { useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import closeBtn from '../../assets/SVG/close.svg'
import playBtn from '../../assets/SVG/sentPlay.svg'
import pauseBtn from '../../assets/SVG/sentPause.svg'
import LockSwitch from '../../shared/LockSwitch'
import CoinModal from './CoinModal'
/* eslint-disable jsx-a11y/media-has-caption */

const Preview = ({
  file,
  fileUrl,
  showPreview,
  closeFileupload,
  sendMedia,
  thumbnail,
  setcoins,
}) => {
  const sendBtn = useRef(null)
  const videoRef = useRef(null)
  let timer
  const [isLocked, setisLocked] = useState(false)
  const [isPlaying, setisPlaying] = useState(false)
  const [type, settype] = useState('')
  const [fadeOut, setfadeOut] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isSending, setIsSending] = useState(false)

  const uploadToServer = async () => {
    console.log('file ready to be uploaded')
    setIsSending(true)
    const res = await sendMedia(isLocked)
    setIsSending(res)
  }

  const toggleControl = () => {
    if (isPlaying) videoRef.current.pause()
    else videoRef.current.play()
    setisPlaying(!isPlaying)
    if (!timer) timer = setTimeout(() => setfadeOut(true), 2000)
  }
  const toggleFadeOut = () => {
    setfadeOut(false)
    timer = setTimeout(() => setfadeOut(true), 3000)
  }
  useEffect(() => {
    if (isLocked) setisOpen(true)
    console.log(file.type.split('/')[0])
    settype(file.type.split('/')[0])
    return clearTimeout(timer)
  }, [isLocked])

  return (
    <Transition
      show={showPreview}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={closeFileupload}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black-dark -z-10 pointer-events-none" />
        <div className="z-30 h-screen flex items-center justify-center">
          <button
            type="button"
            onClick={closeFileupload}
            className="absolute top-10 right-5 z-40"
          >
            <img
              src={closeBtn}
              alt={closeBtn}
              style={{
                filter: 'drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.7))',
              }}
            />
          </button>
          {type === 'image' ? (
            <img
              className="w-full z-30 h-full object-cover object-center"
              alt={fileUrl}
              src={fileUrl}
              loading="lazy"
              onLoad={() => setIsLoading(false)}
            />
          ) : (
            <div className="flex z-30 items-center h-full w-full justify-center">
              <video
                poster={thumbnail}
                onEnded={() => {
                  setisPlaying(!isPlaying)
                  setfadeOut(false)
                }}
                onClick={toggleFadeOut}
                ref={videoRef}
                className="w-full object-cover h-full object-center aspect-[9/16]"
                alt={fileUrl}
                src={fileUrl}
                onLoad={() => setIsLoading(false)}
              />
              <button
                onClick={toggleControl}
                type="button"
                className={`rounded-full z-40 absolute w-16 h-16 bg-PurpleGradient transition-opacity flex justify-center items-center focus:outline-none ${
                  fadeOut ? 'opacity-0 pointer-events-none' : 'opacity-100'
                }`}
              >
                <img
                  className="w-8 h-8"
                  src={isPlaying ? pauseBtn : playBtn}
                  alt="control"
                />
              </button>
            </div>
          )}
          {isLoading && (
            <div className="flex items-center justify-center fixed">
              <div
                className="w-20 h-20 border-t-4 border-b-4 rounded-full animate-spin"
                style={{ borderTopColor: 'aliceblue' }}
              />
            </div>
          )}
          <div className="flex pl-5 pr-6 z-40 justify-between absolute w-full bottom-6">
            <LockSwitch locked={isLocked} setlocked={setisLocked} />
            {isSending ? (
              <svg
                aria-hidden="true"
                className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-200 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ fill: 'lightcyan', color: 'lightgray' }}
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            ) : (
              <button
                type="button"
                onClick={uploadToServer}
                className="mr-1.5"
                ref={sendBtn}
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  stroke="#000"
                  strokeWidth="0.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    filter: 'drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.7))',
                  }}
                >
                  <path
                    d="M6.653 6.113 26.11 15.42a1.153 1.153 0 0 1 0 2.082L6.653 26.807a1.155 1.155 0 0 1-1.612-1.345l1.946-7.136a.461.461 0 0 1 .384-.336l9.427-1.28a.23.23 0 0 0 .18-.138l.017-.059a.23.23 0 0 0-.145-.247l-.05-.014-9.42-1.28a.462.462 0 0 1-.382-.336L5.041 7.46a1.154 1.154 0 0 1 1.612-1.346Z"
                    fill="#fff"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>{' '}
        {isLocked && (
          <CoinModal
            isOpen={isOpen}
            setisOpen={setisOpen}
            setcoins={setcoins}
            setisLocked={setisLocked}
          />
        )}
      </Dialog>
    </Transition>
  )
}

export default Preview
