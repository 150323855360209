import React from 'react'
import backIcon from '../assets/SVG/backIcon.svg'

const BackBtn = ({ handleClick }) => (
  <button className="focus:outline-none" onClick={handleClick} type="button">
    <img src={backIcon} alt={backIcon} />
  </button>
)

export default BackBtn
