import React, { useState, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import closeBtn from '../../assets/SVG/close.svg'
import playBtn from '../../assets/SVG/sentPlay.svg'
import pauseBtn from '../../assets/SVG/sentPause.svg'
/* eslint-disable jsx-a11y/media-has-caption */

const OpenVideo = ({ videoSrc, thumbnail, isOpen, setisOpen }) => {
  const [isPlaying, setisPlaying] = useState(false)
  const [fadeOut, setfadeOut] = useState(false)
  let timer
  const videoRef = useRef(null)
  const close = () => {
    setisOpen(false)
  }
  const toggleControl = () => {
    if (isPlaying) videoRef.current.pause()
    else videoRef.current.play()
    setisPlaying(!isPlaying)
    if (!timer) timer = setTimeout(() => setfadeOut(true), 2000)
  }
  const toggleFadeOut = () => {
    setfadeOut(false)
    timer = setTimeout(() => setfadeOut(true), 3000)
  }
  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog className="fixed z-50 inset-0 overflow-y-auto" onClose={close}>
        <Dialog.Overlay className="fixed inset-0 bg-greyGradient -z-10 pointer-events-none" />
        <div className="z-30 h-screen justify-center flex items-center">
          <button
            type="button"
            onClick={close}
            className="absolute top-7 right-5 z-40"
          >
            <img src={closeBtn} alt={closeBtn} />
          </button>
          <video
            poster={thumbnail}
            onEnded={() => {
              setisPlaying(!isPlaying)
              setfadeOut(false)
            }}
            onClick={toggleFadeOut}
            ref={videoRef}
            className="w-full object-contain h-full object-center aspect-[9/16]"
            alt={videoSrc}
            src={videoSrc}
          />
          <button
            onClick={toggleControl}
            type="button"
            className={`rounded-full z-40 absolute w-16 h-16 bg-PurpleGradient transition-opacity flex justify-center items-center focus:outline-none ${
              fadeOut ? 'opacity-0 pointer-events-none' : 'opacity-100'
            }`}
          >
            <img
              className="w-8 h-8"
              src={isPlaying ? pauseBtn : playBtn}
              alt="control"
            />
          </button>
        </div>
      </Dialog>
    </Transition>
  )
}

export default OpenVideo
