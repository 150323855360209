import React from 'react'
import Logo from '../shared/Logo'

const EntryLayout = ({ children }) => (
  <div className="font-sans my-14 mx-5">
    <Logo />
    {children}
  </div>
)

export default EntryLayout
