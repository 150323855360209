import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const ChartProfits = ({ dateRange }) => {
  const [chartData, setchartData] = useState({
    labels: [],
    data: [],
  });
  const [earnings, setearnings] = useState({});
  const { getAccessToken } = useAuth();
  const token = getAccessToken();
  const DATA_CALENDAR_DATE = "/affiliate/getDataCalendar";

  // honee
  const fetchAffiliateStats = async () => {
    const data2 = {
      startDate: dateRange[0].toISOString().split("T")[0],
      endDate: dateRange[1].toISOString().split("T")[0],
    };
    try {
      const response = await axios.post(DATA_CALENDAR_DATE, data2, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        const { dailyEarnings, totalEarnings } = response.data;

        const labels = dailyEarnings.map((earning) => earning.date);
        const data = dailyEarnings.map((earning) => earning.dollars);

        setchartData({
          labels,
          data,
        });

        setearnings({
          monthly: totalEarnings.dollars,

          total: totalEarnings.dollars,
          balance: response?.data?.userObject?.balance,
        });
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  // honee
  useEffect(() => {
    fetchAffiliateStats();
  }, [dateRange]);

  const getData = () => ({
    labels: chartData.labels,
    datasets: [
      {
        label: "",
        data: chartData.data,
        fill: "start",
        backgroundColor: (context) => {
          const { ctx } = context.chart;
          const gradient = ctx.createLinearGradient(0, 0, 300, 0);
          gradient.addColorStop(0, "rgba(230, 46, 102, 1)");
          gradient.addColorStop(1, "rgba(231, 0, 44, 1)");
          return gradient;
        },
        borderColor: "transparent",
        strokeColor: "black",
        pointBackgroundColor: "#353535",
        pointStyle: "circle",
        pointRadius: 2.5,
        pointBorderWidth: 0.5,
        pointBorderColor: "#fff",
        pointHoverRadius: 7,
        pointHoverBorderWidth: 3,
        tension: 0.45,
      },
    ],
  });
  const options = {
    plugins: {
      title: {
        display: true,
        text:
          chartData.data?.length === 0
            ? "Select calendar events to show Chart"
            : "",
        position: "top",
      },
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        yAlign: "bottom",
        titleFontFamily: "nunito",
        backgroundColor: "#2D2D2D",
        titleFontColor: "white",
        titleAlign: "center",
        titleFont: {
          size: 10,
          lineHeight: "14px",
        },
        bodyAlign: "center",
        bodyFont: {
          size: 12,
          lineHeight: "16px",
          weight: "bold",
        },
        bodySpacing: 3,
        caretSize: 5,
        caretPadding: 20,
        cornerRadius: 10,
        xPadding: 14,
        yPadding: 6,
        callbacks: {
          title: (data) => {
            console.log(data);
            const title = data[0].label;
            return title;
          },
          label: (data) => {
            console.log(data);
            const label = `${data.formattedValue} $`;
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        display: false,
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="font-sans" style={{ padding: " 0 1.25rem" }}>
      <Line data={getData()} options={options} />
      <div className="mt-7 flex justify-between space-x-8">
        <div className="px-8 py-2.5 text-xs text-white rounded-20px bg-blackGradient text-center flex-1">
          Total Profits($)
          <br />
          <span className="text-base font-bold mt-2.5">{`${
            earnings.total !== undefined
              ? earnings.total.toFixed(2) // Display with two decimal places
              : "0.00" // Default value with two decimal places
          } $`}</span>
        </div>
      </div>
    </div>
  );
};

export default ChartProfits;
