import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useReactMediaRecorder } from "react-media-recorder";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import socket from "../socket/socket";
import BackBtn from "../shared/BackBtn";
import sendBtn from "../assets/SVG/send.svg";
import addMediaBtn from "../assets/SVG/addMedia.svg";
import closeMediaBtn from "../assets/SVG/closeMedia.svg";
import AutoTextbox from "../shared/AutoTextbox";
import voiceBtn from "../assets/SVG/voiceIcon.svg";
import galleryBtn from "../assets/SVG/galleryIcon.svg";
import IOSButton from "../shared/IOSButton";
import IOSDialog from "../shared/IOSDialog";
import SentMessage from "../components/messages/SentMessage";
import ReceivedMessage from "../components/messages/ReceivedMessage";
import Typing from "../components/messages/Typing";
import Preview from "../components/messages/Preview";
import getThumbnailForVideo from "../components/messages/getThumbailForVideo";
import fallbackImg from "../assets/PNG/group.png";
import LockSwitch from "../shared/LockSwitch";
import CapturePhoto from "../components/messages/CapturePhoto";
import CaptureVideo from "../components/messages/CaptureVideo";
import AudioPlayer from "../components/messages/AudioPlayer";
import CoinModal from "../components/messages/CoinModal";
import AlertBox from "../components/alert/AlertBox";
import Loader from "../shared/loader/Loader";

const Header = ({ handleClick }) => (
  <header className="fixed top-0 flex items-center px-6 py-15px inset-x-0 z-30 bg-white overflow-hidden font-sans">
    <BackBtn handleClick={handleClick} />
    <img
      className="ml-5 w-[40px] h-[40px] rounded-full"
      // src={
      //   user.avatar !== '' || user.avatar !== undefined
      //     ? user.avatar
      //     : fallbackImg
      // }
      src={fallbackImg}
      alt="user.avatar"
    />
    <div className="pl-4">
      <h2 className="font-semibold text-xl">users</h2>

      {/* <div className="flex items-center h-4">
        {status && (
          <>
            <p className="text-xs">Online</p>
            <span className="ml-1.5 w-1.5 h-1.5 bg-[#11CE2F] rounded-full" />
          </>
        )}
      </div> */}
    </div>
  </header>
);

const Footer = ({
  children,
  toggleMedia,
  openGallery,
  sendAudio,
  textboxFocused,
  isLocked,
  setisLocked,
  translateText,
  value,
  setValue,
}) => {
  const [audioFile, setaudioFile] = useState(null);
  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({
      video: false,
      audio: true,
      type: "audio/mp3",
      blobPropertyBag: {
        type: "audio/mp3",
      },
      askPermissionOnMount: false,
      onStop: (blobUrl, blob) => {
        console.log(blobUrl, blob);
        const myFile = new File([blob], "voice.mp3", {
          type: "audio/mp3",
        });
        setaudioFile(myFile);
      },
    });
  const startVoiceRecording = (event) => {
    event.stopPropagation();
    event.preventDefault();
    startRecording();
  };
  const stopAndSend = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    stopRecording();
    // const myFile = new File([mediaBlobUrl], 'voice.mp3', {
    //   type: 'audio/mp3',
    // })
    // setaudioFile(myFile)
  };
  useEffect(() => {
    if (audioFile === null) {
      setisLocked(false);
    }
  }, [audioFile]);

  return (
    <footer className="bottom-0 bg-white shadow-lightBlack absolute w-full overflow-hidden px-5 py-4 font-sans">
      {textboxFocused ? (
        <div className="flex justify-center pb-5 space-x-10">
          <button
            type="button"
            onClick={() =>
              translateText(
                value,
                "EN",
                "DE",
                "0073bb08-d03a-46a3-b32b-685ad917ae35",
              ).then((translatedTexte) => setValue(translatedTexte))
            }
          >
            Translate
          </button>

          <LockSwitch locked={isLocked} setlocked={setisLocked} />
        </div>
      ) : (
        <div
          className={`justify-center space-x-[78px] flex pb-5 text-xs ${
            toggleMedia ? "block" : "hidden"
          } ${audioFile && "hidden"}`}
        >
          <button
            contextMenu="false"
            type="button"
            onTouchStart={startVoiceRecording}
            onTouchMove={startVoiceRecording}
            onTouchEnd={stopAndSend}
            onMouseDown={startVoiceRecording}
            onMouseUp={stopAndSend}
            className={`space-y-1 flex flex-col items-center focus:outline-none `}
          >
            <div className="relative flex justify-center items-center">
              <img
                className={`pointer-events-none h-8 w-8 z-30
                 ${status === "recording" && ""}`}
                src={voiceBtn}
                alt="voice.svg"
              />
              <div
                className={`absolute w-7 h-7 bg-PurpleGradient rounded-full z-20 ${
                  status === "recording" && "animate-ping"
                }`}
              />
            </div>

            <p className="pointer-events-none">
              {status === "recording" ? status : "Voice"}
            </p>
          </button>
          <button
            type="button"
            onClick={openGallery}
            className="space-y-1 flex flex-col items-center"
          >
            <img src={galleryBtn} alt="Gallery.svg" />
            <p>Gallery</p>
          </button>
        </div>
      )}
      {audioFile !== null ? (
        <>
          <div className="flex justify-center pb-5">
            <LockSwitch locked={isLocked} setlocked={setisLocked} />
          </div>
          <AudioPlayer
            sendAudio={sendAudio}
            setaudioFile={setaudioFile}
            audioFile={audioFile}
            audioUrl={mediaBlobUrl}
            clearBlobUrl={clearBlobUrl}
          />
        </>
      ) : (
        children
      )}
    </footer>
  );
};

const ChatBroadcast = () => {
  const [value, setvalue] = useState("");
  const [recipient, setrecipient] = useState({});
  const [messages, setmessages] = useState([]);
  const [isTyping, setisTyping] = useState(false);
  const { getAccessToken, getUserId } = useAuth();
  const token = getAccessToken();
  const userId = getUserId();
  const [isLoading, setisLoading] = useState(true);
  const messagesEndRef = useRef(null);
  const galleryRef = useRef(null);
  const [file, setfile] = useState(null);
  const [fileUrl, setfileUrl] = useState(null);
  const [thumbnail, setthumbnail] = useState(null);
  const [isOpen, setisOpen] = useState({
    gallery: false,
    voice: false,
  });
  const [showPreview, setshowPreview] = useState(false);
  const [toggleMedia, settoggleMedia] = useState(false);
  const [openCamera, setopenCamera] = useState({
    photo: false,
    video: false,
  });
  const [textboxFocused, settextboxFocused] = useState(false);
  const [isLocked, setisLocked] = useState(false);
  const [fileSizeAlert, setfileSizeAlert] = useState(false);
  const [pageNumber, setpageNumber] = useState(1);
  const [endReached, setendReached] = useState(false);
  const [lastIndex, setlastIndex] = useState(0);
  const [openCoinModal, setopenCoinModal] = useState(false);
  const [coins, setcoins] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const selectedUsers = location.state;
  const id = "641e3608b422fd003437625b";

  const CHAT_URL = "/chat";
  const UPLOAD_MEDIA_URL = "/broadcast/upload";
  const CHAT_READ_URL = "/chat/read";
  const UPLOAD_AUDIO_URL = "/broadcast/upload-audio";

  const goBack = () => {
    navigate("/messages");
  };

  const openGalleryDialog = () => {
    setisOpen((prev) => ({ ...prev, gallery: !prev.gallery }));
    console.log("gallery");
  };
  const openVoiceDialog = () => {
    setisOpen((prev) => ({ ...prev, voice: !prev.voice }));
  };
  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };
  const updateUnreadChat = async (readId) => {
    const res = await axios.post(
      CHAT_READ_URL,
      { readId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (res) console.log(res);
  };
  const fetchUserChat = async (page = 1) => {
    const data = {
      userID: id,
      page,
    };
    try {
      const res = await axios.post(CHAT_URL, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res) {
        if (page === 1) {
          setrecipient(res?.data);
          const reversedMessage = res?.data?.messages.reverse();
          setmessages(reversedMessage);
          setisLoading(false);
        } else {
          setendReached(false);
          setrecipient((prev) => ({ ...res.data, ...prev }));
          const reversedMessage = res?.data?.messages.reverse();
          setmessages((prev) => [...reversedMessage, ...prev]);
          setlastIndex(res?.data?.messages.length);
        }
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const generateMessages = (msg) => {
    let component;
    if (msg.fromUser === id) {
      component = (
        <ReceivedMessage
          mediaSrc={msg.downloadURL ? msg.downloadURL : msg.video}
          id={msg?._id}
          type={msg?.type}
          key={msg?._id}
          date={msg?.createdAt}
          displayPic={recipient.userImage}
        >
          {msg.text}
        </ReceivedMessage>
      );
    } else {
      component = (
        <SentMessage
          mediaSrc={msg.downloadURL ? msg.downloadURL : msg.video}
          type={msg?.type}
          key={msg._id ? msg._id : msg.downloadURL}
          id={msg?._id}
          isLocked={msg?.isLocked}
          wasLocked={msg?.wasLocked}
          coins={msg?.diamonds ? msg.diamonds : msg.coins}
          date={msg?.createdAt}
        >
          {msg.text}
        </SentMessage>
      );
    }
    return component;
  };

  const onSend = () => {
    if (value.length === 0) {
      alert("Type something");
      return;
    }

    socket.emit("broadcastMessage", {
      users: selectedUsers,
      text: value,
    });

    setmessages((prev) => [
      ...prev,
      {
        text: value,
        fromUser: userId,
        type: "text",
        isLocked,
        coins,
      },
    ]);
    setisLocked(false);
    setcoins(0);
    setvalue("");
    document.querySelector("#MessageTextBox").textContent = "";
  };
  const sendAudio = async (audioFile, audioUrl) => {
    let fileTemp;
    if (audioFile !== null) {
      console.log(audioFile, coins, isLocked);
      const type = audioFile.type.split("/")[0];
      const audioformData = new FormData();
      audioformData.append("file", audioFile);
      audioformData.append(
        "userIDs",
        JSON.stringify(selectedUsers.map((user) => user.userId)),
      );
      audioformData.append("isLocked", isLocked);
      audioformData.append("coins", coins);
      if (audioformData) {
        fileTemp = {
          fromUser: userId,
          downloadURL: audioUrl,
          type,
          isLocked,
          coins,
        };
        console.log(fileTemp);
        const response = await axios.post(UPLOAD_AUDIO_URL, audioformData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response) {
          console.log(response);
          setmessages((prev) => [...prev, fileTemp]);
          setcoins(0);
          setisLocked(false);
        }
      }
    }
  };
  const openFileManager = () => {
    galleryRef.current.click();
  };
  const useSocket = () => {
    socket.on("message", (msg) => {
      console.log("messages", msg);
      setmessages((prev) => [...prev, msg]);
      updateUnreadChat(id);
    });
    socket.on("startTyping", () => {
      scrollToBottom();
    });
    socket.on("stopTyping", () => {
      setisTyping(false);
    });
    socket.on("media", (msg) => {
      console.log("Media", msg);
      setmessages((prev) => [...prev, msg]);
      updateUnreadChat(id);
    });
  };
  const sendMedia = async (locked) => {
    setisOpen((prev) => ({ ...prev, gallery: !prev.gallery }));
    let fileTemp;
    if (file !== null) {
      console.log(file, typeof coins, typeof locked);
      const type = file.type.split("/")[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "userIDs",
        JSON.stringify(selectedUsers.map((user) => user.userId)),
      );
      formData.append("isLocked", locked);
      formData.append("coins", coins);
      if (formData) {
        if (type === "image") {
          fileTemp = {
            fromUser: userId,
            downloadURL: fileUrl,
            type,
            isLocked: locked,
            coins,
          };
        } else {
          fileTemp = {
            fromUser: userId,
            video: {
              video: fileUrl,
              thumbnail,
            },
            type: "video",
            isLocked: locked,
            coins,
          };
        }
        try {
          setmessages((prev) => [...prev, fileTemp]);
          const response = await axios.post(UPLOAD_MEDIA_URL, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response) {
            console.log("Success");
            setshowPreview(false);

            scrollToBottom();
            URL.revokeObjectURL(file);
            setcoins(0);
            setisLocked(false);
            setfile(null);
          }
        } catch (e) {
          console.log("Error", e);
          return false;
        }
      } else return false;
    }
    return false;
  };

  const closeFileupload = () => {
    console.log("Close file upload");
    if (file !== null) {
      setfile(null);
      URL.revokeObjectURL(file);
      setfileUrl(null);
      setshowPreview(false);
    }
  };

  const scrollEnd = (scroller) => {
    if (scroller.scrollTop === 0) {
      console.log("Hit top");
      setendReached(true);
    }
  };

  // const translateText = async (txt, sourceLang, targetLang, glossaryId) => {
  //   console.log(glossaryId)
  //   try {
  //     const requestHeaders = {
  //       "accept": "/",
  //       "accept-language": `${targetLang},${sourceLang};q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6`,
  //       "content-type": "application/json",
  //       "ngrok-skip-browser-warning": "true",
  //       "sec-ch-ua": "\"Chromium\";v=\"118\", \"Microsoft Edge\";v=\"118\", \"Not=A?Brand\";v=\"99\"",
  //       "sec-ch-ua-mobile": "?1",
  //       "sec-ch-ua-platform": "\"Android\"",
  //       "sec-fetch-dest": "empty",
  //       "sec-fetch-mode": "cors",
  //       "sec-fetch-site": "cross-site",
  //       "Referer": "https://4based.com/",
  //       "Referrer-Policy": "strict-origin-when-cross-origin"
  //     };

  //     const requestBody = JSON.stringify({
  //       input: txt,
  //       target: targetLang.toLowerCase() // Ensure the target language is in lowercase as per the API request.
  //     });

  //     const response = await fetch("https://seagull-allowed-ocelot.ngrok-free.app/translate", {
  //       method: "POST",
  //       headers: requestHeaders,
  //       body: requestBody
  //     });

  //     if (response.ok) {
  //       const jsonResponse = await response.json();
  //       if (jsonResponse.result) {
  //         const translatedText = jsonResponse.result;
  //         console.log(translatedText);
  //         return translatedText;
  //       }
  //       console.error("Translation result not found in response.");
  //     } else {
  //       console.error("Translation failed with status code:", response.status);
  //     }
  //     return "Translation failed"; // Moved outside of the if-else block
  //   } catch (err) {
  //     console.error("Translation error:", err);
  //     return "Translation error";
  //   }
  // };

  const translateText = async (txt, sourceLang, targetLang, glossaryId) => {
    const apiKey = "cd22a526-5da7-16c5-b63c-60b02570c26b";
    try {
      const params = {
        text: txt,
        source_lang: sourceLang,
        target_lang: targetLang,
        auth_key: apiKey,
      };
      if (glossaryId) {
        params.glossary_id = glossaryId;
      }
      console.log(params);
      const response = await axios.get("https://api.deepl.com/v2/translate", {
        params,
      });
      console.log(response);
      if (response.status === 200) {
        const { data } = response;
        const translatedText = data.translations[0].text;
        console.log(translatedText);
        // setvalue(translatedText);
        return translatedText;
      }
      console.error("Translation failed with status code:", response.status);
      return "Translation failed";
    } catch (err) {
      console.error("Translation error:", err);
      return "Translation error";
    }
  };

  useEffect(() => {
    if (endReached) {
      if (messages.length === pageNumber * 50) {
        console.log("fetch old messages", messages.length, pageNumber);
        setpageNumber(pageNumber + 1);
        fetchUserChat(pageNumber + 1);
      }
    } else {
      scrollToBottom();
    }
  }, [endReached, messages]);

  useEffect(() => {
    console.log("File changed", file);
    if (file !== null) {
      if (file.size / 1024 ** 2 > 100) {
        setfileSizeAlert(true);
      } else {
        setfileUrl(URL.createObjectURL(file));
        if (!openCamera.photo && !openCamera.video) {
          setshowPreview(true);
        }
      }
    }
  }, [file]);
  useEffect(async () => {
    if (file !== null)
      if (file.type.split("/")[0] === "video") {
        console.log("File Url", fileUrl);
        const response = await getThumbnailForVideo(fileUrl);
        console.log(response);
        setthumbnail(response);
      }
  }, [fileUrl]);
  useEffect(() => {
    if (isLocked) setopenCoinModal(true);
  }, [isLocked]);

  useEffect(() => {
    console.log("Rerender");
    document.addEventListener("contextmenu", (e) => e.preventDefault());
    fetchUserChat();
    useSocket();
    return () => {
      socket.off("message");
      socket.off("media");
      socket.off("online");
      socket.off("offline");
      socket.off("stopTyping");
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
    // const scroller = document.querySelector('#scrollId')
    // scroller.addEventListener('scroll', () => scrollEnd(scroller), {
    //   passive: true,
    // })
    return () => {
      // scroller.removeEventListener('scroll', scrollEnd, { passive: true })
    };
  }, [isLoading, messages]);
  return (
    <>
      <Header handleClick={goBack} />
      <AlertBox isOpen={fileSizeAlert} setIsOpen={setfileSizeAlert}>
        File size cannot exceed 100 MB
      </AlertBox>
      <section
        onScroll={(e) => scrollEnd(e.target)}
        id="scrollId"
        className={`px-5 h-auto absolute top-73px space-y-8 bottom-73px inset-0 overflow-y-auto font-sans ${
          toggleMedia ? "pb-20" : "pb-5"
        }`}
      >
        <div className="mt-5 space-y-5">
          {!isLoading &&
            messages.map((msg, i) => (
              <div key={msg._id}>
                {generateMessages(msg)}
                {i === lastIndex - 1 && (
                  <img
                    src={recipient.userImage}
                    onLoad={(e) =>
                      e.currentTarget.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                      })
                    }
                    className="w-0 h-0 m-0"
                    alt="fake-scroll"
                  />
                )}
              </div>
            ))}
          {isTyping && (
            <ReceivedMessage
              key={recipient.otherName}
              displayPic={recipient.userImage}
              type="text"
            >
              <Typing />
            </ReceivedMessage>
          )}
        </div>
        <div className="pt-10" ref={messagesEndRef} />
      </section>
      <Footer
        toggleMedia={toggleMedia}
        openGallery={openGalleryDialog}
        sendAudio={sendAudio}
        textboxFocused={textboxFocused}
        isLocked={isLocked}
        setisLocked={setisLocked}
        translateText={translateText}
        value={value}
        setValue={setvalue}
      >
        <div className="flex space-x-2.5 ">
          {!textboxFocused ? (
            <button type="button" onClick={() => settoggleMedia(!toggleMedia)}>
              {!toggleMedia ? (
                <img src={addMediaBtn} alt="add media" />
              ) : (
                <img src={closeMediaBtn} alt="close" />
              )}
            </button>
          ) : (
            <button type="button" onClick={() => settextboxFocused(false)}>
              <img src={closeMediaBtn} alt="close" />
            </button>
          )}
          <AutoTextbox
            onFocus={() => settextboxFocused(true)}
            styles="w-full block px-3 max-h-[82px] min-h-[32px] py-1.5 text-sm align-middle rounded-20px border border-gray-veryLight rounder-[60px] font-sans"
            value={value}
            id="MessageTextBox"
            setvalue={setvalue}
          />
          <button type="button" onClick={onSend}>
            <img src={sendBtn} alt="send button" />
          </button>
        </div>
      </Footer>
      <IOSDialog gallery isOpen={isOpen.gallery} update={openGalleryDialog}>
        {file && showPreview && (
          <Preview
            thumbnail={thumbnail}
            file={file}
            setcoins={setcoins}
            fileUrl={fileUrl}
            showPreview={showPreview}
            closeFileupload={closeFileupload}
            sendMedia={sendMedia}
          />
        )}
        <IOSButton onClick={openFileManager}>Upload from Gallery</IOSButton>
        <input
          type="file"
          id="file"
          onChange={(e) => {
            console.log("Changed");
            setfile(e.target.files[0]);
            e.target.value = "";
          }}
          ref={galleryRef}
          className="hidden"
          accept="image/*, video/*"
        />
        <IOSButton
          onClick={() => setopenCamera((prev) => ({ ...prev, video: true }))}
        >
          Take Video
        </IOSButton>
        <IOSButton
          onClick={() => setopenCamera((prev) => ({ ...prev, photo: true }))}
        >
          Take Photo
        </IOSButton>
        {openCamera.photo && (
          <CapturePhoto
            setcoins={setcoins}
            openCamera={openCamera.photo}
            setopenCamera={setopenCamera}
            setfile={setfile}
            sendMedia={sendMedia}
          />
        )}
        {openCamera.video && (
          <CaptureVideo
            setcoins={setcoins}
            openCamera={openCamera.video}
            setopenCamera={setopenCamera}
            setfile={setfile}
            sendMedia={sendMedia}
          />
        )}
      </IOSDialog>
      <IOSDialog isOpen={isOpen.voice} update={openVoiceDialog}>
        <div>hello</div>
      </IOSDialog>
      {isLocked && (
        <CoinModal
          isOpen={openCoinModal}
          setisOpen={setopenCoinModal}
          setcoins={setcoins}
          setisLocked={setisLocked}
        />
      )}
      {file ? (
        <Loader className="fixed top-[45%] left-[47%] transform -translate-x-1/2 -translate-y-1/2 z-50" />
      ) : null}
    </>
  );
};
export default ChatBroadcast;
