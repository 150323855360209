import React, { useState } from 'react';
import Input from '../../shared/Input';
import Button from '../../shared/Button';

const Name = ({ disabled, onContinue }) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  const handleContinue = () => {
    // Check for spaces only, name length less than 3 characters, or special characters
    if (/^\s+$/.test(name) || name.length < 3 || /[^a-zA-Z0-9\s]/.test(name)) {
      setError('Invalid name. Please enter a valid name.');
    } else {
      setError('');
      onContinue(name);
    }
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height:  '70vh', // Center vertically in the viewport
  };

  const cardStyle = {
    width: '800px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    padding: '20px',
    borderRadius: '10px',
  };


  return (

    <div style={containerStyle}>
      <div style={cardStyle}>
        <form onSubmit={(e) => e.preventDefault()}>
          <h3 className="text-[32px] w-5/6 leading-[44px] font-bold">
            What do you want others to{' '}
            <span className="text-transparent bg-clip-text bg-PurpleGradient">
              call you
            </span>
            ?
          </h3>
          <Input
            value={name}
            setValue={setName}
            placeholder="Enter your name"
            error={error} // Pass the error message to the Input component
          />
          {error && (
            <div className="text-red-500 mt-2">{error}</div> // Display error message in red
          )}
          <Button
            m={`mt-44 ${disabled ? 'opacity-20' : ''}`}
            onClick={handleContinue}
            disabled={disabled}
          >
            Continue
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Name;
