import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from '../api/axios'
import PreferenceCard from '../components/card/PreferenceCard'
import Button from '../shared/Button'
import categories from '../content/categories'
import useAuth from '../hooks/useAuth'

const Preferences = () => {
  const [selectedPreferences, setselectedPreferences] = useState([])
  const { getAccessToken } = useAuth()
  const token = getAccessToken()
  const location = useLocation()
  const navigate = useNavigate()
  const PREFERENCES_URL = '/user/profile'
  useEffect(() => {
    console.log(location.state)
    if (!location.state) navigate('/messages')
  }, [])

  const updatePreferences = (value) => {
    if (selectedPreferences.includes(value)) {
      const updated = selectedPreferences.filter((ele) => ele !== value)
      setselectedPreferences(updated)
    } else {
      setselectedPreferences((prev) => [...prev, value])
    }
    console.log(selectedPreferences)
  }

  const postPreferences = async () => {
    console.log(selectedPreferences)
    const userData = {
      preferences: selectedPreferences,
      push_token: token,
    }
    console.log(token)
    try {
      console.log('posting to Api -> costly')
      const response = await axios.put(PREFERENCES_URL, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log(response)
      if (response?.data?.Message) navigate('/messages')
    } catch (e) {
      console.log('error ', e)
    }
  }
  return (
    <section className="pb-5">
      <div className="p-5">
        <h3 className="text-[32px] leading-[44px] font-bold">
          What are your{' '}
          <span className="text-transparent bg-clip-text bg-PurpleGradient">
            preferences ?
          </span>
        </h3>
      </div>
      <div className="mt-5 grid px-4 grid-cols-2 gap-3 place-items-center">
        {categories.map((category) => (
          <PreferenceCard
            selected={selectedPreferences.includes(category.title)}
            key={category.title}
            title={category.title}
            imgSrc={category.imgSrc}
            emoji={category.emoji}
            handleClick={updatePreferences}
          />
        ))}
      </div>
      <div className="px-5">
        <Button m="mt-7" onClick={postPreferences}>
          Continue
        </Button>
      </div>
    </section>
  )
}

export default Preferences
