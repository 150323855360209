import React from 'react'
import { AuthProvider } from './AuthContext'
import { MessageProvider } from './MessageContext'

const ContextProvider = ({ children }) => (
  <AuthProvider>
    <MessageProvider>{children}</MessageProvider>
  </AuthProvider>
)

export default ContextProvider
