import React, { useState, useRef } from "react";
import moment from "moment";
import OpenImage from "./OpenImage";
import OpenVideo from "./OpenVideo";
import lockIcon from "../../assets/SVG/lock.svg";
import coinIcon from "../../assets/SVG/Coin.svg";

import playBtn from "../../assets/SVG/receivedPlay.svg";
import pauseBtn from "../../assets/SVG/receivedPause.svg";
import voiceGraph from "../../assets/SVG/receivedVoice.svg";
import noImage from "../../assets/PNG/noProfile.png";

const TextMessage = ({ children, time }) => (
  <div className="max-w-230px min-w-60px relative py-3 px-2.5 bg-gray-vvl rounded-20px rounded-bl-xl">
    <p className="break-all text-black-light text-sm">{children}</p>
    <div style={{ position: "absolute", left: 7, bottom: 0, fontSize: "9px" }}>
      {time}
    </div>
  </div>
);

const PictureMessage = ({ id, imgSrc, time, locked, coins }) => {
  const [isLoading, setisLoading] = useState(true);
  const [isOpen, setisOpen] = useState(false);

  return (
    <>
      <div className="relative flex justify-center items-center w-140px box-content px-2.5 py-3 h-180px bg-gray-vvl rounded-20px rounded-br-xl">
        {isLoading && (
          <div className="rounded-10px h-180px w-140px animate-pulse bg-gray-veryLight/50 absolute" />
        )}
        <img
          onClick={() => setisOpen(true)}
          className={`rounded-10px object-cover object-center w-140px transition-all h-180px ${
            isLoading && "opacity-0"
          } ${locked ? "filter blur-[12px]" : ""}`} // Apply blur if locked
          src={imgSrc}
          alt={id && id}
          onLoad={() => setisLoading(false)}
        />
        {locked && (
          <>
            <img className="absolute" src={lockIcon} alt="lock" />
            <div
              className={`flex pt-3 items-center font-bold text-black-light text-xl ${
                isLoading && "opacity-0"
              }`}
            >
              <img className="mr-1.5" src={coinIcon} alt="coin" />
              {coins}
            </div>
          </>
        )}
      </div>
      <div
        style={{
          fontSize: "9px",
          color: "black",
          position: "relative",
          bottom: "-47%",
          left: "-72%",
        }}
      >
        {time}
      </div>
      <OpenImage imgSrc={imgSrc} isOpen={isOpen} setisOpen={setisOpen} />
    </>
  );
};

const VoiceMessage = ({ audioSrc, time }) => {
  const [isPlaying, setisPlaying] = useState(false);
  const voiceRef = useRef(null);
  const play = () => {
    console.log("helooo");
    voiceRef.current.play();
    setisPlaying(!isPlaying);
  };

  const stop = () => {
    voiceRef.current.pause();
    setisPlaying(!isPlaying);
  };

  return (
    <div className="flex flex-col w-[170px] justify-center items-center box-content px-2.5 py-3 bg-gray-vvl rounded-20px rounded-br-xl">
      <div className="w-full px-2 rounded-60px justify-between items-center">
        <audio
          onEnded={() => setisPlaying(!isPlaying)}
          className="hidden"
          ref={voiceRef}
          src={audioSrc}
        >
          <track kind="captions" src="" label="English" />
        </audio>
        <img className="ml-2" src={voiceGraph} alt="voice" />
        <div className="w-full justify-center items-center flex mt-2">
          {isPlaying ? (
            <button type="button" onClick={stop}>
              <img src={pauseBtn} alt="stop" />
            </button>
          ) : (
            <button type="button" onClick={play}>
              <img src={playBtn} alt="play" />
            </button>
          )}
        </div>
      </div>
      <div
        style={{
          fontSize: "9px",
          color: "black",
          position: "relative",
          bottom: "-9px",
          left: "-70px",
        }}
      >
        {time}
      </div>
    </div>
  );
};

const VideoMessage = ({ locked, videoSrc, id, time }) => {
  const [isLoading, setisLoading] = useState(true);
  const [isOpen, setisOpen] = useState(false);

  return (
    <>
      <div
        className={`flex flex-col justify-center items-center w-140px box-content px-2.5 py-3 bg-gray-vvl rounded-20px rounded-br-xl ${
          locked && ""
        }`}
      >
        {isLoading && (
          <div className="rounded-10px h-180px w-140px animate-pulse bg-gray-veryLight/50 absolute" />
        )}
        <img
          onClick={() => setisOpen(true)}
          className={`rounded-10px object-cover object-center transition-all w-140px h-180px ${
            isLoading && "opacity-0"
          }`}
          src={videoSrc.thumbnail}
          alt={id && id}
          onLoad={() => setisLoading(false)}
        />
        <img
          onClick={() => setisOpen(true)}
          className="absolute"
          src={playBtn}
          alt="play"
        />
        <div
          style={{ position: "absolute", left: 7, bottom: 0, fontSize: "9px" }}
        >
          {time}
        </div>
      </div>
      {isOpen && (
        <OpenVideo
          id={id}
          thumbnail={videoSrc.thumbnail}
          videoSrc={videoSrc.video}
          isOpen={isOpen}
          setisOpen={setisOpen}
        />
      )}
    </>
  );
};

const ReceivedMessage = ({
  children,
  displayPic,
  type,
  mediaSrc,
  id,
  translateText,
  date,
  isTip,
  isLocked,
  coins
}) => {
  const [value, setValue] = useState(children);
  const [loading, setLoading] = useState(false);
  const [translated, setTranslated] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const handleOptionsClick = () => {
    setShowOptions(!showOptions);
  };

  const handleTranslate = () => {
    setLoading(true);
    if (!translated) {
      translateText(children, "DE", "EN")
        .then((translatedText) => {
          setValue(translatedText);
          setTranslated(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setValue(children);
      setTranslated(false);
      setLoading(false);
    }
  };
  const time = moment(date).format("h:mma");
  let buttonContent;
  if (loading) {
    buttonContent = "Loading...";
  } else if (translated) {
    buttonContent = "Revert";
  } else {
    buttonContent = "To English";
  }

  let component;
  switch (type) {
    case "text":
      component = (
        <TextMessage time={time} isTip={isTip}>
          {isTip ? `Send you ${value} coins` : value}
        </TextMessage>
      );
      break;
    case "image":
      component = <PictureMessage locked={isLocked} id={id} imgSrc={mediaSrc} time={time}   coins={coins} />;
      break;
    case "video":
      component = <VideoMessage id={id} videoSrc={mediaSrc} time={time} />;
      break;
    case "audio":
      component = <VoiceMessage audioSrc={mediaSrc} time={time} />;
      break;
    default:
      console.log("none");
  }

  return (
    <div className="flex space-x-2.5">
      {displayPic ? (
        <img
          className="w-5 h-5 rounded-full self-end"
          src={displayPic}
          alt={displayPic}
        />
      ) : (
        <img
          className="w-5 h-5 rounded-full self-end"
          src={noImage}
          alt={displayPic}
        />
      )}

      <div style={{ display: "flex", alignItems: "center" }}>
        {component}
        <div
          style={{ cursor: "pointer" }}
          onClick={handleOptionsClick}
          role="button"
          tabIndex="0"
        >
          ...
        </div>
        {showOptions && (
          <div
            style={{
              border: "1px solid lightgrey",
              backgroundColor: "#f4f4f4",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <button type="button" onClick={handleTranslate}>
              {buttonContent}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReceivedMessage;
