import React from "react";
import { Dialog, Transition } from "@headlessui/react";

const ModalImageNudes = ({ isOpen, closeModal, dataNudes }) => {
  const convertBase64ToImageUrl = () => {
    const binaryString = atob(dataNudes?.imageLink);
    const binaryData = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i += 1) {
      binaryData[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([binaryData], { type: "image/jpeg" }); // Adjust the type based on your image format
    const url = URL.createObjectURL(blob);

    return url;
  };

  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        className="fixed z-20 px-12 flex justify-center items-center inset-0 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 -z-10 bg-greyGradient" />
        <div className="w-full flex justify-center">
          <form className="w-full flex flex-col items-center bg-white pt-5 pb-9 rounded-20px max-w-sm">
            <Dialog.Title
              className="font-bold text-lg"
              style={{ color: "#e71c50" }}
            >
              {dataNudes?.title}
            </Dialog.Title>
            <div style={{ margin: "10px 0" }}>
              <img
                src={convertBase64ToImageUrl()}
                style={{
                  width: "130px",
                  height: "130px",
                  borderRadius: "20px",
                  objectFit: "cover",
                }}
                alt=""
              />
            </div>
            {dataNudes
              ? dataNudes?.label.map((val) => (
                  <div
                    key={val.name}
                    style={{
                      padding: "5px 15px",
                      fontSize: "16px",
                      width: "100%",
                    }}
                  >
                    <li>
                      {val?.name} <span>{Math.floor(val.confidence)}%</span>
                    </li>
                  </div>
                ))
              : null}

            <div>
              <button
                type="button"
                onClick={() => closeModal()}
                style={{
                  backgroundColor: "#e71c50",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: "15px",
                  padding: "12px",
                }}
              >
                Agree
              </button>
            </div>
          </form>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalImageNudes;
