import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import "./Style.css";

const ModalCalendarCoins = ({ dateRange, setDateRange, profitsData }) => {
  const { getAccessToken } = useAuth();
  const [events, setEvents] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleSelectChange = (e) => {
    const today = new Date();
    let startDate;
    let endDate;

    switch (e.target.value) {
      case "yesterday":
        startDate = new Date(today);
        startDate.setDate(startDate.getDate() - 1);
        endDate = new Date(startDate);
        break;
      case "lastWeek":
        startDate = new Date(today.setDate(today.getDate() - 7));
        endDate = new Date();
        break;
      case "lastMonth":
        startDate = new Date(today.setMonth(today.getMonth() - 1));
        endDate = new Date();
        break;
      case "all":
        // Handle the 'all' case if necessary
        break;
      default:
        return;
    }

    setDateRange([startDate, endDate]);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSmallScreen = screenWidth <= 900;
  const isInputSmall = screenWidth <= 550;
  const handleDateChange = (dates) => {
    const newDateRange = [...dates];
    newDateRange[0] = new Date(newDateRange[0]);
    newDateRange[1] = new Date(newDateRange[1]);
    setDateRange(newDateRange);
  };

  const handleInputDateChange = (dateType, value) => {
    const newDateRange = [...dateRange];
    newDateRange[dateType === "start" ? 0 : 1] = new Date(value);
    setDateRange(newDateRange);
  };

  const getEventsInRange = () => {
    if (!dateRange[0] || !dateRange[1]) {
      return [];
    }
    return events;
  };
  const tileClassName = ({ date }) => {
    // Custom class for the selected date range
    if (date >= dateRange[0] && date <= dateRange[1]) {
      return "selected-day";
    }
    return null;
  };

  const handleGetDataByDate = async () => {
    const token = getAccessToken();
    setEvents([]);
    const DATA_CALENDAR_DATE = "/affiliate/getDataCalendar";
    const data = {
      startDate: dateRange[0].toISOString().split("T")[0],
      endDate: dateRange[1].toISOString().split("T")[0],
    };
    try {
      const res = await axios.post(DATA_CALENDAR_DATE, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res && res.data && res.data.result === 1) {
        setEvents(res.data.dailyEarnings);
      } else {
        setEvents([]);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    if (!dateRange[0] || !dateRange[1]) {
      setEvents([]);
    } else {
      handleGetDataByDate();
    }
  }, [dateRange]);

  return (
    <section className="px-5 m-10">
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#383838",
            padding: "20px",
            borderRadius: "15px",
          }}
        >
          <div style={{ color: "#e70e3d", fontWeight: "bold" }}>
            Current Month Profits ($) :{" "}
            <spna style={{ color: "white" }}>
              {profitsData ? profitsData.currentMonthProfitUSD : 0} $
            </spna>
          </div>
          <div style={{ color: "#e70e3d", fontWeight: "bold" }}>
            All Profits ($) :{" "}
            <spna style={{ color: "white" }}>
              {profitsData ? profitsData.allTimeProfitUSD : 0} $
            </spna>
          </div>
        </div>
      </div>
      <h3 style={{ margin: "20px 0", fontWeight: "bold", fontSize: 25 }}>
        Calendar Profits
      </h3>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: isSmallScreen ? "center" : "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Calendar
            onChange={handleDateChange}
            value={dateRange}
            tileClassName={tileClassName}
            selectRange
          />
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundImage:
                "linear-gradient(90deg, #E62E66 0%, #E7002C 100%)",
              padding: isInputSmall ? "5px" : "15px",
              borderRadius: "15px",
              display: "flex",
              flexDirection: isInputSmall ? "column" : "row",
              justifyContent: isInputSmall ? "center" : "space-around",
              alignItems: "center",
              margin: "10px 15px",
              width: isSmallScreen ? "100%" : "70%",
              gap: "5px",
            }}
          >
            <div style={{ width: isInputSmall ? "max-content" : "" }}>
              <span
                style={{
                  fontWeight: "bold",
                  marginRight: "5px",
                  color: "white",
                }}
              >
                Start:
              </span>
              <input
                type="date"
                value={dateRange.startDate?.toISOString().split("T")[0]}
                onChange={(e) => handleInputDateChange("start", e.target.value)}
                style={{
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  padding: isInputSmall ? "3px" : "8px",
                }}
              />
            </div>
            <div style={{ width: isInputSmall ? "max-content" : "" }}>
              <span
                style={{
                  fontWeight: "bold",
                  marginRight: "5px",
                  color: "white",
                }}
              >
                End:
              </span>
              <input
                type="date"
                value={dateRange.endDate?.toISOString().split("T")[0]}
                onChange={(e) => handleInputDateChange("end", e.target.value)}
                style={{
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  padding: isInputSmall ? "3px" : "8px",
                }}
              />
            </div>
            <div>
              <span
                style={{
                  fontWeight: "bold",
                  marginRight: "5px",
                  color: "white",
                }}
              >
                select:
              </span>
              <div>
                <select onChange={handleSelectChange}>
                  <option value="yesterday">Yesterday</option>
                  <option value="lastWeek">Last Week</option>
                  <option value="lastMonth">Last Month</option>
                  <option value="all">All</option>
                </select>
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundImage:
                "linear-gradient(90deg, #4B4B4B 0%, #353535 100%)",
              padding: "15px",
              borderRadius: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "10px 15px",
              width: isSmallScreen ? "100%" : "70%",
            }}
          >
            <h2 style={{ color: "white", fontWeight: "bold" }}>RESULT</h2>
            {getEventsInRange().map((event) => (
              <div
                key={event.date}
                style={{
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "90%",
                  margin: "10px 0",
                  borderRadius: "10px",
                  padding: "0 10px",
                }}
              >
                <span style={{ fontWeight: "bold" }}>{event.date}</span>
                <span style={{ fontWeight: "bold" }}>{event.dollars} $</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
export default ModalCalendarCoins;
