import React, { useEffect, useState } from "react";
import ModalLayout from "../../templates/ModalLayout";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import "./Style.css";
import CloudMediaModal from "./CloudMediaModal";
import CloudMediaChat from "./CloudMediaChat";
import Loader from "../../shared/loader/Loader";

const CloudFolderModal = ({
  isOpen,
  setisOpen,
  cloudChat,
  id,
  setmessages,
}) => {
  const { getAccessToken } = useAuth();

  const [dataMedia, setDataMedia] = useState([]);
  const token = getAccessToken();

  const [nameFolderToSend, setNameFolderToSend] = useState("");
  const [isactioned, setIsactioned] = useState(false);
  const [isOpenModalMedia, setIsOpenModalMedia] = useState(false);
  const [loading, setLoading] = useState(false);
  const [folderId2, setFolderId2] = useState("");
  const [nameFolder, setNameFolder] = useState("");
  const deleteCloudMediaByKey = async (folderId) => {
    const data = { folderId };

    // Display the confirmation dialog
    const confirmation = window.confirm(
      "Are you sure you want to delete the folder media?",
    );

    if (confirmation) {
      try {
        const deleteAvatarResponse = await axios.post(
          "/media/folder/delete",
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (deleteAvatarResponse) {
          setIsactioned(!isactioned);
        }
      } catch (e) {
        console.log("error", e);
      }
    }
  };
  const postNameFolder = async () => {
    if (nameFolderToSend === "") {
      alert("Please fill this form to create new folder cloud media!");
      return;
    }
    const data = { folderName: nameFolderToSend };
    try {
      const addFolderResponse = await axios.post("media/folder/create", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (addFolderResponse) {
        setIsactioned(!isactioned);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  async function getCloudMedia() {
    setLoading(true);
    const res = await axios.post(
      "media/folder/fetch",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (res) {
      setLoading(false);
      setDataMedia(res.data.folders);
    }
  }
  console.log("data media", dataMedia);
  useEffect(() => {
    getCloudMedia();
  }, [isactioned]);

  return (
    <ModalLayout
      title="Cloud Media Folders"
      isOpen={isOpen}
      setisOpen={setisOpen}
    >
      <div style={{ padding: "10px 20px" }}>
        <input
          type="text"
          onChange={(e) => setNameFolderToSend(e.target.value)}
          style={{
            border: "1px solid #e62e66",
            marginRight: "10px",
            borderRadius: "5px",
            padding: "7px",
          }}
          placeholder="Enter Name Folder"
        />
        <button
          style={{
            backgroundColor: "#e62e66",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
          }}
          type="button"
          onClick={postNameFolder}
        >
          Create New Folder
        </button>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          gap: "10px",
          marginTop: "20px",
          padding: "0 10px",
          position: "relative",
        }}
      >
        {dataMedia &&
          dataMedia.map((val) => {
            console.log("heii");
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#D9DDDC",
                  borderRadius: "5px",
                }}
              >
                {val.latestMedia.length === 0 ? (
                  <div
                    role="button"
                    tabIndex="0"
                    onClick={() => {
                      setIsOpenModalMedia(!isOpenModalMedia);
                      setFolderId2(val.folderId);
                      setNameFolder(val.folderName);
                    }}
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "180px",
                      border: "1px solid #fff",
                      borderRadius: "5px",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#000",
                      fontWeight: "500",
                      letterSpacing: "2px",
                    }}
                  >
                    Empty Folder
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "180px",
                      border: "1px solid #fff",
                      borderRadius: "5px",
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(50px, 1fr))",
                      gap: "10px",
                      padding: "10px",
                      overflow: "hidden",
                    }}
                    role="button"
                    tabIndex="0"
                    onClick={() => {
                      setIsOpenModalMedia(!isOpenModalMedia);
                      setFolderId2(val.folderId);
                      setNameFolder(val.folderName);
                    }}
                  >
                    {val.latestMedia.map((value) => (
                      <div
                        style={{
                          width: "100%",
                          height: "70px",
                          objectFit: "scale-down",
                          border: "1px solid #e62e66",
                          borderRadius: "5px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={
                            value.type === "image"
                              ? value.url
                              : value.thumbnailUrl
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          alt={
                            value.type === "image"
                              ? value.url
                              : value.thumbnailUrl
                          }
                        />
                      </div>
                    ))}
                  </div>
                )}

                <div
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "5px 2px",
                  }}
                >
                  <div>
                    {" "}
                    <span style={{ color: "#e62e66", letterSpacing: "2px" }}>
                      {val.folderName}
                    </span>{" "}
                    Folder
                  </div>
                  <div
                    style={{
                      backgroundColor: "red",
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: "11px",
                      padding: "4px",
                      borderRadius: "5px",
                    }}
                    role="button"
                    tabIndex="0"
                    onClick={() => deleteCloudMediaByKey(val.folderId)}
                  >
                    Remove
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {isOpenModalMedia && cloudChat && (
        <CloudMediaChat
          isOpen={isOpenModalMedia}
          setisOpen={setIsOpenModalMedia}
          closeModalFolder={setisOpen}
          folderId2={folderId2}
          nameFolder={nameFolder}
          setmessages={setmessages}
          toUser={id}
        />
      )}
      {isOpenModalMedia && !cloudChat && (
        <CloudMediaModal
          isOpen={isOpenModalMedia}
          setisOpen={setIsOpenModalMedia}
          folderId2={folderId2}
          nameFolder={nameFolder}
        />
      )}
      {loading && (
        <Loader className="fixed top-[45%] left-[47%] transform -translate-x-1/2 -translate-y-1/2" />
      )}
    </ModalLayout>
  );
};

export default CloudFolderModal;
