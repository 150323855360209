import React from 'react'

const IOSButton = ({ children, onClick, className }) => (
  <button
    onClick={onClick}
    type="button"
    className={`w-full border-gray-divider border-b-0.5px py-17px text-[#007AFF] text-lg leading-5 ${className}`}
  >
    {children}
  </button>
)

export default IOSButton
