import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Moment from "react-moment";
import fallbackImg from "../../assets/SVG/profilePic.svg";
import locksvg from "../../assets/SVG/icon8lock.svg";
import gallery from "../../assets/PNG/gallery1.png";

const Avatar = ({ avatar, isLoading, setisLoading, isRead, received }) => (
  <div className="relative">
    <img
      className="w-14 h-14 rounded-full"
      src={avatar !== "" ? avatar : fallbackImg}
      alt={avatar !== "" ? avatar : fallbackImg}
      onLoad={() => setisLoading(false)}
    />
    <span
      className={`absolute top-35px -right-5px w-3 h-3 bg-primary-lightPink box-content border-3px border-white rounded-full ${
        !isLoading && !isRead && received ? "block" : "hidden"
      }`}
    />
    {isLoading && (
      <div className="w-14 h-14 rounded-full animate-pulse bg-gray-veryLight absolute top-0" />
    )}
  </div>
);

const Message = ({
  lastMessage,
  name,
  type,
  isRead,
  isSubscribed,
  received,
  coins,
}) => {
  const expiredBroadcast =
    lastMessage.isBroadcastMSG && !lastMessage.isBroadcastExpired;
  let text;
  let placeholder;
  // if (lastMessage.isLocked) {
  //   text = "locked-message";
  // } else {

  //   if (expiredBroacast) {
  //     text = "Message has expired";
  //   } else {
  //     text =
  //       lastMessage.text && lastMessage.text.length > 18
  //         ? `${lastMessage.text.slice(0, 18)} ...`
  //         : lastMessage.text;
  //   }
  // }
  if (lastMessage.isLocked) {
    text = "locked-message";
  } else if (expiredBroadcast) {
    text = "Message has expired";
  } else {
    text =
      lastMessage.text && lastMessage.text.length > 18
        ? `${lastMessage.text.slice(0, 18)} ...`
        : lastMessage.text;
  }

  if (type === "image")
    placeholder = expiredBroadcast ? "Image has expired" : "Photo";
  else if (type === "audio") placeholder = "Voice message";
  else if (type === "video")
    placeholder = expiredBroadcast ? "Video has expired" : "Video";
  return (
    <div className="pl-4 flex flex-col justify-center">
      <div
        style={{
          display: "flex",
          gap: "5px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 className="text-xl font-semibold text-black">
          {name || "Undefined"}
          {isSubscribed ? (
            <span
              style={{
                backgroundColor: "lightblue",
                color: "#e70f3e",
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "5px",
                fontSize: "15px",
              }}
            >
              Subscribed
            </span>
          ) : null}
        </h1>
        {coins > 0 && (
          <div
            style={{
              backgroundColor: "#e72b69",
              color: "#fff",
              borderRadius: "5px",
              fontWeight: "bold",
              padding: "3px 5px",
              fontSize: "10px",
            }}
          >
            {(coins * 0.01).toFixed(1)}$
          </div>
        )}
      </div>

      <p
        className={`${
          !isRead && received
            ? "text-black font-semibold"
            : "text-gray-mild font-normal"
        }`}
      >
        {type === "image" || type === "video" || type === "audio" ? (
          <span className="flex items-center">
            <img className="w-4 h-4 mr-0.5" src={gallery} alt="gallery" />
            {placeholder}
          </span>
        ) : (
          <span className="flex items-center">
            <img
              className={`w-4 h-4 mr-0.5  ${
                lastMessage.isLocked ? "block" : "hidden"
              }`}
              src={locksvg}
              alt="lock"
            />
            {text}
          </span>
        )}
      </p>
    </div>
  );
};

const ChatPanel = ({
  avatar,
  name,
  role,
  lastMessage,
  userId,
  handleClick,
  isSubscribed,
  coins,
}) => {
  const [isLoading, setisLoading] = useState(true);
  const location = useLocation();

  return (
    <Link
      onClick={handleClick}
      to="/chat"
      state={{
        name,
        avatar,
        role,
        id: userId,
        pathHistory: location.pathname,
        isRead: lastMessage.isRead,
      }}
      className="flex"
    >
      <Avatar
        avatar={avatar}
        isLoading={isLoading}
        setisLoading={setisLoading}
        isRead={lastMessage.isRead}
        received={lastMessage.fromUser === userId}
      />
      <Message
        isSubscribed={isSubscribed}
        name={name}
        lastMessage={lastMessage}
        type={lastMessage.type}
        isRead={lastMessage.isRead}
        received={lastMessage.fromUser === userId}
        coins={coins}
      />
      <Moment
        fromNow
        className={`mt-1 pt-px text-xxs leading-xxs font-bold ml-auto
        ${
          !lastMessage.isRead && lastMessage.fromUser === userId
            ? "text-black"
            : "text-gray-mild"
        }`}
      >
        {lastMessage.updatedAt}
      </Moment>
    </Link>
  );
};

export default ChatPanel;
