import React, { useState } from 'react';
import EmojiShadow from '../../assets/SVG/emojiShadow.svg';
import cardTitlebg from '../../assets/SVG/cardTitlebg.svg';
import Button from '../../shared/Button';

const SelectCards = ({ values, onContinue, isMulti, isLargeCard, children }) => {
  const [selected, setSelected] = useState(() => '');
  const [selectedCards, setSelectedCards] = useState(() => []);
  const handleClick = (value) => {
    if (isMulti) {
      if (selectedCards.includes(value)) {
        setSelectedCards(selectedCards.filter((old) => old !== value));
      } else {
        setSelectedCards([...selectedCards, value]);
      }
    } else {
      setSelected(selected === value ? '' : value);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isMulti) {
      if (selectedCards.length > 0) {
        return onContinue(selectedCards.filter((val) => !values.includes(val)));
      }
      return null;
    }
    if (selected) {
      return onContinue(selected);
    }
    return null;
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: isLargeCard? '160vh':'70vh', // Center vertically in the viewport
  };

  const cardStyle = {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', 
    padding: '50px', 
    borderRadius: '10px', 
  };

  return (
    <form onSubmit={handleSubmit}>
      
      <div style={containerStyle}>
        <div style={cardStyle}>
          {children}
          <div className="mt-5 grid px-4 grid-cols-2 gap-3 place-items-center">
            {values.map((value) => (
              <div
                role="button"
                tabIndex={-1}
                key={value.value + Math.random() * 99000}
                onClick={() => handleClick(value.value)}
                className={`w-[165px] h-[165px] flex justify-center items-center z-20 relative rounded-10px${
                  (selectedCards.includes(value.value) && isMulti) ||
                  (!isMulti && selected === value.value)
                    ? ' bg-purpleBorder'
                    : ''
                }`}
              >
                <div className="w-[157px] h-[157px] overflow-hidden rounded-10px bg-PurpleGradient absolute">
                  <div className="mt-11 pt-0.5 text-[35px] leading-snug text-center gap-3">
                    {value.imgSrc === undefined ? (
                      <span>{value.icon}</span>
                    ) : (
                      <img
                        className="mx-auto"
                        src={value.imgSrc}
                        alt={value.imgSrc}
                        style={{ maxWidth: '30%', maxHeight: '30%' }}
                      />
                    )}
                  </div>
                  <img src={EmojiShadow} alt={EmojiShadow} className="mx-auto mt-1.5" />
                  <div className="absolute bottom-0 flex justify-center">
                    <p className="text-center text-sm text-white font-bold z-30 absolute bottom-1">
                      {value?.label || value.value}
                    </p>
                    <img src={cardTitlebg} alt={cardTitlebg} />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Button
            m={`mt-10 ${
              (isMulti && selectedCards.length) || selected ? '' : 'opacity-20'
            }`}
          >
            Continue
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SelectCards;
