import React from 'react'

const Input = ({ children, value, placeholder, setValue }) => (
  <div className="mt-7 font-semibold space-y-2.5 text-black-dark">
    <label htmlFor={children}>{children}</label>
    <br />
    <input
      id={children}
      type="text"
      value={value}
      name=""
      placeholder={placeholder}
      className="input-box"
      onChange={(e) => setValue(e.target.value)}
    />
  </div>
)

export default Input
