import React, { useEffect, useState }  from 'react';
import ModalLayout from '../../../../templates/ModalLayout';

const AgeModal = ({ 
  isOpen,
   setisOpen, 
   content,
   setuser,
    callApi
  }) => {
 
  const [value, setvalue] = useState(23)
  useEffect(() => {
    console.log('Age Intiatiated')
    setvalue(content !== undefined ? content : 23)
  }, [isOpen])

  const update = () => {
    const ageInt = parseInt(value, 10);
    if (ageInt >= 18 && ageInt <= 50) {

      if (content !== ageInt) {
      const currentYear = new Date().getFullYear();
      const dobYear = currentYear - ageInt;
      const dob = new Date(dobYear, 0, 1).toISOString();

        callApi({dob});
        setuser((prev) => ({ ...prev, dob: ageInt, isUpdated: true }));
      }
      setisOpen(false);
    } else {
      alert('Please enter a valid age between 18 and 50');
    }
  };

console.log("newAge is:",value)
  return (
    <ModalLayout
      title="Age"
      hasTick
      isOpen={isOpen}
      setisOpen={setisOpen}
      handleClick={update}
    >
       <div className="p-5">
              <p className="text-lg font-medium text-gray-700 mb-3">Set Your Age Between 18 - 50</p>
              <input
                type="number"
                value={value}
                onChange={(e) => setvalue(e.target.value)}
                placeholder="Enter your age"
                min="18"
                max="45"
                className="w-72 border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
      </ModalLayout>
  );
};

export default AgeModal;