import React, { useState } from 'react'
import { Show, Hide } from './toggleEye'

const Password = ({ children, password, setpassword }) => {
  const [isVisible, setisVisible] = useState(false)
  return (
    <div className="mt-10 font-semibold space-y-2.5 text-black-dark">
      <label htmlFor="password">{children}</label>
      <br />
      <div className="relative">
        <div className="absolute right-4 h-full flex justify-center items-center">
          <button
            type="button"
            className=""
            onClick={() => {
              setisVisible(!isVisible)
            }}
          >
            {isVisible ? <Hide /> : <Show />}
          </button>
        </div>
        <input
          id={children}
          type={isVisible ? 'text' : 'password'}
          value={password || ''}
          name="password"
          placeholder="Type a password"
          className={`input-box ${
            isVisible
              ? 'font-sans text-base'
              : 'font-asterisk text-[9px] leading-6 tracking-veryWide'
          }`}
          onChange={(e) => setpassword(e.target.value)}
        />
      </div>
    </div>
  )
}

export default Password
