import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { MessageIcon, DiscoverIcon, ProfileIcon } from "../shared/NavIcons";

const NavLink = ({ Icon, children }) => {
  const location = useLocation();
  const path = `/${children}`.toLowerCase();
  const active = location.pathname === path;
  return (
    <Link to={path} active={+active} className="flex flex-col items-center">
      <Icon active={+active} />
      <Lable active={+active}>{children}</Lable>
    </Link>
  );
};

const Lable = ({ children, active }) => (
  <h4
    className={`font-semibold text-9px mt-0.5 leading-3 ${
      active
        ? "text-transparent bg-clip-text bg-PurpleGradient"
        : "text-gray-veryLight"
    }`}
  >
    {children}
  </h4>
);

const Footer = ({ children }) => (
  <footer
    className="bottom-0 left-1/2 transform -translate-x-1/2 fixed w-full overflow-hidden px-14 py-4 font-sans max-w-screen-lg bg-whiteGradient shadow-lightBlack rounded-t-lg"
    style={{ backgroundColor: "white" }}
  >
    <div className="flex justify-between">{children}</div>
  </footer>
);

const Layout = ({ children }) => {
  useEffect(() => {
    console.log("Layout initiated");
  }, []);

  return (
    <div className="h-screen text-black font-sans relative overflow-y-hidden  mx-auto">
      {children}
      <Footer>
        <NavLink Icon={MessageIcon}>Messages</NavLink>
        <NavLink Icon={DiscoverIcon}>Discover</NavLink>
        <NavLink Icon={ProfileIcon}>Profile</NavLink>
      </Footer>
    </div>
  );
};

export default Layout;
