import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import IOSButton from "./IOSButton";

const IOSDialog = ({ isOpen, update, children, title, gallery }) => (
  <Transition
    show={isOpen}
    enter="transition duration-100 ease-out"
    enterFrom="transform scale-95 opacity-0"
    enterTo="transform scale-100 opacity-100"
    leave="transition duration-75 ease-out"
    leaveFrom="transform scale-100 opacity-100"
    leaveTo="transform scale-95 opacity-0"
  >
    <Dialog
      className={`fixed z-50 ${
        gallery ? "w-full px-2.5 items-end py-7" : "px-12 items-center"
      } flex justify-center inset-0 overflow-y-auto font-SFPro`}
      onClose={update}
    >
      <Dialog.Overlay className="fixed inset-0 -z-10 bg-black opacity-60 pointer-events-none" />
      <div className="w-full">
        <div className="flex flex-col items-center bg-white-light rounded-[14px]">
          <Dialog.Title className="font-semibold text-lg text-black px-4 text-center">
            {title}
          </Dialog.Title>
          {children}
        </div>
        <IOSButton
          onClick={update}
          className={`${
            gallery
              ? "bg-white-light rounded-[14px] font-semibold mt-2.5"
              : "hidden"
          }`}
        >
          Cancel
        </IOSButton>
      </div>
    </Dialog>
  </Transition>
);

export default IOSDialog;
