import React, { useState, useRef } from "react";
import ChatListEN from "./ChatListEN";
import ChatListGE from "./ChatListGE";
import useMessageContext from "../../hooks/useMessageContext";
import ChatListIN from "./ChatListIN";

const ChatList = () => {
  const { initialState, filteredState } = useMessageContext();
  const [userList, setuserList] = initialState;
  const [filterUser, setFilterUser] = filteredState;
  const scrollElement = useRef(null);
  const [focusLang, setFocusLang] = useState(1);
  console.log(userList[0]?.name);
  console.log(filterUser[0]?.name);

  function getChatList() {
    if (focusLang === 1) {
      return <ChatListEN scrollElement={scrollElement} />;
    }  if (focusLang === 2) {
      return <ChatListGE scrollElement={scrollElement} />;
    } 
      return <ChatListIN scrollElement={scrollElement} />;
  }

  return (
    <section
      className="h-screen overflow-y-scroll px-5 pt-5 absolute top-73px bottom-73px inset-0"
      ref={scrollElement}
    >
      <section
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "50%",
            height: "50px",
            borderRadius: "15px",
            overflow: "hidden",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            border: "1px solid #e71a4d",
            marginBottom: "10px",
          }}
        >
          <div
            role="button"
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: focusLang === 1 ? "white" : "#e71a4d",
              color: focusLang === 1 ? "#e71a4d" : "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              if (focusLang !== 1) {
                setFocusLang(1);
                setFilterUser([]);
                setuserList([]);
              }
            }}
            tabIndex={0}
          >
            English Users
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: focusLang === 2 ? "white" : "#e71a4d",
              color: focusLang === 2 ? "#e71a4d" : "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              if (focusLang !== 2) {
                setFocusLang(2);
                setFilterUser([]);
                setuserList([]);
              }
            }}
            role="button"
            tabIndex={0}
          >
            German Users
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: focusLang === 3 ? "white" : "#e71a4d",
              color: focusLang === 3 ? "#e71a4d" : "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              if (focusLang !== 3) {
                setFocusLang(3);
                setFilterUser([]);
                setuserList([]);
              }
            }}
            role="button"
            tabIndex={0}
          >
            Other Users
          </div>
        </div>
      </section>
      {getChatList(focusLang, scrollElement)}
    </section>
  );
};

export default ChatList;
