import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import axios from '../api/axios'
import AlertBox from '../components/alert/AlertBox'
import Button from '../shared/Button'
import ErrText from '../shared/ErrText'
import Password from '../shared/Password'
import EntryLayout from '../templates/EntryLayout'

const invite = () => {
  const [password, setpassword] = useState('')
  const [cpassword, setcpassword] = useState('')
  const [isOpen, setisOpen] = useState(false)
  const [error, seterror] = useState(false)
  const { token } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const ACCEPT_INVITE = '/user/accept-invite'

  const AcceptInvite = async () => {
    try {
      const res = await axios.put(ACCEPT_INVITE, {
        token,
        password,
      })
      if (res?.data?.token) {
        console.log(res)
        setisOpen(true)
        setTimeout(() => {
          navigate('/login')
        }, 3000)
      }
    } catch (e) {
      console.log(e, 'api error')
    }
  }

  const handleClick = (e) => {
    e.preventDefault()
    if (password === cpassword) {
      seterror(false)
      console.log(token)
      AcceptInvite()
    } else {
      seterror(true)
    }
  }
  return (
    <EntryLayout>
      <AlertBox isOpen={isOpen} setIsOpen={setisOpen} success>
        Password set successfully. Redirecting to login page
      </AlertBox>
      <form onSubmit={handleClick} className="mt-14" autoComplete="off">
        <Password password={password} setpassword={setpassword}>
          {t('Password')}
        </Password>
        <Password password={cpassword} setpassword={setcpassword}>
          {t('Confirm Password')}
        </Password>
        {error ? <ErrText>Confirm Password does not match</ErrText> : ''}
        <Button>{t('Save')}</Button>
      </form>
    </EntryLayout>
  )
}

export default invite
