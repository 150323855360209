import React, { useState } from 'react'
import Button from '../../shared/Button'

const TextArea = ({ disabled, onContinue, label }) => {
  const [text, setText] = useState('')
  return (
    <form onSubmit={() => onContinue(text)}>
      <div className="mt-7 font-semibold space-y-2.5 text-black-dark">
        <label htmlFor={label}>{}</label>
        <br />
        <textarea
          id={label}
          type="text"
          value={text}
          placeholder={label}
          className="input-box resize-y"
          onChange={(e) => setText(e.target.value)}
        />
      </div>
      <Button m={`mt-44 ${disabled ? 'opacity-20' : ''}`}>Continue</Button>
    </form>
  )
}

export default TextArea
