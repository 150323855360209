import React, { useState, useEffect } from 'react'
import Arrow from '../../assets/SVG/ProfileArrow.svg'
import CurrentLegalModal from './CurrentLegalModal'

const LegalPanels = ({ title }) => {
  const [isOpen, setisOpen] = useState(false)
  const [modal, setmodal] = useState('')

  useEffect(() => {
    console.log('Legal is initiated', modal)
  }, [modal])

  const openModal = (e) => {
    setisOpen(!isOpen)
    setmodal(e.target.id)
    console.log('user clicked the panel')
  }
  return (
    <div>
      <div className="mt-1.5 pb-[18px] border-b-0.5px border-gray-veryLight">
        <button
          id={title}
          onClick={openModal}
          type="button"
          className="flex justify-between w-full text-left"
        >
          <h4 id={title} className="flex font-semibold text-sm pr-2.5">
            {title}
          </h4>
          <img id={title} src={Arrow} alt={Arrow} />
        </button>
      </div>
      <CurrentLegalModal modal={modal} setisOpen={setisOpen} isOpen={isOpen} />
    </div>
  )
}

export default LegalPanels
