import React, { Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AppRoutes from "./Routes/AppRoutes";
import { Globalstore, Store, StoreContext } from "./stores/Stores";
import useAuth from "./hooks/useAuth";
import socket from "./socket/socket";

const App = () => {
  const { getPreferences } = Store().preferencesStore;
  const location = useLocation();
  const { getAccessToken } = useAuth();
  const token = getAccessToken();

  const getAllPreferences = async () => {
    await getPreferences();
  };

  useEffect(() => {
    setTimeout(() => {
      getAllPreferences();
    }, 1000);
    console.log("app started");
    return () => {
      console.log("return from app");
    };
  }, [location.pathname]);
  useEffect(() => {
    if (token) {
      console.log("Setting socket token and connecting...");
      socket.auth = { token };
      socket.connect();
      socket.on("connect", () => {
        console.log("Socket connected");
        // fetchUserList();
      });
      // socket.on('userList', handleUserList);
      //  console.log('Listening for userList event');
    }
  }, [token]);
  return (
    <StoreContext.Provider value={Globalstore}>
      <Suspense fallback={<div>Loading...</div>}>
        <AppRoutes />
      </Suspense>
    </StoreContext.Provider>
  );
};

export default App;
