import React from 'react'
import Layout from '../templates/Layout'
import MessagePanel from '../components/messages/BroadcastMessagePanel'

const Broadcast = () => {
  console.log('Broadcast')
  return (
    <Layout>
      <MessagePanel />
      {/* <h1>hello</h1> */}
    </Layout>
  )
}

export default Broadcast
