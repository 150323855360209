import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import BroadcastChatSelect from "./BroadcastChatSelect";
import useMessageContext from "../../hooks/useMessageContext";
import Button from "../../shared/Button";
import BackBtn from "../../shared/BackBtn";

const MessageList = () => {
  const { filteredState } = useMessageContext();
  const [filterUser, setFilterUser] = filteredState;
  const [allSelected, setAllSelected] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const filteredUsers = filterUser.filter((user) => user.isSelected);
  const isDisabled = filteredUsers.length === 0;
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const changeallSelected = () => {
    setAllSelected(!allSelected);
    setFilterUser((prevUserList) =>
      prevUserList.map((user) => ({ ...user, isSelected: true }))
    );
    setIsSelectAll(!isSelectAll);
  };
  const changeallDeSelected = () => {
    setAllSelected(!allSelected);
    setFilterUser((prevUserList) =>
      prevUserList.map((user) => ({ ...user, isSelected: false }))
    );
    setIsSelectAll(!isSelectAll);
  };

  const selectedUsersCount = filteredUsers.length; 

  const renderNextButton = () => (
    <Link
      to="/chatbroadcast"
      state={filteredUsers}
      className="text-black text-lg font-medium p-5"
    >
      <Button
        disabled={isDisabled}
        className={`btn border border-[#e7002c] flex justify-center items-center text-center font-bold px-2 py-1 rounded-sm text-[#e7002c]${
          isDisabled ? " opacity-50" : ""
        }`}
      >
        Next
      </Button>
    </Link>
  );

  return (
    <>
      <header className="fixed top-0 flex items-center px-5 py-20px inset-x-0 z-30 bg-white overflow-hidden font-sans justify-between">
        {" "}
        <BackBtn handleClick={() => goBack()} />
        <h1 className="text-black text-2xl font-bold">
          select contacts please
        </h1>
        {selectedUsersCount > 0 && (
          <div>
            Selected: {selectedUsersCount} user(s)
          </div>
        )}
        <button
          onClick={() =>
            isSelectAll ? changeallDeSelected() : changeallSelected()
          }
          type="button"
        >
          {isSelectAll ? "Deselect All" : "Select All"}
        </button>
        {renderNextButton()}
      </header>

      <BroadcastChatSelect
        handleAllSelectedChats={allSelected}
        userList={filterUser}
        setuserList={setFilterUser}
      />
    </>
  );
};

export default MessageList;
