import React, { useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Webcam from "react-webcam";
import { useReactMediaRecorder } from "react-media-recorder-2";
import closeBtn from "../../assets/SVG/close.svg";
import playBtn from "../../assets/SVG/sentPlay.svg";
import pauseBtn from "../../assets/SVG/sentPause.svg";
import switchBtn from "../../assets/SVG/switchCamera.svg";
import cameraBtn from "../../assets/SVG/camera.svg";
import stopCameraBtn from "../../assets/SVG/stopCamera.svg";
import LockSwitch from "../../shared/LockSwitch";
import CoinModal from "./CoinModal";

/* eslint-disable jsx-a11y/media-has-caption */

/* eslint-disable prefer-template */

const CaptureMedia = ({
  openCamera,
  setopenCamera,
  setfile,
  setcoins,
  sendMedia,
  openGalleryDialog,
}) => {
  const [locked, setlocked] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [isPlaying, setisPlaying] = useState(false);
  const [fadeOut, setfadeOut] = useState(false);
  const [isCapturing, setisCapturing] = useState(false);
  const [videoFile, setvideoFile] = useState(null);
  const [frontCamera, setfrontCamera] = useState(true);
  const webCamRef = useRef();
  const videoRef = useRef();
  const [isCaptured, setisCaptured] = useState(false);
  const [time, settime] = useState(0);
  let timer;
  const videoConstraints = {
    facingMode: frontCamera ? "user" : { exact: "environment" },
  };
  const { startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({
      video: true,
      audio: true,
      type: "video/mp4",
      blobPropertyBag: {
        type: "video/mp4",
      },
      // video: {
      //   facingMode: frontCamera ? 'user' : { exact: 'environment' },
      // },
      askPermissionOnMount: true,
      onStop: (blobUrl, blob) => {
        console.log(blobUrl, blob);
        const myFile = new File([blob], "video.mp4", {
          type: "video/mp4",
        });
        setfile(myFile);
        console.log(myFile);
        setvideoFile(myFile);
        setisCaptured(true);
      },
    });
  const toggleControl = () => {
    if (isPlaying) videoRef.current.pause();
    else videoRef.current.play();
    setisPlaying(!isPlaying);
    if (!timer) timer = setTimeout(() => setfadeOut(true), 2000);
  };
  const toggleFadeOut = () => {
    setfadeOut(false);
    timer = setTimeout(() => setfadeOut(true), 3000);
  };
  const capture = () => {
    if (!isCapturing) {
      startRecording();
    } else {
      stopRecording();
    }
    setisCapturing(!isCapturing);
  };
  const closeModal = () => {
    console.log("close");
    setopenCamera((prev) => ({ ...prev, video: false }));
    openGalleryDialog();
    clearBlobUrl();
    settime(0);
  };
  const uploadToServer = async () => {
    console.log("file ready to be uploaded");
    if (videoFile !== null) {
      sendMedia(locked);
      closeModal();
    }
  };
  const switchCamera = () => {
    setfrontCamera(!frontCamera);
  };
  const retake = () => {
    setisCaptured(false);
    clearBlobUrl();
    settime(0);
  };
  useEffect(() => {
    if (locked) setisOpen(true);
  }, [locked]);
  useEffect(() => {
    let interval = null;
    if (isCapturing) {
      interval = setInterval(() => {
        settime((prev) => prev + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isCapturing]);
  return (
    <Transition
      show={openCamera}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-gray-semiDark -z-10 pointer-events-none" />
        <div className="z-40 h-screen">
          <button
            type="button"
            onClick={closeModal}
            className="absolute top-10 right-5 z-50"
          >
            <img src={closeBtn} alt={closeBtn} />
          </button>
          <div
            className={`text-white text-4xl font-bold absolute top-10 left-1/2 transform -translate-x-1/2 ${
              isCaptured && "hidden"
            }`}
          >
            <span className="digits">
              {("0" + Math.floor((time / 60000) % 60)).slice(-2)}:
            </span>
            <span className=" text-primary-palePink">
              {("0" + Math.floor((time / 1000) % 60)).slice(-2)}
            </span>
          </div>
          {isCaptured && (
            <div className="h-full flex justify-center items-center">
              <video
                onEnded={() => {
                  setisPlaying(!isPlaying);
                  setfadeOut(false);
                }}
                onClick={toggleFadeOut}
                ref={videoRef}
                className="w-full object-cover h-full object-center aspect-[9/16]"
                alt="preview"
                src={mediaBlobUrl}
              />
              <button
                onClick={toggleControl}
                type="button"
                className={`rounded-full z-40 absolute w-16 h-16 bg-PurpleGradient transition-opacity flex justify-center items-center focus:outline-none ${
                  fadeOut ? "opacity-0 pointer-events-none" : "opacity-100"
                }`}
              >
                <img
                  className="w-8 h-8"
                  src={isPlaying ? pauseBtn : playBtn}
                  alt="control"
                />
              </button>
            </div>
          )}
          <div />
          <Webcam
            ref={webCamRef}
            className={`w-full z-30 h-full object-cover object-center ${
              isCaptured && "hidden"
            }`}
            videoConstraints={videoConstraints}
          />
          {isCaptured ? (
            <div className="flex pl-5 pr-6 justify-between absolute w-full bottom-6">
              <LockSwitch locked={locked} setlocked={setlocked} />
              <button type="button" onClick={retake}>
                <h3 className="font-semibold text-lg leading-6 tracking-[-0.3px] text-white">
                  Retake
                </h3>
              </button>
              <button type="button" onClick={uploadToServer} className="mr-1.5">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.653 6.113 26.11 15.42a1.153 1.153 0 0 1 0 2.082L6.653 26.807a1.155 1.155 0 0 1-1.612-1.345l1.946-7.136a.461.461 0 0 1 .384-.336l9.427-1.28a.23.23 0 0 0 .18-.138l.017-.059a.23.23 0 0 0-.145-.247l-.05-.014-9.42-1.28a.462.462 0 0 1-.382-.336L5.041 7.46a1.154 1.154 0 0 1 1.612-1.346Z"
                    fill="#fff"
                  />
                </svg>
              </button>
            </div>
          ) : (
            <div className="flex z-50 justify-between items-center absolute w-full bottom-6 px-10">
              <div className="w-8 h-8" />
              <button
                type="button"
                className="flex items-center justify-center w-16 h-16 rounded-full bg-white/[0.3]"
                onClick={capture}
              >
                <img
                  className={`z-30 ${isCapturing && "w-6 h-6"}`}
                  src={isCapturing ? stopCameraBtn : cameraBtn}
                  alt="camera"
                />
                <div
                  className={`bg-PurpleGradient w-9 h-9 animate-ping absolute rounded-full z-20 ${
                    isCapturing ? "block" : "hidden"
                  }`}
                />
              </button>
              <button type="button" onClick={switchCamera}>
                <img src={switchBtn} alt="switch" />
              </button>
            </div>
          )}
        </div>
        {locked && (
          <CoinModal
            isOpen={isOpen}
            setisOpen={setisOpen}
            setcoins={setcoins}
            setisLocked={setlocked}
          />
        )}
      </Dialog>
    </Transition>
  );
};

export default CaptureMedia;
