import React from 'react'
import ScrollView from './ScrollView'
import Button from '../../shared/Button'

const Rules = ({ onAccept, onDecline }) => {
  const handleSubmit = (e) => {
    e.preventDefault()
  }
  return (
    <form
      className="grid px-4 grid-cols-1 p-3 place-items-center"
      onSubmit={handleSubmit}
    >
      <h1 className="text-[32px] w-9/10 leading-[44px] font-bold">
        <span className="text-transparent bg-clip-text bg-PurpleGradient">
          Tapyn
        </span>{' '}
        Rules📝
      </h1>
      <h2 className="opacity-50 font-bold">End-user License Agreement</h2>
      <ul className="mt-5 font-medium text-[19px] gap-3 flex flex-col">
        <li>❌ no sale of illegal goods/media</li>
        <li>🔒 keep your infos private</li>
        <li>
          🔞 by accepting you confirm that you are at least 18 years old.
          Otherwise your account will be deleted immediately.
        </li>
      </ul>
      <div className="overflow-hidden rounded-md mt-10 text-center text-sm">
        <ScrollView>
          <b>
            <u className="block text-center">Allgemeine Geschäftsbedingungen</u>
          </b>
          <br /> <br /> <br />
          <b className="block text-center">
            § 1 Vertragsgegenstand / Vertragsschluss <br /> <br />
          </b>
          (1) Der Betreiber der Dating-App Tapyn (im Folgenden: „Anbieter“)
          bietet über die Webseite gegenüber Kundinnen und Kunden (nachfolgend
          zur Vereinfachung und ohne diskriminierende Absicht bzgl. des
          Geschlechtes: „Kunde“) Zugang zu einer App-basierten Dating-Plattform
          an. <br />
          <br /> (2) Der Kunde hat zunächst die Möglichkeit sich kostenlos zu
          registrieren. Der Kunde nimmt eine Registrierung mit Zugangsdaten für
          die App vor. Mit den Zugangsdaten kann sich der Kunde in den
          geschützten Bereich der App einloggen, um Online-Inhalte abzurufen.
          Der Funktionsumfang der kostenlosen Mitgliedschaft ist jedoch
          eingeschränkt. Auf die Beschreibung in der App wird Bezug genommen.{' '}
          <br />
          <br /> (3) Für die Nutzung einiger Funktionen muss der Kunde eine
          kostenpflichtige Mitgliedschaft abschließen oder Coins als digitale
          Währung erwerben. Durch die kostenpflichtige Mitgliedschaft müssen
          keine zusätzlichen Coins erworben werden, um z.B. Nachrichten
          verschicken zu können. Einzelne gekennzeichnete Inhalte sind jedoch
          nicht in der Mitgliedschaft enthalten und können zusätzlich erworben
          werden. Der Vertragsschluss kommt jeweils mit Durchlaufen des
          Buchungsprozesses in der App zustande, der über das Zahlungssystem von
          Apple abgewickelt wird. Der Kunde erhält eine Auftragsbestätigung. Vor
          dem Abschluss einer Bestellung eines Abos oder eines Einzelkaufes
          müssen die AGB sowie die Hinweise zum Datenschutz und die
          Widerrufsbelehrung durch den Kunden akzeptiert werden. <br />
          <br /> (4) Der Kunde versichert zum Zeitpunkt des Vertragsschlusses
          mindestens 18 Jahre zu sein. Eine Altersüberprüfung darf vom Anbieter
          vorgenommen werden. Der Kunde erklärt sein Einverständnis zur
          Verarbeitung seiner Daten zu diesem Zwecke.
          <br />
          <br /> (5) Bezüglich des Umfangs der angebotenen Leistungen wird auf
          die jeweilige Leistungsbeschreibung in der App Bezug genommen.
          <br />
          <br /> (6) Der Kunde ist Verbraucher im Sinne von § 13 BGB, soweit der
          Zweck der georderten Lieferungen und Leistungen nicht überwiegend
          seiner gewerblichen oder selbständigen beruflichen Tätigkeit
          zugerechnet werden kann. Dagegen ist Unternehmer gemäß § 14 BGB jede
          natürliche oder juristische Person oder rechtsfähige
          Personengesellschaft, die beim Abschluss des Vertrags in Ausübung
          ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.
          <br />
          <br /> (7) Der Vertragsschluss erfolgt in deutscher oder englischer
          Sprache. Der Vertragstext wird unter Wahrung der
          datenschutzrechtlichen Bestimmungen gespeichert.
          <br />
          <br />
          <b>
            § 2 Leistungen des Anbieters <br /> <br />
          </b>{' '}
          (1) Bei Abschluss der Mitgliedschaft oder beim Erwerb von Coins erhält
          der Kunde die Möglichkeit bestimmte Funktionen in der App (z.B.
          Versand von Nachrichten) zu verwenden.
          <br />
          <br /> (2) Der Anbieter sorgt für die Möglichkeit zur Vermittlung von
          Kontakten und stellt die dafür erforderliche Infrastruktur der App zur
          Verfügung.
          <br />
          <br /> (3) Erworbene Coins können nicht ausgezahlt werden. Coins sind
          zeitlich unbegrenzt gültig.
          <br />
          <br /> (4) Der Kunde ist dafür verantwortlich geeignete
          Abspielsoftware (Handy-Betriebssystem) bereit zu halten, welches von
          der App unterstützt wird. Der Anbieter ist bemüht die App durch
          Updates aktuell zu halten.
          <br />
          <br /> (5) Der Anbieter fungiert als Vermittler der
          Kontaktmöglichkeiten zwischen den registrierten Kunden. Das
          Zustandekommen von Kontakten oder Beziehungen ist nicht geschuldet.
          Gegenstand des Vertrags ist ausdrücklich nicht die Ehevermittlung oder
          Eheanbahnung. Der Anbieter ist berechtigt professionelle
          Kontaktpersonen in der App einzusetzen.
          <br />
          <br />
          <b>§ 3 Laufzeit und Kündigung bei einer Mitgliedschaft</b>
          <br />
          <br /> (1) Die kostenpflichtige Mitgliedschaft hat eine Laufzeit von
          einer Woche und kann jederzeit zum Ende der Woche gekündigt werden.{' '}
          <br />
          <br />
          (2) Der Kunde kann eine beendete Mitgliedschaft jederzeit erneut
          abschließen. Der Anbieter bietet eine kostenlose Testmitgliedschaft
          von 24h an. Diese kann nur einmal pro Kunde in Anspruch genommen
          werden.
          <br />
          <br /> (3) Das Recht zur außerordentlichen Kündigung aus wichtigem
          Grund bleibt unberührt.
          <br />
          <br /> (4) Die Kündigung bedarf zur Wirksamkeit der Textform. Für den
          Zeitpunkt der Kündigung ist der Zugang der Kündigung maßgeblich.
          Alternativ ist die Kündigung auch durch Löschung des Accounts durch
          den Kunden möglich.
          <br />
          <br />
          <b>§ 3a Erlöschen des Widerrufrechtes</b>
          <br />
          <br />
          (1) Das Widerrufsrecht erlischt beim Kauf von Coins mit Gutschrift der
          Coins im Account. Beim Abschluss einer Mitgliedschaft erlischt das
          Widerrufsrecht, sobald die Mitgliedschaft aktiviert wurde und somit
          Zugriff auf die Funktionen der App besteht. Beim Erwerb von
          Zusatzinhalten erlischt das Widerrufsrecht, sobald auf die Inhalte
          zugegriffen werden kann. <br />
          <br /> (2) Im Übrigen wird auf die Widerrufsbelehrung verwiesen.
          <br />
          <br />
          <b>§ 4 Urheberrechte des Anbieters</b>
          <br />
          <br />
          (1) Die Urheberrechte an sämtlichen aufrufbaren Inhalten verbleibt
          beim Anbieter, sofern die Rechte nicht beim Kunden liegen.
          <br />
          <br /> (2) Dem Kunden ist es untersagt, seine Zugangsdaten weiter zu
          verkaufen oder diese Dritten zu überlassen. Der Kunde versichert, mit
          den Zugangsdaten sorgfältig umzugehen.
          <br />
          <br /> (3) Im Fall einer unberechtigten Nutzung der Inhalte durch den
          Kunden oder einen Dritten, der vom Kunden Zugang zu den Inhalten
          erhalten hat, verpflichtet sich der Kunde zur Zahlung einer vom
          Anbieter nach billigem Ermessen festzusetzenden und im Streitfall vom
          zuständigen Gericht zu überprüfenden Vertragsstrafe pro
          Verletzungshandlung.
          <br />
          <br /> <b>§ 5 Vergütung und Zahlungsabwicklung</b>
          <br />
          <br /> (1) Es gelten die in der App und in Angeboten des Anbieters
          angegebenen Preise zum Zeitpunkt des Vertragsschlusses. <br />
          <br /> (2) Der Anbieter behält sich vor die angegebenen Preise im
          Rahmen von Sonderaktionen zu reduzieren, ohne dass sich ein Kunde
          während der Laufzeit auf den Aktionspreis berufen kann. <br />
          <br /> (3) Die Zahlung erfolgt über die in der App angebotenen
          Zahlungsmethoden. Im Falle fehlender Deckung des Kontos des Kunden,
          gehen die daraus entstehenden Gebühren zu Lasten des Kunden. <br />
          <br /> (4) Die Zahlung ist sofort mit der Buchung fällig. Kommt der
          Kunde mit der Zahlung durch eine unberechtigte Rückbuchung in Verzug,
          ist der Anbieter berechtigt Verzugszinsen nach den gesetzlichen
          Bestimmungen zu fordern. In diesem Fall hat er dem Anbieter für das
          Jahr Verzugszinsen i.H.v. 5 Prozentpunkten über dem Basiszinssatz zu
          zahlen. Ist der Kunde Unternehmer, so betragen die Verzugszinsen 9
          Prozentpunkte über dem Basiszinssatz.
          <br />
          <br />
          (5) Die Verpflichtung des Kunden zur Zahlung von Verzugszinsen
          schließt die Geltendmachung weiterer Verzugsschäden durch den Anbieter
          nicht aus. Dem Kunden bleibt es vorbehalten einen geringeren Schaden
          des Anbieters nachzuweisen. <br />
          <br /> (6) Soweit der Kunde mit einer Zahlung in Verzug ist, ist der
          Anbieter berechtigt vom Vertrag zurück zu treten. <br /> <br />
          <b>§ 6 Pflichten des Kunden</b>
          <br />
          <br />
          (1) Der Kunde trägt selbst die Verantwortung für die Nutzung der
          angebotenen Inhalte. Die Kunden verpflichten sich zum respektvollen
          Umgang miteinander. Beleidigungen, diffamierende, rechtsradikale,
          jugendgefährdende, verfassungsfeindliche, pornographische oder
          sonstige rechtsverletzende Äußerungen sind zu unterlassen.
          <br />
          <br /> (2) Den Kunden ist es untersagt Kontaktmöglichkeiten (z.B.
          E-Mail Adresse oder Telefonnummer) anderen Kunden über die App zu
          kommunizieren oder diese auf andere Weise anderen Kunden mitzuteilen.{' '}
          <br />
          <br />
          (3) Die Zugangsdaten für die App sind sorgfältig aufzubewahren und vor
          dem Zugriff Dritter zu schützen. Die Benutzerkonten sind nicht auf
          einen Dritten übertragbar und dürfen nicht verkauft werden. <br />
          <br />
          (4) Die Kundendaten sind wahrheitsgemäß anzugeben. Änderungen sind dem
          Anbieter unverzüglich mitzuteilen.
          <br />
          <br /> (5) Der Kunde ist verpflichtet seine Beiträge fristgemäß zu
          zahlen.
          <br />
          <br /> (6) Eine Nutzung der Leistungen des Anbieters für geschäftliche
          Zwecke durch den Kunden ist untersagt. Insbesondere ist es dem Kunden
          untersagt die App zur Übermittlung von Werbung für Dritte zu nutzen.
          <br />
          <br /> (7) Dem Kunden ist es untersagt die Leistungen des Anbieters
          rechtsmissbräuchlich zu verwenden (z.B. Fakeprofile,
          Mehrfach-Accounts, Ausspähen von Daten, Betrugsversuche, Spam,
          massenhafte Versendung von Nachrichten, Hochladen von Schadsoftware).
          <br />
          <br /> (8) Der Kunde ist für die Sicherung und Archivierung von Daten
          selbst verantwortlich. Der Anbieter ist befugt Daten die für die
          Mitgliedschaft nicht erforderlich sind (z.B. private Nachrichten) nach
          Ablauf von 12 Monaten zu löschen. <br />
          <br />
          (9) Coins dürfen vom Kunden nicht weiterverkauft werden.
          <br />
          <br /> <b>§ 7 Sperre des Accounts / Sanktionen </b> <br />
          <br /> (1) Bei Verletzung der Pflichten aus § 6 bzw. Verstoß gegen die
          AGB behält der Anbieter sich vor, den Zugriff auf das Benutzerkonto zu
          sperren oder einzuschränken.
          <br />
          <br /> (2) Der Anbieter ist berechtigt den Vertrag außerordentlich zu
          kündigen, wenn der Kunde die Durchführung des Vertrages ungeachtet
          einer Abmahnung stört oder sich in dem Maße vertragswidrig verhält,
          dass die sofortige Aufhebung des Vertrages gerechtfertigt ist. Kündigt
          der Anbieter aus diesem Grund, so bleibt der Kunde zur Zahlung des
          Gesamtpreises verpflichtet. <br />
          <br /> (3) Der Anbieter ist berechtigt Guthaben und erzielte
          Vergütungen von Kunden einzufrieren, wenn der Kunde gegen die
          Bestimmungen der AGB verstößt oder sich anderweitig rechtswidrig
          verhält.
          <br />
          <br /> <b> 8 Haftung</b>§<br />
          <br /> (1) Ansprüche des Kunden auf Schadensersatz sind
          ausgeschlossen. Hiervon ausgenommen sind Schadensersatzansprüche des
          Kunden aus der Verletzung des Lebens, des Körpers, der Gesundheit oder
          aus der Verletzung wesentlicher Vertragspflichten (Kardinalpflichten)
          sowie die Haftung für sonstige Schäden, die auf einer vorsätzlichen
          oder grob fahrlässigen Pflichtverletzung des Anbieters, seiner
          gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Wesentliche
          Vertragspflichten sind solche, deren Erfüllung zur Erreichung des
          Ziels des Vertrags notwendig ist. <br />
          <br />
          (2) Bei der Verletzung wesentlicher Vertragspflichten haftet der
          Anbieter nur auf den vertragstypischen, vorhersehbaren Schaden, wenn
          dieser einfach fahrlässig verursacht wurde, es sei denn, es handelt
          sich um Schadensersatzansprüche des Kunden aus einer Verletzung des
          Lebens, des Körpers oder der Gesundheit.
          <br />
          <br /> (3) Der Anbieter schuldet keinen bestimmten Erfolg. Der
          Anbieter steht insbesondere nicht dafür ein, dass die Vermittlung von
          Kontaktmöglichkeiten zu einer Beziehung führt und die von den Kunden
          angegebenen Informationen der Wahrheit entsprechen. <br />
          <br /> (4) Der Anbieter haftet nicht, wenn es ohne sein Verschulden zu
          einer Nichterreichbarkeit der Webseite kommt. Es besteht insbesondere
          keine Haftung für höhere Gewalt.
          <br />
          <br />
          (5) Die Einschränkungen der Haftung gelten auch zugunsten der
          gesetzlichen Vertreter und Erfüllungsgehilfen des Anbieters, wenn
          Ansprüche direkt gegen diese geltend gemacht werden.
          <br />
          <br /> <b>§ 9 Rechte von Dritten </b>
          <br />
          <br /> (1) Der Kunde versichert, dass er über die erforderlichen
          Rechte verfügt, um Inhalte (z.B. Bilder, Texte) auf die Plattform des
          Anbieters zu übermitteln und keine Rechte Dritter entgegenstehen.{' '}
          <br />
          <br /> (2) Falls der Anbieter wegen einer Rechtsverletzung des Kunden
          von Dritten in Anspruch genommen wird, stellt der Kunden den Anbieter
          von jeglichen Ansprüchen Dritter diesbezüglich frei.
          <br />
          <br /> <b>§ 10 Datenschutz</b>
          <br />
          <br /> (1) Der Kunde ist mit der Speicherung persönlicher Daten im
          Rahmen der Geschäftsbeziehung mit dem Anbieter, unter Beachtung der
          Datenschutzgesetze, insbesondere dem BDSG und der DSGVO einverstanden.
          Eine Weitergabe von Daten an Dritte erfolgt nicht, soweit dies nicht
          zur Durchführung des Vertrages erforderlich ist oder die Einwilligung
          des Kunden vorliegt. <br />
          <br /> (2) Soweit der Kunde personenbezogene Daten von Dritten
          übermittelt, versichert dieser, dass die Einwilligung der Dritten
          eingeholt wurde. Der Kunde stellt den Anbieter von jeglichen
          Ansprüchen Dritter diesbezüglich frei. (3) Die Rechte des Kunden bzw.
          des von der Datenverarbeitung Betroffenen ergeben sich dabei im
          Einzelnen insbesondere aus den folgenden Normen der DSGVO: Artikel 7
          Abs. 3 – Recht auf Widerruf einer datenschutzrechtlichen Einwilligung
          Artikel 15 – Auskunftsrecht der betroffenen Person, Recht auf
          Bestätigung und Zurverfügungstellung einer Kopie der personenbezogenen
          Daten Artikel 16 – Recht auf Berichtigung Artikel 17 – Recht auf
          Löschung („Recht auf Vergessenwerden“) Artikel 18 – Recht auf
          Einschränkung der Verarbeitung Artikel 20 – Recht auf
          Datenübertragbarkeit Artikel 21 – Widerspruchsrecht Artikel 22 –
          Recht, nicht einer ausschließlich auf einer automatisierten
          Verarbeitung – einschließlich Profiling – beruhenden Entscheidung
          unterworfen zu werden Artikel 77 – Recht auf Beschwerde bei einer
          Aufsichtsbehörde <br />
          <br /> (4) Zur Ausübung der Rechte, wird der Kunde bzw. Betroffene
          gebeten sich per E-Mail an den Anbieter oder bei Beschwerde an die
          zuständige Aufsichtsbehörde zu wenden. <br />
          <br /> (5) Der Kunde willigt ein, dass bei Kündigung seiner
          kostenpflichtigen Mitgliedschaft diese in einen kostenlosen Account
          umgewandelt wird und die bei der Registrierung angegebenen Daten somit
          erhalten bleiben. Dem Kunden steht es frei die Löschung dieser Daten
          gegenüber dem Kunden zu verlangen. <br />
          <br /> (6) Auf die Datenschutzerklärung in der App des Anbieters wird
          verwiesen.
          <br />
          <br /> <b>§ 11 Streitschlichtung</b>
          <br />
          <br /> (1) Die Plattform der EU zur außergerichtlichen
          Online-Streitbeilegung ist unter folgender Internetadresse erreichbar:
          https://ec.europa.eu/consumers/odr/ <br />
          <br /> (2) Der Anbieter ist weder bereit noch verpflichtet, an einem
          Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
          teilzunehmen.
          <br />
          <br /> <b> § 12 Gerichtsstand und anwendbares Recht</b>
          <br />
          <br />
          (1) Die Geschäftsbeziehungen zwischen dem Anbieter und den Kunden
          unterliegen dem Recht der Bundesrepublik Deutschland unter Ausschluss
          des UN-Kaufrechts. Die gesetzlichen Vorschriften zur Beschränkung der
          Rechtswahl und zur Anwendbarkeit zwingender Vorschriften insbes. des
          Staates, in dem der Kunde als Verbraucher seinen gewöhnlichen
          Aufenthalt hat, bleiben unberührt.
          <br />
          <br /> (2) Gerichtsstand und Erfüllungsort ist der Sitz des Anbieters
          in Kirchheim (Bayern), soweit der Kunde Kaufmann im Sinne des HGB oder
          eine juristische Person des öffentlichen Rechts oder von
          öffentlich-rechtlichem Sondervermögen ist. Dasselbe gilt, wenn der
          Kunde keinen allgemeinen Gerichtsstand in Deutschland hat oder
          Wohnsitz oder gewöhnlicher Aufenthalt im Zeitpunkt der Klageerhebung
          nicht bekannt sind. <br />
          <br /> <b>§ 13 Geltungsbereich der AGB / Änderungen</b>
          <br />
          <br /> (1) Mit Vertragsschluss erklärt sich der Kunde Allgemeinen
          Geschäftsbedingungen einverstanden.
          <br />
          <br /> (2) Etwaige Geschäftsbedingungen des Kunden werden ohne
          schriftliche Bestätigung des Anbieters nicht akzeptiert.
          <br />
          <br /> (3) Der Anbieter ist berechtigt die Preise mit Wirkung für die
          Zukunft zu erhöhen, wenn dies durch steigende Kosten zur Erhaltung der
          Plattform gerechtfertigt ist. <br />
          <br /> (4) Individuell mit Kunden schriftlich vereinbarte Bedingungen
          gehen diesen AGB vor.
          <br />
          <br />
          <b> § 14 Salvatorische Klausel</b>
          <br />
          <br />
          Sollte eine Bestimmung dieser Allgemeinen Geschäftsbedingungen
          unwirksam sein oder werden, so wird die Gültigkeit der Allgemeinen
          Geschäftsbedingungen im Übrigen hiervon nicht berührt. Anstelle der
          unwirksamen Bestimmung soll eine Bestimmung treten, die im Rahmen des
          rechtlich Möglichen dem Willen der Parteien am nächsten kommt. Das
          Gleiche gilt im Falle einer Regelungslücke. <br />
          <br /> Stand: April 2022
        </ScrollView>
      </div>
      <h3 className="mt-8 opacity-80 text-center font-bold">
        ⚠️ Attention <br />
        Breaking any rule may lead to terminate your device/account forever.
      </h3>
      <div className="flex flex-row w-10/12 justify-between gap-3 mt-4">
        <Button 
        onClick={onDecline} m="w-[100%] saturate-0 brightness-[3]">
          Decline
        </Button>
        <Button 
        onClick={onAccept} m="mt-0">
          Accept
        </Button>
      </div>
    </form>
  )
}

export default Rules
