import React, { useEffect, useRef } from "react";

const AutoTextbox = ({
  value,
  setvalue,
  styles,
  id,
  onFocus,
  handleKeyPress,
}) => {
  const textboxRef = useRef(null);
  const moveCursorToEnd = () => {
    const textbox = textboxRef.current;
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(textbox);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
    textbox.focus();
  };

  useEffect(() => {
    const textbox = textboxRef.current;

    const handleInput = () => {
      setvalue(textbox.innerText);
      moveCursorToEnd();
    };

    const handleKeyDown = (e) => {
      // Check for space (key code 32) and newline (key code 13) at the beginning
      if (
        textbox.innerText.trim() === "" &&
        (e.keyCode === 32 || e.keyCode === 13)
      ) {
        e.preventDefault();
      }
    };

    textbox.addEventListener("input", handleInput);
    textbox.addEventListener("keydown", handleKeyDown);

    return () => {
      textbox.removeEventListener("input", handleInput);
      textbox.removeEventListener("keydown", handleKeyDown);
    };
  }, [setvalue]);

  return (
    <span
      ref={textboxRef}
      role="textbox"
      onKeyDown={handleKeyPress}
      onFocus={onFocus && onFocus}
      contentEditable
      suppressContentEditableWarning
      spellCheck={false}
      className={`whitespace-pre-wrap block overflow-auto resize-y ${styles}`}
      id={id}
      tabIndex={0}
    >
      {value}
    </span>
  );
};

export default AutoTextbox;
