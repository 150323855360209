import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Name from "../components/onBoarding/Name";
import TextArea from "../components/onBoarding/TextArea";
import SelectCards from "../components/onBoarding/SelectCards";
import { ChangePicDialog } from "../components/profile/DisplayPicture";
import Button from "../shared/Button";
import { Store } from "../stores/Stores";
import Rules from "../components/onBoarding/Rules";
import Signup from "./Signup";
import Loader from "../shared/loader/Loader";
import useAuth from "../hooks/useAuth";
import ProfilePic from "../assets/SVG/profileImage.svg";
import ModalImageNudes from "../components/profile/ModalImageNudes";

const OnBoarding = () => {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { getProfile } = Store().profileStore;
  const { isAuthed } = useAuth();
  const [uploading, setUploading] = useState(false);
  const [openPicker, setOpenPicker] = useState(false);
  const [showImageNudes, setShowImageNudes] = useState(false);
  const [dataNudes, setDataNudes] = useState({
    title: "",
    imageLink: "",
    label: [],
  });
  const { preferences } = Store().preferencesStore;
  const [userData, setUserData] = useState({
    name: "",
    gender: "",
    interest: [],
    preferences: [],
    about: "",
  });

  const checkUserAvatar = async () => {
    const profile = await getProfile();
    if (profile?.images?.length < 1) setStep(7);
  };
  const handleStepBack = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate(-1);
    }
  };
  const closeModalImageNudes = () => {
    setShowImageNudes(!showImageNudes);
  };
  const renderBackButton = () => (
    <button
      onClick={() => handleStepBack()}
      className="absolute left-4 top-4 text-gray-500"
      type="button" // Added type attribute
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 19l-7-7 7-7"
        />
      </svg>
    </button>
  );

  useEffect(() => {
    checkUserAvatar();
  }, [isAuthed]);
  switch (step) {
    case 0:
      return (
        <div className="p-5">
          {renderBackButton()}
          <Name
            onContinue={(name) => {
              setUserData((prv) => ({ ...prv, name }));
              setStep(1);
            }}
          />
        </div>
      );
    case 1:
      return (
        <div className="p-5">
          {renderBackButton()}
          <SelectCards
            key={step}
            values={[
              {
                value: "male",
                icon: "🙋‍♂️",
              },
              { value: "female", icon: "💁‍♀️" },
            ]}
            onContinue={(gender) => {
              setUserData((prv) => ({ ...prv, gender }));
              setStep(2);
            }}
          >
            <h3 className="text-[32px] w-9/10 leading-[44px] font-bold">
              What do you{" "}
              <span className="text-transparent bg-clip-text bg-PurpleGradient">
                identify as
              </span>
              ?
            </h3>
          </SelectCards>
        </div>
      );
    case 2:
      return (
        <div className="p-5">
          {renderBackButton()}
          <SelectCards
            key={step}
            isMulti
            values={[
              {
                value: "male",
                icon: "🙋‍♂️",
              },
              { value: "female", icon: "💁‍♀️" },
            ]}
            onContinue={(interest) => {
              setUserData((prv) => ({ ...prv, interest }));
              setStep(3);
            }}
          >
            <h3 className="text-[32px] w-9/10 leading-[44px] font-bold">
              What are you{" "}
              <span className="text-transparent bg-clip-text bg-PurpleGradient">
                interested in
              </span>
              ?
            </h3>
          </SelectCards>
        </div>
      );
    case 3:
      return (
        <div className="p-5">
          {renderBackButton()}
          <SelectCards
            key={step}
            isMulti
            values={preferences.map((prf) => ({
              value: prf.preference._id,
              imgSrc: prf.preference.image,
              label: prf.preference.name,
            }))}
            onContinue={(newPreferences) => {
              setUserData((prv) => ({ ...prv, preferences: newPreferences }));
              setStep(4);
            }}
            isLargeCard
          >
            <h3 className="text-[32px] w-9/10 leading-[44px] font-bold">
              What are your{" "}
              <span className="text-transparent bg-clip-text bg-PurpleGradient">
                preferences
              </span>
              ?
            </h3>
          </SelectCards>
        </div>
      );
    case 4:
      return (
        <div className="p-5">
          {renderBackButton()}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <div className="p-5">
                <h3 className="text-[32px] leading-[44px] font-bold">
                  Tell people{" "}
                  <span className="text-transparent bg-clip-text bg-PurpleGradient">
                    about you
                  </span>
                </h3>
                <TextArea
                  onContinue={(about) => {
                    setUserData((prv) => ({ ...prv, about }));
                    setStep(5);
                  }}
                  label="About you"
                />
              </div>
            </div>
          </div>
        </div>
      );
    case 5:
      return (
        <div className="p-5">
          {renderBackButton()}
          <Rules
            onAccept={() => {
              setUserData((prv) => ({
                ...prv,
                rulesAccepted: true,
                isOnboardingcomplete: true,
              }));
              setStep(6);
            }}
            onDecline={() =>
              alert(
                "You have to accept terms and rules in order to use the app.",
              )
            }
          />
        </div>
      );
    case 6:
      return (
        <div className="p-5">
          {renderBackButton()}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "155vh",
            }}
          >
            <div
              style={{
                width: "800px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                padding: "50px",
                borderRadius: "10px",
              }}
            >
              <Signup onBoardingData={userData} onSignup={() => setStep(7)} />
            </div>
          </div>
        </div>
      );
    case 7:
      return (
        <div className="p-5">
          {renderBackButton()}
          <form
            className="p-5"
            onSubmit={(e) => {
              e.preventDefault();
              navigate("/messages");
            }}
          >
            <div className="text-center mb-4 mt-20">
              {/* Profile Image with Rounded Corners */}
              <img
                src={ProfilePic} // Replace with your image URL
                alt="Profile Placeholder"
                className="mx-auto rounded-full"
                style={{ width: "200px", height: "200px" }} // Adjust the size as needed
              />
            </div>
            <Button type="button" onClick={() => setOpenPicker(!openPicker)}>
              Upload Profile Picture
            </Button>
            <ChangePicDialog
              signup="true"
              imgSrc=""
              isOpen={openPicker}
              handleClick={() => setOpenPicker(!openPicker)}
              refresh={() => navigate("/messages")}
              onUpload={(bool) => setUploading(bool)}
              setDataNudes={setDataNudes}
              closeModalImageNudes={closeModalImageNudes}
            />
            {uploading ? <Loader className="mx-auto mt-3" /> : ""}
          </form>
          <ModalImageNudes
            isOpen={showImageNudes}
            closeModal={closeModalImageNudes}
            dataNudes={dataNudes}
          />
        </div>
      );

    default:
      return (
        <Name
          onContinue={(name) => {
            setUserData((prv) => ({ ...prv, name }));
            setStep(1);
          }}
        />
      );
  }
};

export default OnBoarding;
