import { makeAutoObservable } from 'mobx'
import axios from '../api/axios'

const getCookie = (name) => {
  const cookieString = document.cookie
  const cookies = cookieString.split(';')
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i].trim()
    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1)
    }
  }
  return null
}

class ProfileStore {
  profile = {}
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }
  async setProfileData(data) {
    this.profile = data
  }

  async getProfile() {
    try {
      const token = getCookie('token')
      if (!token) return this.setProfileData({})
      const USER_PROFILE_URL = '/user/profile'
      const response = await axios.get(USER_PROFILE_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (response.data.result === 1) {
        this.setProfileData(response.data.data)
      }
      return response.data.data
    } catch (err) {
      console.log(err)
      return err
    }
  }
}

const profileStore = new ProfileStore()
export default profileStore
