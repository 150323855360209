import React, { useState } from 'react'
import Loader from './loader/Loader'

const Img = ({ src, alt, className, onClick }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  function handleLoad() {
    setIsLoading(false)
  }

  function handleError() {
    setHasError(true)
    setIsLoading(false)
  }

  return (
    <div className={`${className || ''} relative`}>
      {isLoading && (
        <Loader className="absolute left-1/2 top-1/2 transform translate-y-1/2 translate-x-1/2" />
      )}

      {hasError && (
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          Error loading image
        </div>
      )}

      {!hasError && (
        <img
          src={src}
          onClick={onClick}
          className="w-full object-inherit rounded-inherit"
          alt={alt}
          onLoad={handleLoad}
          onError={handleError}
        />
      )}
    </div>
  )
}
export default Img
