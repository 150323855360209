import React, { useState } from 'react'
import Layout from '../templates/Layout'
// import categories from '../content/categories'
import PreferenceCard from '../components/card/PreferenceCard'
import SearchUsers from '../components/discover/SearchUsers'
import { Store } from '../stores/Stores'

const Discover = () => {
  const [isOpen, setisOpen] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedCategoryName, setSelectedCategoryName] = useState('')
  const { preferences } = Store().preferencesStore

  const chooseCategory = (id, name) => {
    setisOpen((prev) => !prev)
    setSelectedCategory(id)
    setSelectedCategoryName(name)
  }
  return (
    <>
      <Layout>
        <header className="fixed top-0 inset-x-0 z-50 bg-white overflow-hidden">
          <h1 className="text-black text-2xl font-bold p-5">
            Choose your category
          </h1>
        </header>
        <section className="h-auto pb-16 absolute top-73px bottom-73px inset-0 overflow-y-auto">
          <div className="mt-5 grid px-4 grid-cols-2 gap-3 place-items-center">
            {preferences.map((category) => (
              <PreferenceCard
                key={category.preference._id}
                title={category.preference.name}
                imgSrc={category.preference.image}
                // emoji={category.emoji}
                handleClick={() =>
                  chooseCategory(
                    category.preference._id,
                    category.preference.name
                  )
                }
                id={category.preference._id}
              />
            ))}
          </div>
        </section>
      </Layout>
      {isOpen && (
        <SearchUsers
          name={selectedCategoryName}
          categoryId={selectedCategory}
          isOpen={isOpen}
          setisOpen={setisOpen}
        />
      )}
    </>
  )
}

export default Discover
