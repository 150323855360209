const categories = [
  {
    title: 'Over18',
    emoji: '👀',
  },
  {
    title: 'Beer in the park',
    emoji: '🍺',
  },
  {
    title: 'Gamer Buddys',
    emoji: '🎮',
  },
  {
    title: 'Homework',
    emoji: '📃',
  },
  {
    title: 'Music',
    emoji: '🎧',
  },
  {
    title: 'Deeptalks',
    emoji: '💭',
  },
  {
    title: 'Business Talk',
    emoji: '👔',
  },
  {
    title: 'New Series',
    emoji: '🍿',
  },
  {
    title: 'Sports',
    emoji: '🥇',
  },
]

export default categories
