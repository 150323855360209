import React from 'react'

const Email = ({ children, email, setemail }) => (
  <div className="mt-7 font-semibold space-y-2.5 text-black-dark">
    <label htmlFor={children}>{children}</label>
    <br />
    <input
      id={children}
      type="email"
      value={email}
      name=""
      placeholder="example@gmail.com"
      className="input-box"
      onChange={(e) => setemail(e.target.value)}
    />
  </div>
)

export default Email
