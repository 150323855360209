import React from 'react'
import ModalLayout from '../../../../templates/ModalLayout'

const Licenses = ({ isOpen, setisOpen }) => (
  <ModalLayout title="Licenses" isOpen={isOpen} setisOpen={setisOpen}>
    <iframe
      title="licences"
      className="w-full h-[90%]"
      src="https://sharify.de/tapyn_agb.html"
    />
  </ModalLayout>
)

export default Licenses
