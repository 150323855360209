import React, { useEffect } from "react";
import About from "./modals/profile-modals/About";
import Category from "./modals/profile-modals/Category";
import Legal from "./modals/profile-modals/Legal";
import Name from "./modals/profile-modals/Name";
import Stats from "./modals/profile-modals/Stats";
import ControPanel from "./modals/control-panel-modals/ControlPanel";
import CloudMediaChat from "./CloudMediaChat";
import CloudFolderModal from "./CloudFolderModal";
import AgeModal from './modals/profile-modals/AgeModal';

const CurrentProfileModal = ({
  modal,
  isOpen,
  setisOpen,
  content,
  setuser,
  callApi,
  id,
  setmessages,
  cloudChat,
}) => {
  let Component;
  useEffect(() => {
    console.log("CurrentProfileModal is redered",modal);
  }, []);
  switch (modal) {
    case "Name":
      Component = (
        <Name
          callApi={callApi}
          isOpen={isOpen}
          setisOpen={setisOpen}
          content={content}
          setuser={setuser}
        />
      );
      break;
    case "About you":
      Component = (
        <About
          callApi={callApi}
          isOpen={isOpen}
          setisOpen={setisOpen}
          content={content}
          setuser={setuser}
        />
      );
      break;
    case "Age":
      Component = (
        <AgeModal
          callApi={callApi}
          isOpen={isOpen}
          setisOpen={setisOpen}
          content={content}
          setuser={setuser}
        />
      );
      break;
    case "Category":
      Component = (
        <Category
          isOpen={isOpen}
          setisOpen={setisOpen}
          content={content}
          setuser={setuser}
        />
      );
      break;
    case "cloud":
      Component = (
        <CloudFolderModal
          setmessages={setmessages}
          id={id}
          isOpen={isOpen}
          setisOpen={setisOpen}
          cloudChat={cloudChat}
        />
      );
      break;
    case "cloudChat":
      Component = (
        <CloudMediaChat
          isOpen={isOpen}
          setisOpen={setisOpen}
          toUser={id}
          setmessages={setmessages}
        />
      );
      break;
    case "Legal":
      Component = (
        <Legal
          isOpen={isOpen}
          setisOpen={setisOpen}
          content={content}
          setuser={setuser}
        />
      );
      break;
    case "Stats":
      Component = <Stats isOpen={isOpen} setisOpen={setisOpen} />;
      break;
    case "ControlPanel":
      Component = <ControPanel isOpen={isOpen} setisOpen={setisOpen} />;
      break;
    default:
      Component = <div />;
  }
  return Component;
};

export default CurrentProfileModal;
