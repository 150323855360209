import React, { useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import fallbackImg from "../../assets/SVG/profilePic.svg";

export const DisplayPicture = ({
  imgSrc,
  handleClick,
  loadingFileUploaded,
}) => {
  const [isLoading, setisLoading] = useState(true);
  return (
    <div className="flex relative justify-center items-center flex-col">
      <img
        src={imgSrc !== undefined ? imgSrc : fallbackImg}
        className={`h-100px w-100px rounded-full ${isLoading && "opacity-0"}`}
        alt=""
        onLoad={() => setisLoading(false)}
      />
      {isLoading && (
        <div className="h-100px w-100px rounded-full animate-pulse bg-gray-veryLight absolute top-0" />
      )}
      {loadingFileUploaded ? (
        <button type="button" className="mt-2.5 font-semibold text-sm">
          Uploading photo please wait..
        </button>
      ) : (
        <button
          type="button"
          className="mt-2.5 font-semibold text-sm"
          onClick={handleClick}
        >
          Change profile photo
        </button>
      )}
    </div>
  );
};

export const ChangePicDialog = ({
  imgSrc,
  isOpen,
  handleClick,
  refresh,
  onUpload,
  signup,
  setDataNudes,
  closeModalImageNudes,
  setLoadingFileUploaded,
}) => {
  const inputFile = useRef();
  const [file, setfile] = useState(null);

  const { getAccessToken } = useAuth();
  const token = getAccessToken();
  const AVATAR_URL = "/user/avatar";
  const postAvatar = async (formData) => {
    setLoadingFileUploaded(true);
    try {
      if (onUpload) onUpload(true);
      handleClick();
      const addAvatarResponse = await axios.post(AVATAR_URL, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("holaaa", addAvatarResponse);
      if (addAvatarResponse.data.result === 3) {
        const objNudes = {
          title: addAvatarResponse.data.error,
          imageLink: addAvatarResponse.data.image.base64,
          label: addAvatarResponse.data.labels,
        };
        setDataNudes(objNudes);
        closeModalImageNudes();
      } else {
        refresh();
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      if (onUpload) onUpload(false);
      setLoadingFileUploaded(false);
    }
  };
  useEffect(() => {
    if (file !== null) {
      const formData = new FormData();
      formData.append("file", file);
      postAvatar(formData);
    }
  }, [file]);

  const removeAvatar = async () => {
    try {
      if (onUpload) onUpload(true);
      await axios.delete(AVATAR_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      handleClick();
      refresh();
    } catch (e) {
      console.log("error ", e);
    } finally {
      if (onUpload) onUpload(false);
    }
  };
  const update = () => {
    handleClick();
  };
  const openFileManger = () => {
    inputFile.current.click();
  };

  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        className="fixed z-20 px-12 flex justify-center items-center inset-0 overflow-y-auto"
        onClose={update}
      >
        <Dialog.Overlay className="fixed inset-0 -z-10 bg-greyGradient" />
        <div className="w-full flex justify-center">
          <form className="w-full flex flex-col items-center bg-white pt-5 pb-9 rounded-20px max-w-sm">
            <Dialog.Title className="font-bold text-lg">
              Change profile photo
            </Dialog.Title>
            <input
              type="file"
              id="file"
              ref={inputFile}
              className="hidden"
              onChange={(e) => setfile(e.target.files[0])}
              accept="image/*"
            />
            <button
              type="button"
              className={`font-medium mt-14 pb-4 w-full ${
                !signup ? "border-b border-[#DEDEDE]" : null
              }`}
              onClick={openFileManger}
            >
              New profile photo
            </button>
            {!signup ? (
              <button
                type="button"
                className="text-primary-mildPink font-medium mt-4 w-full"
                onClick={imgSrc === undefined ? update : removeAvatar}
              >
                Remove profile photo
              </button>
            ) : null}
          </form>
        </div>
      </Dialog>
    </Transition>
  );
};
