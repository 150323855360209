/* eslint-disable */
import React from "react";

const Button = ({ children, m, onClick, id, className, disabled, type }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center", // Horizontal centering
      alignItems: "center", // Vertical centering
      // height: '100vh',
      width: "30%",
    }}
  >
    <button
      disabled={disabled || false}
      id={id !== "" ? id : ""}
      type={type || "submit"}
      className={
        className
          ? className
          : `btn bg-PurpleGradient w-full flex justify-center items-center text-center font-bold py-4 rounded-20px text-white ${
              m !== undefined ? m : "mt-11"
            }`
      }
      onClick={onClick}
      style={{ maxWidth: "600px" }}
    >
      {children}
    </button>
  </div>
);

export default Button;
