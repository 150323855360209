import React, { useState, useEffect, createContext } from 'react'
import Cookies from 'js-cookie'
import socket from '../socket/socket'

export const AuthContext = createContext()

export const authState = () => {
  const [isAuthed, setisAuthed] = useState(false)

  useEffect(() => {
    if (window !== undefined) {
      try {
        const token = Cookies.get('token')
        const userId = localStorage.getItem('user')
        if (token !== undefined && userId !== undefined) {
          setisAuthed(true)
        } else setisAuthed(false)
      } catch (e) {
        console.log(e)
      }
    }
    return () => {
      setisAuthed(false)
    }
  }, [isAuthed])

  const persistUser = (response, reset) => {
    let isErr;
    if (window !== undefined) {
      try {
        Cookies.set('token', response?.data?.token, {
          expires: 1,
        });
        localStorage.setItem('user', response?.data?.user?._id); // Save userId to local storage
        isErr = false;
        setisAuthed(true);
      } catch (e) {
        console.log(e);
        isErr = e;
      }
      reset();
    }
    return isErr;
  };
  const logout = () => {
    let isErr
    if (window !== undefined) {
      try {
        Cookies.remove('token')
        localStorage.removeItem('user')
        isErr = false
        setisAuthed(false)
        socket.disconnect()
      } catch (e) {
        isErr = e
      }
    }
    return isErr
  }
  const getAccessToken = () => {
    const ACCESSTOKEN = Cookies.get('token') ? Cookies.get('token') : null
    return ACCESSTOKEN
  }
  const getUserId = () => {
    const USERID = localStorage.getItem('user')
      ? localStorage.getItem('user')
      : null
    return USERID
  }
  return {
    isAuthed,
    logout,
    persistUser,
    getAccessToken,
    getUserId,
  }
}

export const AuthProvider = ({ children }) => {
  const auth = authState()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
