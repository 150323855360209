import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import useAuth from "../../hooks/useAuth";
import ChatPanel from "./ChatPanel";
import socket from "../../socket/socket";
import axios from "../../api/axios";
import logo from "../../assets/SVG/logo-sm.svg";
import Loader from "../../shared/loader/Loader";
import { Store } from "../../stores/Stores";

const EmptyChat = () => (
  <div className="flex flex-col items-center">
    <img src={logo} alt="logo" />
    <h2 className="mt-7 pt-0.5 text-2xl font-bold text-black-light text-center">
      Go to the Discover screen and chat with people!
    </h2>
  </div>
);

const ChatList = observer(({ refresh, setRefresh }) => {
  const { getAccessToken } = useAuth();
  const token = getAccessToken();
  const { setChats, chats } = Store().chatStore;
  const [isEmpty, setisEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [numberPageChat, setNumberPageChat] = useState(1);
  const scrollElement = useRef(null);
  const CHAT_READ_URL = "/chat/read";
  const CHAT_LIST = "/userChatListInsteadOfSocket";

  let isRequesting = false; // Track whether a request is in progress
  const mergeUniqueArrays = (array1, array2) => {
    const mergedArray = [...array1, ...array2];
    const uniqueChats = Array.from(
      new Map(mergedArray.map((chat) => [chat.userId, chat])).values(),
    );
    return uniqueChats.sort(
      (a, b) =>
        new Date(b.lastMessage.updatedAt) - new Date(a.lastMessage.updatedAt),
    );
  };
  const handleUserList = (newList) => {
    console.log("Received userList event with data:", newList);
    const mergedList = mergeUniqueArrays(chats, newList);
    setChats(mergedList);
    setLoading(false);
    setLoadingPagination(false);
    setisEmpty(mergedList.length === 0);
  };
  console.log("numberrr", numberPageChat);
  const fetchAffiliatechats = async (pagenumber) => {
    if (chats.length === 0 && numberPageChat === 1) {
      console.log("fetna awal");
      setLoading(true);
    } else if (chats.length > 0 && numberPageChat !== 1) {
      console.log("fetna teni");
      setLoading(true);
    } else {
      setLoading(false);
    }
    const data2 = {
      page: pagenumber,
    };
    try {
      const response = await axios.post(CHAT_LIST, data2, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        if (chats.length === 0 && numberPageChat === 1) {
          handleUserList(response.data.data);
        } else if (chats.length > 0 && numberPageChat !== 1) {
          handleUserList(response.data.data);
        } else {
          console.log("not loading");
        }

        console.log("hello wael");
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  const refreshFetchAffiliatechats = async (pagenumber) => {
    setLoading(true);

    const data2 = {
      page: pagenumber,
    };
    try {
      const response = await axios.post(CHAT_LIST, data2, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        handleUserList(response.data.data);

        console.log("hello wael");
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    // const fetchUserList = () => {
    //   console.log('Emitting userList event with page:', numberPageChat);
    // //  socket.emit('userList', { page: numberPageChat });
    //   fetchAffiliatechats(numberPageChat)
    // };

    if (numberPageChat > 1) {
      console.log(
        "Emitting userList event for pagination with page:",
        numberPageChat,
      );
      // socket.emit('userList', { page: numberPageChat });
      fetchAffiliatechats(numberPageChat);
    }

    if (chats.length > 0) {
      setLoading(false);
    }
  }, [numberPageChat]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = scrollElement.current;
      const isBottom = scrollTop + clientHeight >= scrollHeight - 5;

      // Check if we've reached the bottom, if a request isn't already in progress, and if we're not already loading more data
      if (isBottom && !isRequesting && !loadingPagination) {
        isRequesting = true; // Indicate that a request is starting
        setNumberPageChat((prev) => prev + 1);
        setLoadingPagination(true);
      }
    };

    const scrollEl = scrollElement.current;
    scrollEl.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the scroll event listener
    return () => {
      scrollEl.removeEventListener("scroll", handleScroll);
    };
  }, [loadingPagination]);

  useEffect(() => {
    if (!loadingPagination) {
      isRequesting = false; // Reset the flag when we're not loading pagination anymore
    }
  }, [loadingPagination]);

  useEffect(() => {
    const fetchChatList = () => {
      if (chats.length > 0 && numberPageChat !== 1) {
        setLoadingPagination(true);
      }

      // socket.emit('userList', { page: 1 });
      fetchAffiliatechats(1);
    };
    fetchChatList();
  }, []);
  useEffect(() => {
    if (refresh) {
      refreshFetchAffiliatechats();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    const handleUpdateUserList = (updatedChat) => {
      console.log("ussssss", updatedChat);
      // Check if the updated chat already exists in the chats list
      const existingChatIndex = chats.findIndex(
        (chat) => chat.userId === updatedChat.userId,
      );

      if (existingChatIndex !== -1) {
        // If the chat exists, update its details
        const updatedChats = [...chats];
        const [existingChat] = updatedChats.splice(existingChatIndex, 1); // Remove the existing chat from its current position
        const updatedChatDetails = { ...existingChat, ...updatedChat }; // Merge updated details into existing chat
        setChats([updatedChatDetails, ...updatedChats]); // Add the updated chat to the top and spread the rest
      } else {
        // If the chat doesn't exist, add it to the top of the list
        setChats([updatedChat, ...chats]);
      }
    };
    const handleConnectError = () => {
      console.log("err connect socket");
    };
    console.log("waelll matttt");
    socket.on("updateUserList", handleUpdateUserList);
    socket.on("connect_error", handleConnectError);

    return () => {
      socket.off("updateUserList", handleUpdateUserList);
      socket.off("connect_error", handleConnectError);
    };
  }, [chats]);

  const handleChatClick = async (userId) => {
    await axios.post(
      CHAT_READ_URL,
      { userId },
      { headers: { Authorization: `Bearer ${token}` } },
    );
    const updatedChats =
      chats &&
      chats.map((chat) =>
        chat.userId === userId
          ? { ...chat, lastMessage: { ...chat.lastMessage, isRead: true } }
          : chat,
      );

    setChats(updatedChats);
  };

  return (
    <section
      className="h-screen overflow-y-scroll px-5 pt-5 absolute top-73px bottom-73px inset-0"
      ref={scrollElement}
    >
      <div
        className={`${
          chats.length > 0
            ? "space-y-5"
            : "flex justify-center items-center h-full"
        }`}
        style={{ paddingBottom: "10.25rem" }}
      >
        {loading ? (
          <Loader className="fixed top-[45%] left-[47%]" />
        ) : (
          chats.map(
            (user) =>
              user.name && (
                <ChatPanel
                  handleClick={() => handleChatClick(user.userId)}
                  key={user.userId}
                  {...user}
                />
              ),
          )
        )}
        {loadingPagination && !isEmpty && (
          <Loader className="fixed top-[45%] left-[47%]" />
        )}
      </div>
      {isEmpty && !loading && <EmptyChat />}
    </section>
  );
});

export default ChatList;
