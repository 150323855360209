import React from 'react';
import IOSDialog from '../../../../shared/IOSDialog';

const ReqDelProfile = ({ isOpen, setisOpen, onDeleteConfirm }) => {
  const update = () => {
    setisOpen(!isOpen);
  };

  return (
    <IOSDialog update={update} isOpen={isOpen} title="Delete Account?">
      <p className="mt-0.5 text-sm text-center px-5">
        This action deletes all your data and is irreversible.
      </p>
      <div className="mt-5 border-t-0.5px border-gray-divider px-4 grid grid-cols-2 w-full">
        <button
          type="button"
          className="border-r-0.5px border-gray-divider py-3 text-[#007AFF] text-lg"
          onClick={update}  // Close the modal on cancel
        >
          Cancel
        </button>
        <button
          type="button"
          className="text-[#FF6464] text-lg font-semibold"
          onClick={() => {
            onDeleteConfirm();  // Trigger the deletion process from parent component
            update();  // Optionally close the modal
          }}
        >
          Delete
        </button>
      </div>
    </IOSDialog>
  );
}

export default ReqDelProfile;
