import React, { useState } from 'react'

import fallbackImg from '../../assets/SVG/profilePic.svg'

const SelectableUserCard = ({ name, about, avatar, onClick }) => {
  const [isLoading, setisLoading] = useState(true)
  return (
    <div aria-hidden onClick={onClick} className="flex justify-between py-4">
      <div className="flex items-center relative">
        <img
          className="w-20 h-20 rounded-full"
          src={avatar !== '' ? avatar : fallbackImg}
          alt={avatar !== '' ? avatar : 'not available'}
          onLoad={() => setisLoading(false)}
        />
        {isLoading && (
          <div className="w-20 h-20 rounded-full animate-pulse bg-gray-veryLight absolute top-0" />
        )}
        <div className="flex ml-6 flex-col max-w-xs text-gray-veryLight">
          <h3 className="text-xl text-black-light font-semibold">{name}</h3>
          <p className="text-gray-semiDark text-xs font-semibold">{about}</p>
        </div>
      </div>
    </div>
  )
}

export default SelectableUserCard
