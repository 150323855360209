import React from 'react'

export const Show = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.25 21a.746.746 0 0 1-.53-.22L3.22 4.28a.75.75 0 0 1 1.06-1.06l16.5 16.5a.75.75 0 0 1-.53 1.28Zm-8.625-6.194-2.428-2.428a.094.094 0 0 0-.158.08 3.005 3.005 0 0 0 2.507 2.506.094.094 0 0 0 .08-.158Zm.75-5.611 2.431 2.43a.094.094 0 0 0 .109.018.093.093 0 0 0 .05-.097 3.006 3.006 0 0 0-2.51-2.51.094.094 0 0 0-.08.159Z"
      fill="#17161E"
    />
    <path
      d="M23.016 12.814a1.508 1.508 0 0 0-.005-1.63c-1.24-1.918-2.85-3.547-4.653-4.712-1.999-1.29-4.202-1.972-6.373-1.972a10.62 10.62 0 0 0-3.367.553.187.187 0 0 0-.073.31l2.215 2.215a.187.187 0 0 0 .179.049 4.5 4.5 0 0 1 5.437 5.438.187.187 0 0 0 .05.178l3.185 3.188a.188.188 0 0 0 .253.01 16.115 16.115 0 0 0 3.152-3.627ZM12 16.5a4.501 4.501 0 0 1-4.373-5.56.188.188 0 0 0-.05-.18L4.444 7.627a.188.188 0 0 0-.253-.01c-1.144.975-2.204 2.162-3.172 3.549a1.496 1.496 0 0 0-.03 1.666c1.238 1.937 2.83 3.569 4.606 4.718 2.002 1.295 4.151 1.951 6.39 1.951a11.165 11.165 0 0 0 3.405-.541.189.189 0 0 0 .125-.135.187.187 0 0 0-.05-.177l-2.225-2.224a.188.188 0 0 0-.178-.05A4.494 4.494 0 0 1 12 16.5Z"
      fill="#17161E"
    />
  </svg>
)

export const Hide = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" fill="#000" />
    <path
      d="M23.008 11.184c-1.24-1.918-2.85-3.547-4.653-4.712-1.996-1.29-4.199-1.972-6.371-1.972-1.993 0-3.953.57-5.825 1.693-1.91 1.145-3.639 2.818-5.141 4.972a1.496 1.496 0 0 0-.03 1.666c1.238 1.937 2.83 3.569 4.606 4.718 2 1.295 4.151 1.951 6.39 1.951 2.19 0 4.397-.676 6.384-1.956 1.803-1.16 3.41-2.796 4.645-4.73a1.51 1.51 0 0 0-.005-1.63ZM12 16.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Z"
      fill="#000"
    />
  </svg>
)
