import React, { useEffect } from 'react'
import ModalLayout from '../../../../templates/ModalLayout'
import LegalPanels from '../../LegalPanels'

const Legal = ({ isOpen, setisOpen }) => {
  useEffect(() => {
    console.log('Legal Intiatiated')
  })
  return (
    <ModalLayout title="Legal" isOpen={isOpen} setisOpen={setisOpen}>
      <div className="mt-5 mx-5 space-y-5">
        <LegalPanels title="Imprint" />
        <LegalPanels title="Privacy Policy" />
        <LegalPanels title="Licences" />
        <LegalPanels title="Request Data" />
        <LegalPanels title="Delete Profile" />
      </div>
    </ModalLayout>
  )
}

export default Legal
