import React, { useState, useEffect, useRef } from 'react'
import ModalLayout from '../../templates/ModalLayout'
import UserCard from '../card/UserCard'
import Search from '../../assets/SVG/Vector.svg'
import axios from '../../api/axios'
import useAuth from '../../hooks/useAuth'
// import { getDbName } from './ConvertCategory'

const SearchUsers = ({ isOpen, setisOpen, categoryId, name }) => {
  const [searchInput, setSearchInput] = useState('')
  const [userList, setuserList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const USER_CATEGORY_URL = '/user/category'
  const { getAccessToken } = useAuth()
  const scrollElement = useRef(null)
  const fetchUsersFromCategory = async () => {
    const token = getAccessToken()
    const searchInfo = {
      categoryId,
      search: searchInput,
      page: currentPage,
    }
    try {
      const res = await axios.post(USER_CATEGORY_URL, searchInfo, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (res && res.data && res.data.result === 1) {
        if (currentPage === 1) {
          setuserList(res.data.users)
        } else {
          setuserList((prevUsers) => [...prevUsers, ...res.data.users])
        }
      } else if (res && res.data && res.data.result === 0 && currentPage === 1)
        setuserList([])
    } catch (err) {
      console.log('Error', err)
    }
  }

  const handleScroll = (scroller = scrollElement.current) => {
    // Check if the scroll position reached the bottom
    if (
      scroller.target.scrollTop + scroller.target.clientHeight >=
      scroller.target.scrollHeight
    )
      setCurrentPage((prevPage) => prevPage + 1)
  }
  const handleChange = (value) => {
    setCurrentPage(1)
    setSearchInput(value)
  }
  useEffect(() => {
    fetchUsersFromCategory()
    scrollElement.current.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [searchInput])

  useEffect(() => {
    if (currentPage > 1) {
      fetchUsersFromCategory()
    }
  }, [currentPage])

  return (
    <ModalLayout title={name} isOpen={isOpen} setisOpen={setisOpen}>
      <div className="h-screen overflow-y-scroll" ref={scrollElement}>
        <div className="mt-5 mx-5">
          <div className="border-0.5px px-4 py-3 rounded-3xl w-full font-semibold text-sm textbox-highlight">
            <div className="flex justify-between items-stretch w-full">
              <input
                type="search"
                className="text-sm font-normal w-11/12 text-black text-opacity-40 focus:outline-none"
                placeholder=" search for a user"
                value={searchInput}
                onChange={(e) => handleChange(e.target.value)}
              />
              <button type="button">
                <img src={Search} alt={Search} />
              </button>
            </div>
          </div>

          <div className="mt-4">
            {userList.map((user) => (
              <UserCard
                key={user.id}
                id={user.id}
                name={user.name}
                about={user.about}
                avatar={user.avatar}
              />
            ))}
          </div>
        </div>
      </div>
    </ModalLayout>
  )
}

export default SearchUsers
