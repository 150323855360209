import React, { useEffect, useState } from "react";
import ModalLayout from "../../templates/ModalLayout";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
// import closeImage from "../../assets/PNG/close.png";
import playBtn from "../../assets/SVG/sentPlay.svg";
import "./Style.css";
import OpenImage from "../messages/OpenImage";
import OpenVideo from "../messages/OpenVideo";
import CoinModalCloud from "../messages/CoinModalCloud";
import Loader from "../../shared/loader/Loader";

const CloudMediaChat = ({
  isOpen,
  setisOpen,
  toUser,
  setmessages,
  folderId2,
  nameFolder,
  closeModalFolder,
}) => {
  const { getAccessToken, getUserId } = useAuth();
  const fromUser = getUserId();
  //  const [file, setfile] = useState(null);
  const [dataMedia, setDataMedia] = useState([]);
  const token = getAccessToken();
  // const fileInputRef = useRef(null);
  // const [loadingFileUploaded, setLoadingFileUploaded] = useState(false);
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [isOpenCoin, setIsOpenCoin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values2, setValues2] = useState(null);
  const [coin, setCoin] = useState(0);
  const [lock, setLock] = useState(false);
  // const [isactioned, setIsactioned] = useState(false);
  const [urlVerify, setUrlVerify] = useState("");

  //   const postAvatar = async (formData) => {
  //     setLoadingFileUploaded(true);
  //     try {
  //       const addAvatarResponse = await axios.post("media/manage", formData, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });

  //       if (addAvatarResponse) {
  //         setLoadingFileUploaded(false);
  //         setIsactioned(true);
  //       }
  //     } catch (e) {
  //       console.log("error", e);
  //     }
  //   };
  //   useEffect(() => {
  //     if (file !== null) {
  //       const formData = new FormData();
  //       formData.append("file", file);
  //       postAvatar(formData);
  //     }
  //   }, [file]);
  async function sendCloudMedia(values) {
    setLoading(true);
    const data = {
      userID: toUser,
      isLocked: lock,
      coins: coin,
      mediaKey: values.key,
    };

    let fileTemp;
    if (values.type === "image") {
      fileTemp = {
        fromUser,
        downloadURL: values.url,
        type: values.type,
        isLocked: lock,
        coins: coin,
      };
    } else {
      fileTemp = {
        fromUser,
        video: {
          video: values.url,
          thumbnail: values.thumbnailUrl,
        },
        type: values.type,
        isLocked: lock,
        coins: coin,
      };
    }
    const res = await axios.post("/media/send", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res) {
      setLoading(false);
      setmessages((prev) => [...prev, fileTemp]);
      setisOpen(!isOpen);
      closeModalFolder(false);
      setLock(false);
      setCoin(0);
      console.log("sucessssss");
    }
  }
  useEffect(() => {
    if (coin > 0 && lock && values2) {
      sendCloudMedia(values2);
    }
  }, [values2, lock, coin]);
  async function getCloudMedia() {
    setLoading(true);
    const data = {
      folderId: folderId2,
      pageNumber: 1,
    };
    const res = await axios.post("media/fetch/gallery", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res) {
      setLoading(false);
      setDataMedia(res.data.media);
    }
  }
  useEffect(() => {
    getCloudMedia();
  }, []);
  const handleCancelLockMedia = () => {
    setLock(false);
    setCoin(0);
    setUrlVerify("");
  };
  const handleLockMedia = (val) => {
    setUrlVerify(val.url);
    setIsOpenCoin(!isOpenCoin);
  };
  //   const handleUploadClick = () => {
  //     fileInputRef.current.click();
  //   };

  //   const handleFileChange = () => {
  //     const files = fileInputRef.current.files[0];
  //     setfile(files);
  //   };
  return (
    <ModalLayout
      title={`${nameFolder} folder`}
      isOpen={isOpen}
      setisOpen={setisOpen}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          gap: "10px",
          marginTop: "20px",
          padding: "0 10px",
        }}
      >
        {dataMedia &&
          dataMedia.map((val) => {
            if (val.type === "image") {
              return (
                <div style={{ position: "relative" }}>
                  <img
                    key={val.url}
                    src={val.url}
                    style={{
                      width: "100%",
                      height: "180px",
                      objectFit: "scale-down",
                      border: "2px solid #e62e66",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      setIsOpenImage(!isOpenImage);
                      setUrlVerify(val.url);
                    }}
                    alt={val.url}
                  />
                  {/* <img
                    src={closeImage}
                    alt="close"
                    style={{
                      width: "20px",
                      height: "20px",
                      position: "absolute",
                      top: -10,
                      right: -7,
                    }}
                  /> */}
                  {urlVerify === val.url && (
                    <OpenImage
                      imgSrc={val.url}
                      isOpen={isOpenImage}
                      setisOpen={setIsOpenImage}
                    />
                  )}
                  <div
                    style={{
                      width: "100%",
                      border: "2px solid #e62e66",
                      margin: "5px 0",
                      borderRadius: "5px",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "#e62e66",
                        cursor: "pointer",
                      }}
                      role="button"
                      tabIndex="0"
                      onClick={() => sendCloudMedia(val)}
                    >
                      Send
                    </div>
                    {lock && urlVerify === val.url ? (
                      <div
                        style={{
                          width: "50%",
                          backgroundColor: "#e62e66",
                          color: "#fff",
                          fontWeight: "bold",
                          textAlign: "center",
                          cursor: "pointer",
                          zIndex: 11111,
                        }}
                        role="button"
                        tabIndex="0"
                        onClick={() => handleCancelLockMedia(val)}
                      >
                        Cancel
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "50%",
                          backgroundColor: "#e62e66",
                          color: "#fff",
                          fontWeight: "bold",
                          textAlign: "center",
                          cursor: "pointer",
                          zIndex: 11111,
                        }}
                        role="button"
                        tabIndex="0"
                        onClick={() => {
                          handleLockMedia(val);
                          setValues2(val);
                        }}
                      >
                        Lock Media
                      </div>
                    )}
                  </div>
                </div>
              );
            }
            return (
              <div
                style={{
                  position: "relative",
                }}
              >
                <img
                  key={val.url}
                  src={val.thumbnailUrl}
                  style={{
                    width: "100%",
                    height: "180px",
                    objectFit: "scale-down",
                    border: "2px solid #e62e66",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    setIsOpenImage(!isOpenImage);
                    setUrlVerify(val.url);
                  }}
                  alt={val.url}
                />
                {/* <img
                  src={closeImage}
                  alt="close"
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    top: -10,
                    right: -7,
                  }}
                /> */}

                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    style={{ width: "25px", height: "25px" }}
                    src={playBtn}
                    alt="control"
                  />
                </div>
                {urlVerify === val.url && (
                  <OpenVideo
                    id={val.url}
                    thumbnail={val.thumbnailUrl}
                    videoSrc={val.url}
                    isOpen={isOpenImage}
                    setisOpen={setIsOpenImage}
                  />
                )}
                <div
                  style={{
                    width: "100%",
                    border: "2px solid #e62e66",
                    margin: "5px 0",
                    borderRadius: "5px",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "#e62e66",
                      cursor: "pointer",
                      zIndex: 11111,
                    }}
                    role="button"
                    tabIndex="0"
                    onClick={() => sendCloudMedia(val)}
                  >
                    Send
                  </div>
                  {lock && urlVerify === val.url ? (
                    <div
                      style={{
                        width: "50%",
                        backgroundColor: "#e62e66",
                        color: "#fff",
                        fontWeight: "bold",
                        textAlign: "center",
                        cursor: "pointer",
                        zIndex: 11111,
                      }}
                      role="button"
                      tabIndex="0"
                      onClick={() => handleCancelLockMedia(val)}
                    >
                      Cancel
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "50%",
                        backgroundColor: "#e62e66",
                        color: "#fff",
                        fontWeight: "bold",
                        textAlign: "center",
                        cursor: "pointer",
                        zIndex: 11111,
                      }}
                      role="button"
                      tabIndex="0"
                      onClick={() => {
                        handleLockMedia(val);
                        setValues2(val);
                      }}
                    >
                      Lock Media
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      {isOpenCoin && (
        <CoinModalCloud
          isOpenCoin={isOpenCoin}
          setIsOpenCoin={setIsOpenCoin}
          setCoin={setCoin}
          setLock={setLock}
        />
      )}
      {loading && (
        <Loader className="fixed top-[45%] left-[47%] transform -translate-x-1/2 -translate-y-1/2" />
      )}
    </ModalLayout>
  );
};

export default CloudMediaChat;
