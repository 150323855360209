import React from 'react'
import ModalLayout from '../../../../templates/ModalLayout'

const Privacy = ({ isOpen, setisOpen }) => (
  <ModalLayout title="Privacy" isOpen={isOpen} setisOpen={setisOpen}>
    <iframe
      title="privacy"
      className="w-full h-[90%]"
      src="https://sharify.de/tapyn_datenschutz.html"
    />
  </ModalLayout>
)

export default Privacy
