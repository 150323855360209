import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../shared/Button';
import ErrText from '../shared/ErrText';
import Password from '../shared/Password';
import EntryLayout from '../templates/EntryLayout';
import axios from '../api/axios';

const Reset = () => {
  const [password, setpassword] = useState('');
  const [cpassword, setcpassword] = useState('');
  const [error, seterror] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleClick = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password !== cpassword) {
      seterror(true);
      return;
    }

    // Extract token from URL
    const queryParams = new URLSearchParams(window.location.search);
    const resetToken = queryParams.get('token');

    setLoading(true);

    try {
      const response = await axios.put('/resetpassword', {
        resetPasswordLink: resetToken,
        newPassword: password,
      });

      console.log(response);

      if (response.data.message) {
        setSuccess(true);
        seterror(false);
        setLoading(false);
        // Wait for a few seconds before navigating to the login screen
        setTimeout(() => {
          navigate('/login');
        }, 4000);
      }
    } catch (err) {
      // Handle errors
      seterror(true);
      setSuccess(false);
      setLoading(false);
    }
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '120vh',
  };

  const cardStyle = {
    width: '800px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    padding: '50px',
    borderRadius: '10px',
  };

  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        <EntryLayout>
          <form onSubmit={handleClick} className="mt-14" autoComplete="off">
            <Password password={password} setpassword={setpassword}>
              {t('New Password')}
            </Password>
            <Password password={cpassword} setpassword={setcpassword}>
              {t('Confirm New Password')}
            </Password>
            {loading ? <div>Loading...</div> : ''}
            {error && !success && !loading ? (
              <ErrText>Error resetting password</ErrText>
            ) : (
              ''
            )}
            {success && !loading ? (
              <div className="text-green-500">Password reset successful! You will be redirected to the login screen shortly.</div>
            ) : (
              ''
            )}
            <Button disabled={loading}>{t('Save')}</Button>
          </form>
        </EntryLayout>
      </div>
    </div>
  );
};

export default Reset;
