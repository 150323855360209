import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import useAuth from '../../hooks/useAuth'
import axios from '../../api/axios'

const DeleteImage = ({ imgSrc, isOpen, handleClick, refresh }) => {
  const { getAccessToken } = useAuth()
  const token = getAccessToken()
  const ADD_IMAGE_URL = '/user/image'

  const removeAvatar = async () => {
    try {
      await axios.delete(ADD_IMAGE_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: imgSrc,
      })
      handleClick()
      refresh()
    } catch (e) {
      console.log('error ', e)
    }
  }
  const update = () => {
    handleClick()
  }
  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        className="fixed z-20 px-12 flex justify-center items-center inset-0 overflow-y-auto"
        onClose={update}
      >
        <Dialog.Overlay className="fixed inset-0 -z-10 bg-greyGradient" />
        <div className="w-full">
          <form className="flex flex-col items-center bg-white pt-5 pb-9 rounded-20px max-w-sm">
            <Dialog.Title className="font-bold text-lg">Images</Dialog.Title>
            <button
              type="button"
              className="text-primary-mildPink font-medium mt-4 w-full"
              onClick={imgSrc === undefined ? update : removeAvatar}
            >
              Delete Image
            </button>
          </form>
        </div>
      </Dialog>
    </Transition>
  )
}
export default DeleteImage
