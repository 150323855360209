import React from 'react'
import MessagePanel from '../components/messages/MessagePanel'
import Layout from '../templates/Layout'

const Messages = () => {
  console.log('messages')
  return (
    <Layout>
      <MessagePanel />
    </Layout>
  )
}

export default Messages
