import React, { useState, useEffect } from "react";
import axios from "../../../../api/axios";
import useAuth from "../../../../hooks/useAuth";
import ModalLayout from "../../../../templates/ModalLayout";
// import AccountInfo from "./AccountInfo";
// import Edit from "../../../../assets/SVG/editIcon.svg";
// import AccountDetails from "../../AccountDetails";
// import TransactionHistory from "./TransactionHistory";
// import StatsChart from "../../StatsChart";
import ModalCalendarCoins from "../../ModalCalendarCoins";
import ChartCalendar from "../../ChartCalendar";
import ChartProfits from "../../ChartProfits";
import "../../Style.css";

const Stats = ({ isOpen, setisOpen }) => {
  const [account, setacccount] = useState({
    firstname: "",
    address: "",
    IBAN: "",
    BIC: "",
    paypal: "",
  });
  const [transactions, settransactions] = useState([]);
  const [profitsData, setProfitsData] = useState({});
  const [dateRange, setDateRange] = useState(["", ""]);
  // const [isAccOpen, setisAccOpen] = useState(false);
  const { getAccessToken } = useAuth();
  const token = getAccessToken();
  const ACCOUNT_INFO_URL = "/affiliate/account";
  const RECENT_TRANSACTION_URL = "/affiliate/transaction-history";
  const PROFITS_TOTAL = "/affiliate/getProfit";
  console.log("h", transactions);
  console.log("b", account);
  const fetchUserProfile = async () => {
    try {
      console.log("calling Api -> costly");
      const response = await axios.get(ACCOUNT_INFO_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response?.data);
      if (response?.data) {
        //  checking if the object has those properties and making sure we don't assign undefined to our properties
        const firstname = response?.data?.name ? response?.data?.name : "";
        const address = response?.data?.address ? response?.data?.address : "";
        const IBAN = response?.data?.IBAN ? response?.data?.IBAN : "";
        const BIC = response?.data?.BIC ? response?.data?.BIC : "";
        const paypal = response?.data?.paypal ? response?.data?.paypal : "";
        setacccount({
          firstname,
          address,
          IBAN,
          BIC,
          paypal,
        });
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const getRecentTransactions = async () => {
    try {
      console.log("calling Api -> costly");
      const response = await axios.get(RECENT_TRANSACTION_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        console.log(response);
        settransactions(response.data.history);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };
  const getProfitsTotal = async () => {
    try {
      const response = await axios.post(
        PROFITS_TOTAL,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response) {
        setProfitsData(response.data);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };
  useEffect(() => {
    console.log("Stats");
    fetchUserProfile();
    getRecentTransactions();
    getProfitsTotal();
  }, []);

  return (
    <ModalLayout title="Statistics" isOpen={isOpen} setisOpen={setisOpen}>
      {/* <section className="px-5 mt-5">
        <StatsChart />
        <div className="flex justify-between mb-5 mt-14">
          <h3 className="font-bold text-xl">Account Info</h3>
          <button type="button" onClick={() => setisAccOpen(!isAccOpen)}>
            <img src={Edit} alt={Edit} />
          </button>
        </div>
        <AccountDetails formData={account} disabled />
        <div className="mt-9 pt-1 pb-10">
          <h4 className="text-xs font-semibold">Recent Transaction</h4>
          <TransactionHistory transactions={transactions} />
        </div>
      </section> */}
      {/* {isAccOpen && (
        <AccountInfo
          isOpen={isAccOpen}
          setisOpen={setisAccOpen}
          details={account}
          updateDetails={updateDetails}
        />
      )} */}
      <ModalCalendarCoins
        dateRange={dateRange}
        setDateRange={setDateRange}
        profitsData={profitsData}
      />
      {dateRange[0] !== "" && dateRange[1] !== "" ? (
        <div className="flex-column">
          <div
            style={{
              backgroundColor: "#e7043133",
              padding: "10px 40px",
              width: "90%",
              borderRadius: "15px",
            }}
          >
            <ChartCalendar dateRange={dateRange} />
          </div>
          <div
            style={{
              width: "90%",
              backgroundColor: "#e7043133",
              padding: "10px 40px",
              borderRadius: "15px",
            }}
          >
            <ChartProfits dateRange={dateRange} />
          </div>
        </div>
      ) : null}
    </ModalLayout>
  );
};

export default Stats;
