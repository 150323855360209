import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import fallbackImg from '../../assets/SVG/profilePic.svg'

const UserCard = ({ id, name, about, avatar, onClick }) => {
  const [isLoading, setisLoading] = useState(true)
  return (
    <div aria-hidden onClick={onClick} className="flex justify-between py-4">
      <Link
        // onClick={handleClick}
        to="/chat"
        state={{
          name,
          avatar,
          id,
        }}
        className="flex"
      >
        <div className="flex items-center relative">
          <img
            className="w-14 h-14 rounded-full"
            src={avatar !== '' ? avatar : fallbackImg}
            alt={avatar !== '' ? avatar : 'not available'}
            onLoad={() => setisLoading(false)}
          />
          {isLoading && (
            <div className="w-14 h-14 rounded-full animate-pulse bg-gray-veryLight absolute top-0" />
          )}
          <div className="flex ml-4 flex-col max-w-xs text-gray-veryLight">
            <h3 className="text-xl text-black-light font-semibold">{name}</h3>
            <p className="text-gray-semiDark text-xs font-semibold">{about}</p>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default UserCard
