import { Dialog, Transition } from '@headlessui/react';
import React, { useRef } from 'react';

const TextModal = ({ isOpen, setIsOpen, messageToEdit, editMessage, setEditedMessage }) => {
  console.log(messageToEdit)
  const inputBox = useRef(null);

  const closeModal = () => {
    setIsOpen(false);
  };

  const updateText = async () => {
    const newText = inputBox.current.value;
    const editedMessageObj = {
      id: messageToEdit.id,
      message: newText,
    }

    try {
      await editMessage(editedMessageObj.id, editedMessageObj.message); // Call the messageToEdit function with the new text
      setEditedMessage(editedMessageObj); // Update the edited message in your state
      setIsOpen(false);
    } catch (err) {
      // Handle the error
      console.error("Error editing message", err);
    }
  };

  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        className="fixed z-50 px-5 flex justify-center items-center inset-0 overflow-y-auto overflow-x-hidden"
        initialFocus={inputBox}
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 z-10 bg-grayGradient" />
        <div className="text-center z-50">
          <div className="w-335px max-w-md transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all">
            <h3 className="text-xl mt-10 text-center font-bold leading-6 text-gray-900">
              Edit Message
            </h3>
            <div className="mt-2.5 flex justify-center gap-2">
              <textarea
                ref={inputBox}
                className="w-64 px-2 h-32 text-xl text-black bg-gray-vvvl rounded-xl resize-none"
                defaultValue={messageToEdit.message} // Set the default value to the messageToEdit.message
              />
            </div>
            <div className="flex justify-center mt-10 mb-10">
              <button
                type="button"
                className="inline-flex justify-center border border-transparent bg-PurpleGradient rounded-xl px-4 py-2 text-sm font-bold text-white w-295px focus:outline-none focus-visible:ring-2"
                onClick={updateText}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default TextModal;
