import React, { useEffect, useRef, useState } from "react";
import ModalLayout from "../../templates/ModalLayout";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import closeImage from "../../assets/PNG/close.png";
import playBtn from "../../assets/SVG/sentPlay.svg";
import "./Style.css";
import OpenImage from "../messages/OpenImage";
import OpenVideo from "../messages/OpenVideo";
import Loader from "../../shared/loader/Loader";

const CloudMediaModal = ({ isOpen, setisOpen, folderId2, nameFolder }) => {
  const { getAccessToken } = useAuth();
  const [files, setfiles] = useState([]);
  const [dataMedia, setDataMedia] = useState([]);
  const token = getAccessToken();
  const fileInputRef = useRef(null);
  const [loadingFileUploaded, setLoadingFileUploaded] = useState(false);
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [isactioned, setIsactioned] = useState(false);
  const [loading, setLoading] = useState(false);
  const [urlVerify, setUrlVerify] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [uploadProgress, setUploadProgress] = useState(0);

  const maxFiles = 10;
  const postAvatar = async (formData) => {
    setLoadingFileUploaded(true);
    try {
      const addAvatarResponse = await axios.post(
        `/media/manage?folderId=${folderId2}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            setUploadProgress(percentCompleted);
          },
        },
      );

      if (addAvatarResponse.data.result === 1) {
        setLoadingFileUploaded(false);
        setIsactioned(!isactioned);
      } else {
        // handle error response
        console.log("Error:", addAvatarResponse.data.message);
      }
    } catch (e) {
      console.log("error", e);
      setLoadingFileUploaded(false);
    }
  };

  const deleteCloudMediaByKey = async (Key) => {
    const data = { mediaKey: Key };

    // Display the confirmation dialog
    const confirmation = window.confirm(
      "Are you sure you want to delete the media?",
    );

    if (confirmation) {
      try {
        const deleteAvatarResponse = await axios.post("/media/delete", data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (deleteAvatarResponse) {
          setDataMedia([]);
        }
      } catch (e) {
        console.log("error", e);
      } finally {
        setIsactioned(!isactioned);
      }
    }
  };
  useEffect(() => {
    if (files.length !== 0) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i += 1) {
        formData.append("files", files[i]);
      }
      postAvatar(formData);
    }
  }, [files]);

  async function getCloudMedia() {
    setLoading(true);
    const data = {
      folderId: folderId2,
      pageNumber: currentPage,
    };
    const res = await axios.post("media/fetch/gallery", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res) {
      const mergedArray = [
        ...dataMedia,
        ...res.data.media.filter(
          (item) => !dataMedia.some((i) => i.key === item.key),
        ),
      ];
      console.log("arrrr0", mergedArray.length);
      setTotalPages(res.data.totalPages);
      setDataMedia(mergedArray);
      setLoading(false);
    }
  }
  const handlePageChange = (pageNumber) => {
    if (currentPage < totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  useEffect(() => {
    getCloudMedia();
  }, [isactioned, currentPage]);
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = () => {
    const files2 = fileInputRef.current.files;

    if (files2.length > maxFiles) {
      alert(`You can only upload a maximum of ${maxFiles} files.`);
      fileInputRef.current.value = ""; // Clear the file input
    } else {
      setfiles(files2);
    }
  };
  return (
    <ModalLayout
      title={`${nameFolder} folder`}
      isOpen={isOpen}
      setisOpen={setisOpen}
    >
      <div style={{ padding: "10px 20px" }}>
        <input
          type="file"
          id="upload"
          multiple
          ref={fileInputRef}
          hidden
          onChange={handleFileChange}
        />
        <button
          type="button"
          style={{
            backgroundColor: "#e62e66",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
          }}
          onClick={() => (loadingFileUploaded ? null : handleUploadClick())}
        >
          {loadingFileUploaded ? "Uploading..." : "Upload Cloud Media"}
        </button>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          gap: "10px",
          marginTop: "20px",
          padding: "0 10px",
        }}
      >
        {dataMedia &&
          dataMedia.map((val) => {
            if (val.type === "image") {
              return (
                <div
                  style={{ position: "relative" }}
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    setIsOpenImage(!isOpenImage);
                    setUrlVerify(val.url);
                  }}
                >
                  <img
                    key={val.url}
                    src={val.url}
                    style={{
                      width: "100%",
                      height: "180px",
                      objectFit: "scale-down",
                      border: "2px solid #e62e66",
                      borderRadius: "5px",
                    }}
                    alt={val.url}
                  />
                  <span
                    role="button"
                    tabIndex="0"
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteCloudMediaByKey(val.key);
                    }}
                    style={{ position: "absolute", top: -10, right: -7 }}
                  >
                    <img
                      src={closeImage}
                      alt="close"
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </span>

                  {urlVerify === val.url && (
                    <OpenImage
                      imgSrc={val.url}
                      isOpen={isOpenImage}
                      setisOpen={setIsOpenImage}
                    />
                  )}
                </div>
              );
            }
            return (
              <div
                role="button"
                tabIndex="0"
                style={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
                onClick={() => {
                  setIsOpenImage(!isOpenImage);
                  setUrlVerify(val.url);
                }}
              >
                <img
                  key={val.url}
                  src={val.thumbnailUrl}
                  style={{
                    width: "100%",
                    height: "180px",
                    objectFit: "scale-down",
                    border: "2px solid #e62e66",
                    borderRadius: "5px",
                  }}
                  alt={val.url}
                />
                <span
                  role="button"
                  tabIndex="0"
                  onClick={(event) => {
                    event.stopPropagation();
                    deleteCloudMediaByKey(val.key);
                  }}
                  style={{ position: "absolute", top: -10, right: -7 }}
                >
                  <img
                    src={closeImage}
                    alt="close"
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </span>

                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    style={{ width: "25px", height: "25px" }}
                    src={playBtn}
                    alt="control"
                  />
                </div>
                {urlVerify === val.url && (
                  <OpenVideo
                    id={val.url}
                    thumbnail={val.thumbnailUrl}
                    videoSrc={val.url}
                    isOpen={isOpenImage}
                    setisOpen={setIsOpenImage}
                  />
                )}
              </div>
            );
          })}
      </div>
      {currentPage < totalPages && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "10px 0",
          }}
        >
          <button
            style={{
              color: "#fff",
              padding: "10px",
              backgroundColor: "#e62e66",
              borderRadius: "7px",
            }}
            type="button"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Show More
          </button>
        </div>
      )}
      {loading && (
        <Loader className="fixed top-[45%] left-[47%] transform -translate-x-1/2 -translate-y-1/2" />
      )}
      {files && uploadProgress < 99 &&  uploadProgress >= 1 &&(
        <div className="fixed top-[45%] left-[47%] transform -translate-x-1/2 -translate-y-1/2 z-50">
          <div
            style={{
              padding: "10px",
              backgroundImage:
                "linear-gradient(90deg, #E62E66 0%, #E7002C 100%)",
              color: "#fff",
              borderRadius: "100%",
            }}
          >
            {uploadProgress}%
          </div>
        </div>
      )}

      {loadingFileUploaded && uploadProgress >= 99  &&(
        <Loader className="fixed top-[45%] left-[47%] transform -translate-x-1/2 -translate-y-1/2" />
      )}

      {!files && null}
    </ModalLayout>
  );
};

export default CloudMediaModal;
