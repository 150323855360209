const getThumbnailForVideo = async (videoUrl) => {
  const video = document.createElement('video')
  const canvas = document.createElement('canvas')
  video.style.display = 'none'
  canvas.style.display = 'none'

  // Trigger video load
  await new Promise((resolve) => {
    video.addEventListener('loadedmetadata', () => {
      console.dir(video)
      video.width = video.videoWidth
      video.height = video.videoHeight
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      // Seek the video to 25%
      if (video.duration === Infinity) {
        video.currentTime = 1e101
      } else video.currentTime = video.duration * 0.25
    })
    video.addEventListener('seeked', () => resolve())
    video.src = videoUrl
  })

  // Draw the thumbnailz
  canvas
    .getContext('2d')
    .drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
  const imageUrl = canvas.toDataURL('image/png')
  return imageUrl
}

export default getThumbnailForVideo
