import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import closeBtn from '../../assets/SVG/close.svg'

const OpenImage = ({ imgSrc, isOpen, setisOpen }) => {
  const close = () => {
    setisOpen(false)
  }

  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog className="fixed z-50 inset-0 overflow-y-auto" onClose={close}>
        <Dialog.Overlay className="fixed inset-0 bg-greyGradient -z-10 pointer-events-none" />
        <div className="z-30 h-screen flex items-center justify-center">
          <button
            type="button"
            onClick={close}
            className="absolute top-10 right-5 z-40"
          >
            <img src={closeBtn} alt={closeBtn} />
          </button>
          <div className="relative">
            <img
              className="max-h-screen max-w-screen h-auto w-auto"
              alt={imgSrc}
              src={imgSrc}
            />
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default OpenImage
