import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const ProtectedRoutes = () => {
  const { isAuthed } = useAuth();
  const location = useLocation();

  return isAuthed ? (
    <Outlet />
  ) : (
    <Navigate
      to="/login"
      replace
      state={location.pathname !== "/" ? { path: location.pathname } : ""}
    />
  );
};

export default ProtectedRoutes;
