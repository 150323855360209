import React, { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import useMessageContext from "../../hooks/useMessageContext";
import axios from "../../api/axios";
import logo from "../../assets/SVG/logo-sm.svg";
import Loader from "../../shared/loader/Loader";
import BroadcastChatPanel from "./BroadcastChatPanel";

const EmptyChat = () => (
  <div className="flex flex-col items-center">
    <img src={logo} alt={logo} />
    <h2 className="mt-7 pt-0.5 text-2xl font-bold text-black-light text-center">
      Go to the Discover
      <br /> screen and chat with
      <br /> people!
    </h2>
  </div>
);

const ChatListGE = ({ scrollElement }) => {
  console.log("helloo ge");
  const { readState, filteredState } = useMessageContext();
  const [userList, setuserList] = useState([]);
  const [filterUser, setFilterUser] = filteredState;
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [numberPageChat, setNumberPageChat] = useState(1);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [isEmpty, setisEmpty] = useState(false);
  const { getAccessToken } = useAuth();
  const token = getAccessToken();
  const [readId, setreadId] = readState;
  const CHAT_READ_URL = "/chat/read";
  const CHAT_BROADCAST_LANG = "/affiliate/broadcastList";
  const updateUnreadChat = async (userId) => {
    await axios.post(
      CHAT_READ_URL,
      { userId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  };

  // honn
  const fetchUsersFromCategory = async () => {
    console.log("helloo ge2");
    const data = {
      page: numberPageChat,
      language: "de",
    };
    console.log("helloo ge2", data);
    try {
      const res = await axios.post(CHAT_BROADCAST_LANG, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res && res.data) {
        if (res.data.length === 0) setisEmpty(true);
        setuserList(res.data);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    fetchUsersFromCategory();
  }, [numberPageChat]);

  function mergeUniqueArrays(array1, array2) {
    // Merge the arrays
    const mergedArray = [...array1, ...array2];

    // Create a Map with a custom key to identify unique objects
    const uniqueMap = new Map();

    // Filter out duplicate objects
    const uniqueArray = mergedArray.filter((obj) => {
      const key = obj.userId; // Use a unique identifier for each object, like 'id'
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, obj);
        return true;
      }
      return false;
    });

    return uniqueArray;
  }
  useEffect(() => {
    if (filteredUserList.length > 0) {
      if (userList.length > 0) {
        const uniqueObjects = mergeUniqueArrays(userList, filteredUserList);
        setFilteredUserList(uniqueObjects);
        setFilterUser(uniqueObjects);
        setLoadingPagination(false);
      }
      setLoadingPagination(false);
    } else {
      setLoading(true);

      if (userList.length > 0) {
        const uniqueObjects = mergeUniqueArrays(userList, filteredUserList);
        setFilteredUserList(uniqueObjects);
        setFilterUser(uniqueObjects);
        setLoadingPagination(false);
        setLoading(false);
      }
      setLoadingPagination(false);
    }
  }, [userList]);

  const handleScroll = (scroller = scrollElement.current) => {
    if (
      scroller.target.scrollTop + scroller.target.clientHeight >=
      scroller.target.scrollHeight
    ) {
      setNumberPageChat((prevStat) => prevStat + 1);
      setLoadingPagination(true);
    }
  };
  useEffect(() => {
    const scrollElementto = scrollElement.current;
    scrollElementto.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the event listener when the component unmounts
      scrollElementto.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // honn

  useEffect(() => {
    if (readId !== "") {
      const index = filteredUserList.findIndex(
        (user) => user.userId === readId,
      );
      if (index >= 0) {
        const tempArr = [...filteredUserList];
        tempArr[index].lastMessage.isRead = true;
        setFilteredUserList(tempArr);
        setFilterUser(tempArr);
        setreadId("");
      }
    }
  }, [readId]);

  return (
    <div
      className={`${
        filteredUserList.length > 0
          ? "space-y-5"
          : "flex justify-center items-center h-full"
      }`}
      style={{ paddingBottom: "10.25rem" }}
    >
      {!loading ? (
        filterUser
          .sort((a, b) => {
            const dateA = new Date(
              a.lastMessage.updatedAt.toString(),
            ).getTime();
            const dateB = new Date(
              b.lastMessage.updatedAt.toString(),
            ).getTime();

            if (dateA > dateB) {
              return -1;
            }
            if (dateA < dateB) {
              return 1;
            }
            return 0;
          })
          .map((user) => (
            <BroadcastChatPanel
              userList={filterUser}
              setuserList={setFilterUser}
              handleClick={() =>
                !user.lastMessage.isRead && updateUnreadChat(user.userId)
              }
              key={user.userId}
              userId={user.userId}
              name={user.name}
              isSubscribed={user.isSubscribed}
              isSelected={user.isSelected ? user.isSelected : false}
              avatar={user.avatar}
              lastMessage={user.lastMessage}
            />
          ))
      ) : (
        <EmptyChat />
      )}
      {loadingPagination && !isEmpty ? (
        <Loader className="fixed top-[45%] left-[47%] transform -translate-x-1/2 -translate-y-1/2" />
      ) : null}

      {loading ? (
        <Loader className="fixed top-[45%] left-[47%] transform -translate-x-1/2 -translate-y-1/2" />
      ) : null}
    </div>
  );
};

export default ChatListGE;
