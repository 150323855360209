import React from 'react'
import message from '../assets/SVG/message.svg'
import messageActive from '../assets/SVG/messageActive.svg'
import discover from '../assets/SVG/discover.svg'
import discoverActive from '../assets/SVG/discoverActive.svg'
import profile from '../assets/SVG/profile.svg'
import profileActive from '../assets/SVG/profileActive.svg'

export const MessageIcon = ({ active }) => (
  <div>
    <img src={active ? messageActive : message} alt={message} />
  </div>
)

export const DiscoverIcon = ({ active }) => (
  <div>
    <img src={active ? discoverActive : discover} alt={discover} />
  </div>
)

export const ProfileIcon = ({ active }) => (
  <div>
    <img src={active ? profileActive : profile} alt={profile} />
  </div>
)
