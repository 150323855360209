import React, { useState } from 'react';
import fallbackImg from '../../assets/SVG/profilePic.svg';

const SelectedUserCard = ({ avatar, id, onRemoveClick, about }) => {
  const [isLoading, setisLoading] = useState(true);
  const handleRemoveClick = () => {
    onRemoveClick(id);
  };

  return (
    <div
      aria-hidden
      onClick={handleRemoveClick}
      className="w-full h-full relative flex flex-col items-center"
    >
      <div className="relative w-full h-24 flex justify-center items-center">
        <img
          className="w-15 h-15 rounded-full"
          src={avatar !== '' ? avatar : fallbackImg}
          alt={avatar}
          onLoad={() => setisLoading(false)}
        />
        {isLoading && (
          <div className="w-15 h-15 rounded-full animate-pulse bg-gray-veryLight absolute top-0" />
        )}
      </div>
      {about && (
        <p className="text-center mt-2 text-xs">{about}</p>
      )}
    </div>
  );
};

export default SelectedUserCard;
