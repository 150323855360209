import React, { useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { toJS } from 'mobx'
import ModalLayout from '../../../../templates/ModalLayout'
import IOSDialog from '../../../../shared/IOSDialog'
import { Store } from '../../../../stores/Stores'
import axios from '../../../../api/axios'
import useAuth from '../../../../hooks/useAuth'

const Category = ({ isOpen, setisOpen, content, setuser }) => {
  const [selectedCategories, setselectedCategories] = useState(content);
  const [noCategorySelected, setnoCategorySelected] = useState(false);
  const [updated, setUpdated] = useState(false);
  const { preferences } = Store().preferencesStore
  const userPreferences = toJS(preferences)
  const { getAccessToken } = useAuth()
  const token = getAccessToken()

  function isSelected(value) {
    return selectedCategories.find((el) => el.name === value) ? 1 : 0
  }

  function handleDeselect(value) {
    const selectedCategoryUpdated = selectedCategories.filter(
      (el) => el.name !== value
    )
    if (selectedCategoryUpdated.length === 0) {
      setnoCategorySelected(!noCategorySelected)
    } else {
      setselectedCategories(selectedCategoryUpdated)
    }
  }

  function handleSelect(value) {
    if (!isSelected(value)) {
      const selectedCategoryUpdated = [
        ...selectedCategories,
        userPreferences.find((el) => el.preference.name === value)?.preference,
      ]
      setselectedCategories(selectedCategoryUpdated)
    } else {
      handleDeselect(value)
    }
  }

  async function putPreferences() {
    try {
      const EDIT_USER_URL = '/user/profile'
      const res = await axios.put(
        EDIT_USER_URL,
        {
          preferences: selectedCategories.map((category) => category._id),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (res) {
        setuser((prev) => ({
          ...prev,
          categories: selectedCategories,
          isUpdated: true,
        }))
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if (isOpen) {
      setselectedCategories(content);
      setUpdated(false);
    }
  }, [isOpen, content]);

  return (
    <ModalLayout
      title="Category"
      isOpen={isOpen}
      setisOpen={setisOpen}
      hasTick={updated}
      handleClick={() => putPreferences()}
    >
      <Listbox
        value={selectedCategories.map((cat) => cat._id)}
        className="font-bold mt-7"
        multiple
        onChange={(val) => {
          setUpdated(true)
          handleSelect(val)
        }}
      >
        <Transition
          show
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="space-y-10 px-7" as="ul">
            {userPreferences.map((category) => {
              const selected = isSelected(category.preference.name)
              return (
                <Listbox.Option
                  key={category.preference._id}
                  value={category.preference.name}
                  className="w-full flex justify-between items-center"
                >
                  <div>{category.preference.name}</div>
                  {selected ? (
                    <svg
                      width="24"
                      height="25"
                      className="w-6 h-6 text-purple-lighter"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.5 6.5 9 18.5 4.5 14"
                        stroke="#DA275D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : null}
                </Listbox.Option>
              )
            })}
          </Listbox.Options>
        </Transition>
      </Listbox>
      <IOSDialog
        isOpen={noCategorySelected}
        update={setnoCategorySelected}
        title="You can’t remove all categories"
      >
        <div className="mt-0.5 px-4 pb-5 text-[13px] text-center">
          You have to have at least one category checked so people can find you.
        </div>
        <button
          onClick={() => setnoCategorySelected(!noCategorySelected)}
          type="button"
          className="border-t-0.5px w-full border-gray-divider py-3 text-[#007AFF] text-lg leading-5"
        >
          OK
        </button>
      </IOSDialog>
    </ModalLayout>
  )
}
export default Category
