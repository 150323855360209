import React from "react";
import ProfilePanel from "./ProfilePanel";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";

const PanelContainer = ({ user, setuser }) => {
  const { getAccessToken } = useAuth();
  const token = getAccessToken();
  const EDIT_USER = "/user/profile";
  const editUser = async (data) => {
    try {
      await axios.put(EDIT_USER, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      console.log("error", e);
    }
  };
  const calculateAge = (dob) => {
    if (!dob) {
      return "undefined"; // Return "undefined" if dob is not provided
    }
    const dobDate = new Date(dob); // Convert the string to a Date object
    const today = new Date(); // Get today's date

    let age = today.getFullYear() - dobDate.getFullYear();
    const monthDifference = today.getMonth() - dobDate.getMonth();

    // If the current month is earlier than the birth month, or it's the birth month but the current day is earlier, subtract one from the age
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < dobDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  };
  // console.log("kokooo",user)
  return (
    <div className="space-y-5 mt-7 pt-0.5">
      <ProfilePanel
        name="Name"
        content={user.firstname}
        setuser={setuser}
        callApi={editUser}
      />
      <ProfilePanel
        name="About you"
        content={user.about}
        setuser={setuser}
        callApi={editUser}
      />
      <ProfilePanel
        name="Age"
        content={calculateAge(user?.dob)}
        setuser={setuser}
        callApi={editUser}
      />
      <ProfilePanel
        name="Category"
        content={user.categories}
        setuser={setuser}
      />
      <ProfilePanel
        name="Legal"
        content="Imprint, Privacy, Licences, Data & Account"
      />
    </div>
  );
};

export default PanelContainer;
