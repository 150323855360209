import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useMessageContext from "../hooks/useMessageContext";
import ChatPanel from "../components/messages/ChatPanel";
import axios from "../api/axios";
import logo from "../assets/SVG/logo-sm.svg";
import Loader from "../shared/loader/Loader";
import backIcon from "../assets/SVG/backIcon.svg";
import Refresh from "../assets/SVG/refresh.svg";

const EmptyChat = () => (
  <div className="flex flex-col items-center">
    <img src={logo} alt={logo} />
    <h2 className="mt-7 pt-0.5 text-2xl font-bold text-black-light text-center">
      Go to the Discover
      <br /> screen and chat with
      <br /> people!
    </h2>
  </div>
);
const UnreadList = ({ refresh }) => {
  const [unreadMessages, setUnreadMessages] = useState([]);
  const CHAT_UNREAD_URL = "/userUnredChatList";
  const [isEmpty, setisEmpty] = useState(false);
  const scrollElement = useRef(null);
  const { getAccessToken } = useAuth();
  const token = getAccessToken();
  const { initialBroadcastState, readState } = useMessageContext();

  const [broadcastUserList, setbroadcastUserList] = initialBroadcastState;
  console.log(broadcastUserList[0]?.name);
  const [filteredUserList, setFilteredUserList] = useState([]);
  // const [updatedUserList, setupdatedUserlist] = updatedState;
  const [numberPageChat, setNumberPageChat] = useState(1);
  const [readId, setreadId] = readState;
  const [loading, setLoading] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const CHAT_READ_URL = "/chat/read";

  const updateUnreadChat = async (userId) => {
    const res = await axios.post(
      CHAT_READ_URL,
      { userId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (res) {
      console.log("done");
    }
  };

  const fetchDataUsersApi = async () => {
    setLoading(true);
    const res = await axios.post(
      CHAT_UNREAD_URL,
      { page: numberPageChat },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    // console.log(res)
    if (res && res.data && res.data.data) {
      if (res.data.data.length === 0) {
        setisEmpty(true);
        setLoading(false);
        setLoadingPagination(false);
      }
      setUnreadMessages(res.data.data); // Set unread messages
    }
    setLoading(false);

  };

  useEffect(() => {
    fetchDataUsersApi();
  }, [numberPageChat, refresh]);

  function mergeUniqueArrays(array1, array2) {
    // Merge the arrays
    const mergedArray = [...array1, ...array2];

    // Create a Map with a custom key to identify unique objects
    const uniqueMap = new Map();

    // Filter out duplicate objects
    const uniqueArray = mergedArray.filter((obj) => {
      const key = obj.userId; // Use a unique identifier for each object, like 'id'
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, obj);
        return true;
      }
      return false;
    });

    return uniqueArray;
  }
  useEffect(() => {
    if (filteredUserList.length > 0) {
      if (unreadMessages.length > 0) {
        const uniqueObjects = mergeUniqueArrays(
          unreadMessages,
          filteredUserList,
        );
        setbroadcastUserList(uniqueObjects);
        setFilteredUserList(uniqueObjects);
        setLoadingPagination(false);
      }
      setLoadingPagination(false);
    } else {
      setLoading(true);

      if (unreadMessages.length > 0) {
        const uniqueObjects = mergeUniqueArrays(
          unreadMessages,
          filteredUserList,
        );
        setFilteredUserList(uniqueObjects);
        setLoadingPagination(false);
        setLoading(false);
      }
      setLoadingPagination(false);
      setLoading(false);
    }
  }, [unreadMessages]);
  //  px-5 h-auto pt-5 absolute top-73px bottom-73px inset-0 overflow-y-auto
  // wael masri

  const handleScroll = (scroller = scrollElement.current) => {
    if (
      scroller.target.scrollTop + scroller.target.clientHeight >=
      scroller.target.scrollHeight
    ) {
      setNumberPageChat((prevStat) => prevStat + 1);
      setLoadingPagination(true);
    }
  };
  useEffect(() => {
    const scrollElementto = scrollElement.current;
    scrollElementto.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the event listener when the component unmounts
      scrollElementto.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // wael masri

  //   useEffect(() => {
  //     const index = filteredUserList.findIndex(
  //       (item) => item.userId === updatedUserList.userId,
  //     );
  //     if (index >= 0) {
  //       const tempArr = [...filteredUserList];
  //       tempArr.splice(index, 1);
  //       setFilteredUserList([updatedUserList, ...tempArr]);
  //     } else if (!Object.keys(updatedUserList) === 0) {
  //       setFilteredUserList((prev) => [updatedUserList, ...prev]);
  //     }
  //   }, [updatedUserList]);

  useEffect(() => {
    if (readId !== "") {
      const index = filteredUserList.findIndex(
        (user) => user.userId === readId,
      );
      if (index >= 0) {
        const tempArr = [...filteredUserList];
        tempArr[index].lastMessage.isRead = true;

        setFilteredUserList(tempArr);

        setreadId("");
      }
    }
  }, [readId]);
  // console.log("userlistnew", filteredUserList);
  const hasData = !loading;
  // const hasNoData = !loading && isEmpty
  return (
    <div>
      <div
        style={{
          height: "73px",
          width: "100%",
          padding: "0px 10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link to="/messages" className="flex">
          <div>
            {" "}
            <img src={backIcon} alt={backIcon} />
          </div>
        </Link>
        <div style={{ fontSize: "22px", fontWeight: "bold" }}>
          Unread Messages
        </div>
        <button
          type="button"
          onClick={() => {
            setUnreadMessages([]);
            setFilteredUserList([]);
            fetchDataUsersApi();
          }}
        >
          <img src={Refresh} alt="refresh icon" style={{ width: "30px" }} />
        </button>
      </div>

      <section
        className="h-screen overflow-y-scroll px-5 pt-5 absolute top-73px bottom-73px inset-0"
        ref={scrollElement}
      >
        <div
          className={`${
            filteredUserList.length > 0
              ? "space-y-5"
              : "flex justify-center items-center h-full"
          }`}
          style={{ paddingBottom: "10.25rem" }}
        >
          {hasData ? (
            filteredUserList
              .sort((a, b) => {
                const dateA = new Date(
                  a.lastMessage.updatedAt.toString(),
                ).getTime();
                const dateB = new Date(
                  b.lastMessage.updatedAt.toString(),
                ).getTime();

                if (dateA > dateB) {
                  return -1;
                }
                if (dateA < dateB) {
                  return 1;
                }
                return 0;
              })
              .map(
                (user) =>
                  user.name && (
                    <ChatPanel
                      handleClick={() =>
                        !user.lastMessage.isRead &&
                        updateUnreadChat(user.userId)
                      }
                      key={user.userId}
                      role={user.role}
                      userId={user.userId}
                      name={user.name}
                      isSubscribed={user.isSubscribed}
                      avatar={user.avatar}
                      lastMessage={user.lastMessage}
                    />
                  ),
              )
          ) : (
            <EmptyChat />
          )}
          {loadingPagination && !isEmpty ? (
            <Loader className="fixed top-[45%] left-[47%] transform -translate-x-1/2 -translate-y-1/2" />
          ) : null}

          {loading ? (
            <Loader className="fixed top-[45%] left-[47%] transform -translate-x-1/2 -translate-y-1/2" />
          ) : null}
        </div>
      </section>
    </div>
  );
};

export default UnreadList;
