import React, { useEffect } from 'react'
import Imprint from './modals/legal-modals/Imprint'
import Privacy from './modals/legal-modals/Privacy'
import Licences from './modals/legal-modals/Licenses'
import DelProfile from './modals/legal-modals/DelProfile'
import ReqData from './modals/legal-modals/ReqData'

const CurrentLegalModal = ({ modal, isOpen, setisOpen }) => {
  let Component
  useEffect(() => {
    console.log('Current modal is redered')
    console.log(modal)
    console.log(isOpen)
  }, [])
  switch (modal) {
    case 'Imprint':
      Component = <Imprint isOpen={isOpen} setisOpen={setisOpen} />
      break
    case 'Privacy Policy':
      Component = <Privacy isOpen={isOpen} setisOpen={setisOpen} />
      break
    case 'Licences':
      Component = <Licences isOpen={isOpen} setisOpen={setisOpen} />
      break
    case 'Request Data':
      Component = <ReqData isOpen={isOpen} setisOpen={setisOpen} />
      break
    case 'Delete Profile':
      Component = <DelProfile isOpen={isOpen} setisOpen={setisOpen} />
      break
    default:
      Component = <div />
  }
  return Component
}

export default CurrentLegalModal
