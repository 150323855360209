import React, { useEffect, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import Arrow from '../../assets/SVG/ProfileArrow.svg'

const SettingsPanel = ({ name = '', navigate }) => {
  const Navigate = useNavigate();

  useEffect(() => {
    console.log('Setting profile rendered');
  }, []);

  const handleNavigate = () => {
    Navigate(navigate);
  };

  return (
    <>
      <h5 className="text-xs">{name}</h5>
      <div className="mt-1.5 pb-1.5 border-b-0.5px border-gray-veryLight">
        <button
          id={name}
          onClick={handleNavigate}
          type="button"
          className="flex justify-between w-full text-left"
        >
          <img id={name} src={Arrow} alt={Arrow} />
        </button>
      </div>
    </>
  );
};

export default memo(SettingsPanel)
