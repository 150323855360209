import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";

// Import Swiper styles
import "./swiper.min.css";
import BackBtn from "../../shared/BackBtn";
import Img from "../../shared/Img";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import noImage from "../../assets/PNG/noProfile.png";
import closeImage from "../../assets/PNG/close.png";

const ChatDetails = ({
  onBack,
  avatar,
  otherUserId,
  carouselOpen,
  isImageModal,
  isImageModalUrl,
  handleCloseModalImage,
  handleOpenModalImage,
}) => {
  const scrollElement = useRef(null);
  const [media, setMedia] = useState([]);
  console.log(media);
  const [userDetails, setUserDetails] = useState({});
  const [profilePics, setProfilePics] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [clickedImg, setClickedImg] = useState("");
  const [UserEmail, setUserEmail] = useState("");
  const [UserCountry, setUserCountry] = useState("");
  const [userDob, setUserDob] = useState(0);
  const [showGallery, setShowGallery] = useState(carouselOpen || false);
  const { getAccessToken } = useAuth();
  const token = getAccessToken();
  async function getUserDetails() {
    const { data } = await axios.post(
      "user/other-user-profile",
      { _id: otherUserId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    setUserDetails(data?.user);
    setUserEmail(data?.user?.email);
    setUserCountry(data?.user?.country);
    setUserDob(data?.user?.dob);
    console.log("User email:", data?.user);

    if (data?.user?.images?.length) {
      setProfilePics(data?.user?.images);
    }
    console.log("User email:", UserEmail);
    console.log("User email2222222:", userDob);
  }
  async function getChatMedia() {
    const data = {
      userID: otherUserId,
      page: currentPage,
    };
    const res = await axios.post("chatMedia", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const mediaMsgs = res?.data?.messages?.map((message) => {
      if (message.type === "video") {
        return message.video.thumbnail;
      }
      if (message.type === "image") {
        return message.downloadURL;
      }
      return message?.downloadURL || message?.video?.thumbnail;
    });
    if (mediaMsgs && mediaMsgs?.length) {
      setMedia((prv) => [...prv, ...mediaMsgs]);
    }
  }

  const handleScroll = (scroller = scrollElement.current) => {
    // Check if the scroll position reached the bottom
    if (
      scroller.target.scrollTop + scroller.target.clientHeight >=
      scroller.target.scrollHeight
    )
      setCurrentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    if (currentPage > 1) {
      getChatMedia();
    }
  }, [currentPage]);

  useEffect(() => {
    getUserDetails();
    getChatMedia();
    scrollElement.current.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className="fixed top-0 flex flex-col bg-white inset-x-0 font-sans z-30 overflow-y-auto h-screen"
      ref={scrollElement}
    >
      <header className="select-none flex items-center px-6 py-10 bg-white">
        <BackBtn handleClick={onBack} />
      </header>
      {avatar ? (
        <Img
          src={avatar}
          onClick={() => handleOpenModalImage(avatar)}
          className="w-[17em] block aspect-square rounded-full mx-auto mt-10 relative"
        />
      ) : (
        <Img
          src={noImage}
          onClick={() => handleOpenModalImage(noImage)}
          className="w-[17em] block aspect-square rounded-full mx-auto mt-10 relative"
        />
      )}

      <div className="flex flex-row justify-start items-center font-semibold text-xxl p-2 w-full mt-20 mb-1 gap-20 bg-[#f0f0f0]">
        <div>
          <h2>About User:</h2>
          {userDetails && userDetails.about ? (
            <p className="text-primary-pinkishRed">{userDetails.about}</p>
          ) : (
            <p className="text-primary-pinkishRed">No About Section</p>
          )}
        </div>

        {UserEmail && (
          <div>
            <h2>User Email:</h2>
            <p className="text-primary-pinkishRed">{UserEmail}</p>
          </div>
        )}

        <div>
          <h2>User Country:</h2>
          {UserCountry ? (
            <p className="text-primary-pinkishRed">{UserCountry}</p>
          ) : (
            <p className="text-primary-pinkishRed">Country not available</p>
          )}
        </div>
        <div>
          <h2>Date of Birth:</h2>
          {userDob ? (
            <p className="text-primary-pinkishRed">{userDob}</p>
          ) : (
            <p className="text-primary-pinkishRed">
              Date of Birth not available
            </p>
          )}
        </div>
      </div>

      <div className="bg-[#f0f0f0] flex flex-col mt-20 w-full px-1 py-2">
        <h2 className="font-bold text-xxl pl-2 w-max mb-2">Shared Media</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 gap-4">
          {media.map((item) => (
            <Img
              key={Math.random() * 1234 * Math.random()}
              alt=""
              src={item}
              onClick={() => handleOpenModalImage(item)}
              className="w-full bg-gray aspect-square object-cover"
            />
          ))}
        </div>
      </div>
      {clickedImg ? (
        <div className="fixed inset-0 flex justify-center items-center z-30">
          <img
            src={clickedImg}
            alt=""
            className="max-w-screen max-h-screen w-auto h-auto"
            onClick={() => setClickedImg("")}
          />
        </div>
      ) : null}

      {showGallery ? (
        <Swiper
          scrollbar={{
            hide: false,
          }}
          className="w-[50%] fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 bg-gray-800 p-2 rounded-lg sm:w-[90%]"
          modules={[Scrollbar]}
        >
          {profilePics.map((item) => (
            <SwiperSlide
              key={`${Math.random() * 12312321 * Math.random()}`}
              className="aspect relative"
            >
              <Img src={item} alt="" className="block w-full object-contain" />
              <button
                className="absolute text-lg top-2 border-none right-2 z-100 block text-[#e70635] cursor-pointer"
                type="button"
                onClick={() => setShowGallery(false)}
              >
                x
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : null}
      {isImageModal ? (
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.9)",
            position: "fixed",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div style={{ width: "50%", height: "90%" }}>
            <img
              src={isImageModalUrl}
              alt="modalImage"
              style={{
                objectFit: "cover",
                height: "100%",
                width: "100%",
              }}
            />
          </div>

          <div
            style={{
              position: "absolute",
              right: "15px",
              top: "15px",
              color: "white",
            }}
            onClick={handleCloseModalImage}
            role="button"
            tabIndex={0}
          >
            <img
              src={closeImage}
              alt="close"
              style={{ width: "30px", height: "30px" }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ChatDetails;
