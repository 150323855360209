import React from 'react'
import { Switch } from '@headlessui/react'

const LockSwitch = ({ locked, setlocked }) => (
  <div className="flex items-center">
    <Switch
      checked={locked}
      onChange={setlocked}
      className={`${locked ? 'bg-PurpleGradient' : 'bg-gray-veryLight'}
  relative h-6 w-12 z-30 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    />
    <span
      aria-hidden="true"
      className={`${locked ? 'translate-x-[18px]' : 'translate-x-0'}
    flex pointer-events-none items-center absolute justify-center z-30 h-[30px] w-[30px] transform rounded-full bg-white shadow-lightGrayLock ring-0 transition duration-200 ease-in-out`}
    >
      <span className="text-transparent bg-clip-text bg-PurpleGradient text-[9px] leading-3 font-bold">
        {!locked ? 'Open' : 'Lock'}
      </span>
    </span>
  </div>
)

export default LockSwitch
