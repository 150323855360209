import React, { useEffect, useState } from 'react'
import AutoTextbox from '../../../../shared/AutoTextbox'
import ModalLayout from '../../../../templates/ModalLayout'

const About = ({ isOpen, setuser, setisOpen, content, callApi }) => {
  const [value, setvalue] = useState('')
  useEffect(() => {
    console.log('About Intiatiated')
    setvalue(content !== undefined ? content : '')
  }, [isOpen])

  const update = () => {
    if (content !== value) {
      callApi({ about: value })
      setuser((prev) => ({ ...prev, about: value, isUpdated: true }))
    }
  }
  return (
    <ModalLayout
      title="About you"
      hasTick
      isOpen={isOpen}
      setisOpen={setisOpen}
      handleClick={update}
    >
      <div className="mt-5 mx-5">
        <p className="text-xs">Add a short desciption about you</p>
        <AutoTextbox
          styles="mt-1.5 pb-1.5 max-h-[60px] min-h-[20px] font-semibold text-sm border-b-0.5px w-full textbox-highlight"
          value={content}
          id="aboutTextBox"
          setvalue={setvalue}
        />
      </div>
    </ModalLayout>
  )
}

export default About
