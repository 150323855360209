import { configure } from 'mobx'
import React from 'react'
// import loginStore from './LoginStore';
// import tokenStore from './TokenStore';
// import modeStore from './ModeStore';
// import profileStore from './ProfileStore';
// import registrationStore from './RegistrationStore';
// import chatStore from './ChatStore';
// import purchaseStore from './PurchaseStore';
import preferencesStore from './PreferencesStore'
import profileStore from './ProfileStore'
import chatStore from './ChatsStore'

configure({ enforceActions: 'never' })
export const Globalstore = {
  //   loginStore,
  //   tokenStore,
  //   modeStore,
  //   registrationStore,
  //   profileStore,
  //   purchaseStore,
  chatStore,
  preferencesStore,
  profileStore,
}

export const StoreContext = React.createContext(Globalstore)

export const Store = () => React.useContext(StoreContext)
