import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../shared/Button";
import Email from "../shared/Email";
import Password from "../shared/Password";
import EntryLayout from "../templates/EntryLayout";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import AlertBox from "../components/alert/AlertBox";
import Loader from "../shared/loader/Loader";
import ReqDelProfile from "../components/profile/modals/legal-modals/ReqDelProfile";

const Redirect = ({ t }) => (
  <div className="mt-12">
    <p className="pt-0.5 text-xs text-center text-black-veryDark">
      {t("Need help?")}{" "}
      <Link className="font-bold underline" to="/help">
        {t("Contact Support")}
      </Link>
    </p>
  </div>
);

const DeleteAccount = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { logout } = useAuth();
  const { t } = useTranslation();
  const DELETE_ACCOUNT_REQUEST = "/user/requestDelete";

  const handleDeleteAccount = async () => {
    setLoading(true);
    setError(""); // Clear previous errors
    try {
      const response = await axios.post(DELETE_ACCOUNT_REQUEST, {
        email,
        password,
      });
      if (
        response?.data?.message ===
        "Your account has been deleted successfully."
      ) {
        alert(response.data.message);
        logout(); // Log out the user
        window.location.href =
          "https://docs.google.com/document/d/1jVM5hXceps5brduwhQ11LmFJ4bTDCYYqTzUxvwbGgS8/edit?usp=sharing"; // Redirect to the specified URL
      } else if (response?.data?.errors) {
        setError(response.data.errors);
      }
    } catch (err) {
      setError(
        err.response?.data?.errors ||
          "An error occurred during the deletion process.",
      );
    } finally {
      setLoading(false);
      setIsOpen(false); // Close the modal after the attempt
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsOpen(true); // Open the confirmation dialog before sending request
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          width: "800px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
          padding: "50px",
          borderRadius: "10px",
        }}
      >
        <EntryLayout>
          <AlertBox isOpen={!!error} setIsOpen={setIsOpen}>
            {error}
          </AlertBox>
          <form onSubmit={handleSubmit} className="mt-14" autoComplete="off">
            <Email email={email} setemail={setEmail}>
              {t("Email")}
            </Email>
            <Password password={password} setpassword={setPassword}>
              {t("Password")}
            </Password>
            <Button>
              {loading ? (
                <Loader className="white" />
              ) : (
                t("Request Delete Account")
              )}
            </Button>
            <Redirect t={t} />
          </form>
        </EntryLayout>
      </div>
      <ReqDelProfile
        isOpen={isOpen}
        setisOpen={setIsOpen}
        onDeleteConfirm={handleDeleteAccount}
      />
    </div>
  );
};

export default DeleteAccount;
