import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import Button from "../shared/Button";
import Email from "../shared/Email";
import Password from "../shared/Password";
import EntryLayout from "../templates/EntryLayout";
import Input from "../shared/Input";
import useAuth from "../hooks/useAuth";
import axios from "../api/axios";

const Redirect = ({ t }) => (
  <div className="mt-12">
    <p className="pt-0.5 text-xs text-center text-black-veryDark">
      {t("Have an account?")}{" "}
      <Link className="font-bold underline" to="/login">
        {t("Log In")}
      </Link>
    </p>
  </div>
);

const Signup = ({ onBoardingData, onSignup }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [globalError, setGlobalerror] = useState("");
  const [errors, setErrors] = useState({
    firstName: "",
    email: "",
    password: "",
    passwordConfirm: "",
  });
  const { t } = useTranslation();
  const { persistUser, isAuthed } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const SIGNUP_URL = "/user/add?role=affiliate";
  const navigate = useNavigate();
  const reset = () => {
    setEmail("");
    setPassword("");
    setPasswordConfirm("");
    setFirstName("");
  };

  const signUp = async () => {
    try {
      const response = await axios.post(SIGNUP_URL, {
        email,
        password,
        firstName,
        passwordConfirm,
        ...onBoardingData,
      });
      console.log(response);
      if (response?.data?.token) {
        const res = persistUser(response, reset);
        if (!res) {
          const { isOnboardingcomplete } = response.data;
          if (!isOnboardingcomplete) navigate("/onboarding", { state: true });
          else navigate("/messages");
        } else navigate("/login");
      } else {
        setIsOpen(!isOpen);
        reset();
      }
    } catch (err) {
      console.log(err);
      console.log(JSON.stringify(err));

      setIsOpen(true);
      setGlobalerror(
        err.response?.data?.message ||
          err?.response?.data?.errors[0].msg ||
          err.response?.data?.message,
      );
    }
  };
  const validationSchema = Joi.object({
    firstName: Joi.string().min(3).required().empty().messages({
      "string.min": "First name is too short!",
      "string.required": "Please, provide your first name!",
      "string.empty": "Please, provide your first name!",
    }),
    email: Joi.string()
      .email({
        tlds: {
          allow: [
            "com",
            "gov",
            "in",
            "jo",
            "org",
            "io",
            "mail",
            "lb",
            "co",
            "de",
            "uk",
            "tk",
          ],
        },
      })
      .required()
      .messages({
        "string.email": "Please provide a valid email address!",
        "string.empty": "Please, provide your email!",
      }),
    password: Joi.string().min(4).max(10).required().messages({
      "string.min": "Password is too short!",
      "string.max": "Password should not exceed 10 chars",
      "string.required": "Please, provide your password!",
    }),
    passwordConfirm: Joi.string().required().valid(password).empty().messages({
      "string.required": "Please, confirm your password!",
      "any.only": "Passwords doesn't match!",
      "string.empty": "Passwords doesn't match!",
    }),
  });

  const validate = async () => {
    try {
      const { error } = await validationSchema.validate({
        firstName,
        email,
        password,
        passwordConfirm,
      });
      if (error) {
        error.details.map((dtl) =>
          setErrors((prv) => ({ ...prv, [dtl.path[0]]: dtl.message })),
        );
      }
      if (error?.details?.length) {
        console.log("not signed");
        return null;
      }
      signUp();
    } catch (err) {
      err?.details.map((dtl) =>
        setErrors((prv) => ({ ...prv, [dtl.path[0]]: dtl.message })),
      );
    }
    return null;
  };

  const validateField = async (fieldName, value) => {
    try {
      const { error } = await validationSchema
        .extract(fieldName)
        .validate(value);
      if (error) return error?.details[0]?.message;
      return "";
    } catch (error) {
      return error.details[0].message;
    }
  };
  const handleFirstNameChange = (value) => {
    setFirstName(value);
    validateField("firstName", value).then((error) => {
      setErrors((prevState) => ({ ...prevState, firstName: error }));
    });
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    validateField("email", value).then((error) => {
      setErrors((prevState) => ({ ...prevState, email: error }));
    });
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    validateField("password", value).then((error) => {
      setErrors((prevState) => ({ ...prevState, password: error }));
    });
  };

  const handlePasswordConfirmChange = (value) => {
    setPasswordConfirm(value);
    validateField("passwordConfirm", value).then((error) => {
      setErrors((prevState) => ({ ...prevState, passwordConfirm: error }));
    });
  };
  useEffect(() => {
    if (isAuthed) onSignup();
  }, [isAuthed]);

  const handleClick = async (e) => {
    e.preventDefault();
    // Trigger individual field validation to display error messages
    const firstNameError = await validateField("firstName", firstName);
    const emailError = await validateField("email", email);
    const passwordError = await validateField("password", password);
    const passwordConfirmError = await validateField(
      "passwordConfirm",
      passwordConfirm,
    );

    setErrors({
      firstName: firstNameError,
      email: emailError,
      password: passwordError,
      passwordConfirm: passwordConfirmError,
    });
    if (
      errors.firstName ||
      errors.email ||
      errors.password ||
      errors.passwordConfirm
    ) {
      return null;
    }
    validate();
    return null;
  };
  return (
    <EntryLayout>
      <form onSubmit={handleClick} className="mt-14" autoComplete="off">
        <Input
          value={firstName}
          placeholder="First name"
          setValue={handleFirstNameChange}
        >
          {t("First Name")}
        </Input>
        {errors.firstName ? (
          <p className="text-red-500">{errors.firstName}</p>
        ) : null}
        <Email email={email} setemail={handleEmailChange}>
          {t("Email")}
        </Email>
        {errors.email ? <p className="text-red-500">{errors.email}</p> : null}
        <Password password={password} setpassword={handlePasswordChange}>
          {t("Password")}
        </Password>
        {errors.password ? (
          <p className="text-red-500">{errors.password}</p>
        ) : null}
        <Password
          password={passwordConfirm}
          setpassword={handlePasswordConfirmChange}
        >
          {t("Confirm Password")}
        </Password>
        {errors.passwordConfirm ? (
          <p className="text-red-500">{errors.passwordConfirm}</p>
        ) : null}
        {globalError ? (
          <div
            style={{
              width: "100%",
              backgroundColor: "#f72b2bb5",
              padding: "10px",
              borderRadius: 12,
              color: "white",
              marginTop: 15,
            }}
          >
            {globalError}
          </div>
        ) : null}

        <Button>{t("Sign Up")}</Button>
        <Redirect t={t} />
      </form>
    </EntryLayout>
  );
};

export default Signup;
