import React, { useRef, useState } from 'react'
import playBtn from '../../assets/SVG/playBtn.svg'
import stopBtn from '../../assets/SVG/stopBtn.svg'
import sendBtn from '../../assets/SVG/send.svg'
import deleteBtn from '../../assets/SVG/delete.svg'
import voiceGraph from '../../assets/SVG/voiceGraph.svg'
/* eslint-disable jsx-a11y/media-has-caption */

const AudioPlayer = ({
  audioFile,
  setaudioFile,
  audioUrl,
  clearBlobUrl,
  sendAudio,
}) => {
  const audioRef = useRef(null)
  const [isPlaying, setisPlaying] = useState(false)

  const play = () => {
    console.log(audioRef)
    audioRef.current.play()
    setisPlaying(!isPlaying)
  }
  const stop = () => {
    console.log(audioRef)
    audioRef.current.pause()
    setisPlaying(!isPlaying)
  }
  const uploadAudio = () => {
    if (audioFile !== null) {
      sendAudio(audioFile, audioUrl)
      setaudioFile(null)
    }
  }
  const deleteFile = () => {
    clearBlobUrl()
    setaudioFile(null)
  }
  return (
    <div className="flex items-center space-x-2.5 max-h-8">
      {isPlaying ? (
        <button type="button" onClick={stop}>
          <img src={stopBtn} alt="stop" />
        </button>
      ) : (
        <button type="button" onClick={play}>
          <img src={playBtn} alt="play" />
        </button>
      )}

      <div className="w-full px-2 flex rounded-60px h-8 justify-between items-center py-2.5 bg-PurpleGradient">
        <img className="ml-2" src={voiceGraph} alt="voice" />
        <button type="button" onClick={deleteFile}>
          <img src={deleteBtn} alt="delete" />
        </button>
      </div>
      <button type="button" onClick={uploadAudio}>
        <img src={sendBtn} alt="send" />
      </button>
      <audio
        onEnded={() => setisPlaying(!isPlaying)}
        ref={audioRef}
        className="hidden"
        controls
        src={audioUrl}
      />
    </div>
  )
}

export default AudioPlayer
