import { Dialog, Transition } from "@headlessui/react";
import React, { useRef } from "react";
import Coin from "../../assets/SVG/Coin.svg";

const CoinModalCloud = ({ isOpenCoin, setIsOpenCoin, setCoin, setLock }) => {
  const inputBox = useRef(null);
  const closeModal = () => {
    setCoin(0);
    setLock(false);
    setIsOpenCoin(!isOpenCoin);
  };
  const update = () => {
    const inputValue = parseInt(inputBox.current.value, 10);
    if (!inputValue || inputValue < 1 || inputValue > 99999) {
      // alert("Enter a value between 1 and 99999.");
      inputBox.current.focus();
      return;
    }
    setCoin(inputValue);
    setLock(true);
    setIsOpenCoin(!isOpenCoin);
  };

  const handleInputChange = () => {
    // Ensure the input value does not exceed 999
    const inputValue = parseInt(inputBox.current.value, 10);
    if (inputValue > 99999) {
      inputBox.current.value = "99999";
    }
  };

  return (
    <Transition
      show={isOpenCoin}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        className="fixed z-50 px-5 flex justify-center items-center inset-0 overflow-y-auto overflow-x-hidden"
        initialFocus={inputBox}
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 z-10 bg-greyGradient" />
        <div className="text-center z-50">
          <div className="w-335px max-w-md transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all">
            <h3 className="text-xl mt-10 text-center font-bold leading-6 text-gray-900">
              Locked Cloud Media
            </h3>
            <div className="mt-2.5 flex justify-center gap-2">
              <img src={Coin} alt="coin" />
              <input
                ref={inputBox}
                placeholder="1"
                min={1}
                max={99999}
                type="number"
                id="number"
                className="w-20 px-2 h-12 font-bold text-2xl text-black bg-gray-vvvl rounded-xl caret-black"
                onChange={handleInputChange} // Enforce maximum value
              />
            </div>
            <div className="flex justify-center mt-10 mb-10">
              <button
                type="button"
                className="inline-flex justify-center border border-transparent bg-PurpleGradient rounded-xl px-4 py-2 text-sm font-bold text-white w-295px focus:outline-none focus-visible:ring-2"
                onClick={update}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CoinModalCloud;
