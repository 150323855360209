import React, { useState } from 'react'
import IOSDialog from '../../../../shared/IOSDialog'
import axios from '../../../../api/axios'
import useAuth from '../../../../hooks/useAuth'

const ReqData = ({ isOpen, setisOpen }) => {
  const [email, setemail] = useState('')
  const { getAccessToken } = useAuth()
  const token = getAccessToken()
  const REQUEST_URL = '/user/requestData'

  const update = () => {
    setisOpen(!isOpen)
  }
  const requestData = async () => {
    try {
      const response = await axios.post(
        REQUEST_URL,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (response?.data?.Message) {
        console.log(response?.data?.Message)
        update()
      }
    } catch (e) {
      console.log('error', e)
    }
  }
  return (
    <IOSDialog update={update} isOpen={isOpen} title="Request Data">
      <div className="px-4">
        <p className="mt-0.5 text-sm text-center">
          All your data will be send to the email you specify beneath.
        </p>
        <input
          onChange={(e) => setemail(e.target.value)}
          type="email"
          placeholder="Email"
          value={email}
          className="mt-4 px-1 py-0.5 focus:outline-none text-sm w-full rounded border-0.5px border-gray-divider caret-[#007AFF]"
        />
      </div>
      <div className="mt-5 border-t-0.5px border-gray-divider px-4 grid grid-cols-2 w-full">
        <button
          type="button"
          className="border-r-0.5px border-gray-divider py-3 text-[#007AFF] text-lg"
          onClick={update}
        >
          Cancel
        </button>
        <button
          type="button"
          className="text-[#FF6464] text-lg font-semibold"
          onClick={requestData}
        >
          ReqData
        </button>
      </div>
    </IOSDialog>
  )
}

export default ReqData
