import { makeAutoObservable } from "mobx";
import axios from "../api/axios";
// import { callRemoteMethod } from '_utility/WebserviceHandler';

// import { URL } from '_utility/Constants';

class PreferenceStore {
  preferences = [];
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
  async setPreferenceData(data) {
    this.preferences = data;
  }

  async getPreferences() {
    try {
      const PREFERENCES_URL = "/preference";
      const response = await axios.get(PREFERENCES_URL, {});
      if (response.data.result === 1) {
        this.setPreferenceData(response.data.preferences);
      }
      return response;
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  // async deleteImage(data) {
  //   const response = await callRemoteMethod(URL.DELETE_PICTURE, 'DELETE', data)
  //   return response
  // }

  // async uploadPicture(data) {
  //   const response = await callRemoteMethod(
  //     URL.UPLOAD_PICTURE,
  //     'POST',
  //     data,
  //     true
  //   )
  //   return response
  // }
}

const preferenceStore = new PreferenceStore();
export default preferenceStore;
