import React from 'react'

const Typing = () => (
  <span className="space-x-1 inline-block">
    <span className="bg-[#929292] animate-pulse rounded-full inline-block w-2 h-2" />
    <span className="bg-[#5A5A5A] animate-pulse rounded-full inline-block w-2 h-2" />
    <span className="bg-[#000000] animate-pulse rounded-full inline-block w-2 h-2" />
  </span>
)

export default Typing
