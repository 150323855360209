import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import Button from '../shared/Button'
import Email from '../shared/Email'
import ErrText from '../shared/ErrText'
import EntryLayout from '../templates/EntryLayout'
import axios from '../api/axios'

const Forgot = () => {
  const [email, setemail] = useState('')
  const [error, seterror] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation()

  const handleClick = async (e) => {
    e.preventDefault()
    try {
      setLoading(true); // Start loading
      const response = await axios.put('/password/forgot', { email });

      if (response.data.message) {
        setSuccess(true);
        seterror(false);
        setTimeout(() => {
          navigate('/login');
        }, 6000);
      } else {
        setSuccess(false);
        seterror(true);
      }
    } catch (err) {
      setSuccess(false);
      seterror(true);
    } finally {
      setLoading(false);
    }
  }

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  };

  const cardStyle = {
    width: '800px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    padding: '50px',
    borderRadius: '10px',
  };

  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        <EntryLayout>
          <form onSubmit={handleClick} className="mt-14" autoComplete="off">
            <Email email={email} setemail={setemail}>
              {t('Email')}
            </Email>
            {error ? <ErrText>The email you entered doesn’t exist</ErrText> : ''}
            {success ? (
              <div className="text-green-500">
                Password reset email sent successfully! check you email inbox, Redirecting to login...
              </div>
            ) : (
              <Button type="submit" disabled={loading}>
                {loading ? 'Loading...' : t('Continue')}
              </Button>
            )}
          </form>
        </EntryLayout>
      </div>
    </div>
  )
}

export default Forgot
