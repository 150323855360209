import React, { useState, useRef, useCallback, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Webcam from "react-webcam";
import closeBtn from "../../assets/SVG/close.svg";
import switchBtn from "../../assets/SVG/switchCamera.svg";
import cameraBtn from "../../assets/SVG/camera.svg";
import LockSwitch from "../../shared/LockSwitch";
import CoinModal from "./CoinModal";

const CaptureMedia = ({
  openCamera,
  setopenCamera,
  setfile,
  sendMedia,
  setcoins,
  openGalleryDialog,
}) => {
  const [locked, setlocked] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [frontCamera, setfrontCamera] = useState(true);
  const webCamRef = useRef();
  const sendBtn = useRef(null);
  const [isCaptured, setisCaptured] = useState(false);
  const [imageSrc, setimageSrc] = useState(null);
  const videoConstraints = {
    facingMode: frontCamera ? "user" : { exact: "environment" },
  };
  const capture = useCallback(() => {
    setimageSrc(webCamRef.current.getScreenshot());
    setisCaptured(true);
  }, [webCamRef]);
  useEffect(() => {
    if (locked) setisOpen(true);
  }, [locked]);

  useEffect(() => {
    if (imageSrc !== null) {
      fetch(imageSrc)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "captured.jpeg", {
            type: "image/jpeg",
          });
          console.log("file", file);
          setfile(file);
        });
    }
  }, [imageSrc]);

  const closeModal = () => {
    console.log("close");
    setopenCamera((prev) => ({ ...prev, photo: false }));
    openGalleryDialog();
  };
  const uploadToServer = async () => {
    console.log("file ready to be uploaded");
    if (imageSrc !== null) {
      sendMedia(locked);
      closeModal();
    }
  };
  const switchCamera = () => {
    setfrontCamera(!frontCamera);
  };
  const retake = () => {
    setisCaptured(false);
  };
  return (
    <Transition
      show={openCamera}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={closeModal}
        initialFocus={sendBtn}
      >
        <Dialog.Overlay className="fixed inset-0 bg-gray-semiDark -z-10 pointer-events-none" />
        <div className="z-40 h-screen">
          <button
            type="button"
            onClick={closeModal}
            className="absolute top-10 right-5 z-50"
          >
            <img src={closeBtn} alt={closeBtn} />
          </button>
          {isCaptured && (
            <img
              className="w-full z-30 h-full object-cover object-center"
              alt="captured"
              src={imageSrc}
            />
          )}
          <div />
          {/* <div className="w-full z-50 h-full object-cover object-center bg-gray-text" /> */}
          <Webcam
            videoConstraints={videoConstraints}
            ref={webCamRef}
            className={`w-full z-30 h-full object-cover object-center ${
              isCaptured && "hidden"
            }`}
            audio={false}
            screenshotFormat="image/jpeg"
          />
          {isCaptured ? (
            <div className="flex pl-5 pr-6 justify-between absolute w-full bottom-6">
              <LockSwitch locked={locked} setlocked={setlocked} />
              <button type="button" onClick={retake}>
                <h3 className="font-semibold text-lg leading-6 tracking-[-0.3px] text-white">
                  Retake
                </h3>
              </button>
              <button
                ref={sendBtn}
                type="button"
                onClick={uploadToServer}
                className="mr-1.5"
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.653 6.113 26.11 15.42a1.153 1.153 0 0 1 0 2.082L6.653 26.807a1.155 1.155 0 0 1-1.612-1.345l1.946-7.136a.461.461 0 0 1 .384-.336l9.427-1.28a.23.23 0 0 0 .18-.138l.017-.059a.23.23 0 0 0-.145-.247l-.05-.014-9.42-1.28a.462.462 0 0 1-.382-.336L5.041 7.46a1.154 1.154 0 0 1 1.612-1.346Z"
                    fill="#fff"
                  />
                </svg>
              </button>
            </div>
          ) : (
            <div className="flex z-50 justify-between items-center absolute w-full bottom-6 px-10">
              <div className="w-8 h-8" />
              <button type="button" onClick={capture}>
                <img src={cameraBtn} alt="camera" />
              </button>
              <button type="button" onClick={switchCamera}>
                <img src={switchBtn} alt="switch" />
              </button>
            </div>
          )}
        </div>
        {locked && (
          <CoinModal
            isOpen={isOpen}
            setisOpen={setisOpen}
            setcoins={setcoins}
            setisLocked={setlocked}
          />
        )}
      </Dialog>
    </Transition>
  );
};

export default CaptureMedia;
