import React from 'react'
import EmojiShadow from '../../assets/SVG/emojiShadow.svg'
import cardTitlebg from '../../assets/SVG/cardTitlebg.svg'

const PreferenceCard = ({
  emoji,
  title,
  imgSrc,
  handleClick,
  selected,
  id,
}) => (
  <div
    aria-hidden
    onClick={() => handleClick(id)}
    className={`w-[165px] h-[165px] flex justify-center items-center z-20 relative rounded-10px  ${
      selected ? 'bg-purpleBorder' : ''
    }`}
  >
    <div className="w-[157px] h-[157px] overflow-hidden rounded-10px bg-PurpleGradient absolute">
      <div className="mt-11 pt-0.5 text-[35px] leading-snug text-center">
        {imgSrc === undefined ? (
          <span>{emoji}</span>
        ) : (
          <img
            className="mx-auto"
            src={imgSrc}
            alt={imgSrc}
            style={{ maxWidth: '30%', maxHeight: '30%' }}
          />
        )}
      </div>
      <img src={EmojiShadow} alt={EmojiShadow} className="mx-auto mt-1.5" />
      <div className="absolute bottom-0 flex justify-center">
        <p className="text-center text-sm text-white font-bold z-30 absolute bottom-1">
          {title}
        </p>
        <img src={cardTitlebg} alt={cardTitlebg} />
      </div>
    </div>
  </div>
)

export default PreferenceCard
