import { makeAutoObservable } from 'mobx'


class ChatStore {
  chats = []
  chats2 = []

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }
  async setIsAllChatsSelected(data) {
    this.chats = data
  }

  async getChats() {
    return this.chats;
  }

  async setChats(chats){
    this.chats = chats;
  }
  async setChats2(chats){
    this.chats2 = chats;
  }
}

const chatsStore = new ChatStore()
export default chatsStore
