import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import Button from "../shared/Button";
import Email from "../shared/Email";
import Password from "../shared/Password";
import EntryLayout from "../templates/EntryLayout";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import AlertBox from "../components/alert/AlertBox";
import Loader from "../shared/loader/Loader";

const Redirect = ({ t }) => (
  <div className="mt-12">
    <p className="pt-0.5 text-xs text-center text-black-veryDark">
      {t("Don’t have an account?")}{" "}
      <Link className="font-bold underline" to="/signup">
        {t("Sign Up")}
      </Link>
    </p>
  </div>
);

const Login = () => {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { persistUser, isAuthed } = useAuth();
  const { t } = useTranslation();
  const LOGIN_URL = "/affiliate/login";
  const navigate = useNavigate();
  // const { state } = useLocation()


  const reset = () => {
    setemail("");
    setpassword("");
  };

  const validatePassword = () => {
    if (password.length < 6) {
      alert("Password must be at least 6 characters");
      setError("Password must be at least 6 characters");
      console.log(error);
      return false;
    }
    return true;
  };

  const handleLogin = async (loginEmail, loginPassword) => {
    try {
      setLoading(true);
      const response = await axios.post(LOGIN_URL, {
        email: loginEmail,
        password: loginPassword,
      });
      if (response?.data?.token) {
        if (response.data?.user?.isInActive || response.data?.user?.isBlocked) {
          setIsOpen(!isOpen);
          console.log("1")
          reset();
        } 
        
          const res = persistUser(response, reset);
          console.log("2")
          if (!res) navigate("/messages");
          else navigate("/login");
        
      } else {
        console.log("3")
        setIsOpen(!isOpen);
        if (response.status === 400 && Array.isArray(response.data?.errors)) {
          const validationErrors = response.data?.errors.map((err) => err.msg);
          console.log("4")
          setError(validationErrors.join("\n"));
        } else {
          setError(response.data?.errors || "An error occurred");
          console.log("5")
        }
        reset();
      }
    } catch (err) {
      setError(err.response?.data?.errors || "An error occurred");
      console.log(err);
      console.log("6")
    } finally {
      console.log("7")
      setLoading(false);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if (rememberMe) {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
      localStorage.setItem("rememberMe", rememberMe.toString());
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      localStorage.removeItem("rememberMe");
    }
    if (!validatePassword()) {
      reset();
      return;
    }
    handleLogin(email, password);
  };

  useEffect(() => {
    const savedUsername = localStorage.getItem("username");
    const savedPassword = localStorage.getItem("password");
    const savedRememberMe = localStorage.getItem("rememberMe") === "true";

    if (savedRememberMe && savedUsername && savedPassword) {
      setemail(savedUsername);
      setpassword(savedPassword);
      setRememberMe(savedRememberMe);
      handleLogin(savedUsername, savedPassword, savedRememberMe);
    }
  }, []);

  useEffect(() => {
    if (isAuthed) navigate("/messages");
  }, [isAuthed, navigate]);

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "130vh",
  };

  const cardStyle = {
    width: "800px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    padding: "50px",
    borderRadius: "10px",
  };

  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        <EntryLayout>
          <AlertBox isOpen={isOpen} setIsOpen={setIsOpen}>
            {error}
          </AlertBox>
          <form onSubmit={handleClick} className="mt-14" autoComplete="off">
            {/* turn on autoComplete */}
            <Email email={email} setemail={setemail}>
              {t("Email")}
            </Email>
            <Password password={password} setpassword={setpassword}>
              {t("Password")}
            </Password>
            <p className="mt-3.5 text-right">
              <a
                className="text-xs font-semibold underline text-black-veryDark"
                href="/forgot"
              >
                Forgot Password?
              </a>
            </p>
            <div className=" flex flex-col items-center justify-center">
              <div className="justify-center flex items-center w-full max-w-xs mb-3">
                <Button>
                  {loading ? <Loader className="white" /> : t("Log In")}
                </Button>
              </div>
              <div className="flex items-center">
                <input
                  id="rememberMe"
                  type="checkbox"
                  className="mr-2"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  aria-label="Remember Me"
                />
                <span className="text-black-veryDark">Remember Me</span>
              </div>
            </div>
            <Redirect t={t} />
          </form>
        </EntryLayout>
      </div>
    </div>
  );
};

export default Login;
