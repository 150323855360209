import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import OpenImage from "./OpenImage";
import OpenVideo from "./OpenVideo";
import lockIcon from "../../assets/SVG/lock.svg";
import coinIcon from "../../assets/SVG/Coin.svg";
import playBtn from "../../assets/SVG/sentPlay.svg";
import pauseBtn from "../../assets/SVG/sentPause.svg";
import voiceGraph from "../../assets/SVG/sentVoice.svg";
/* eslint-disable jsx-a11y/media-has-caption */

const TextMessage = ({ locked, children, coins,time }) => (
  <div className="px-2.5 flex flex-col justify-center items-center min-w-60px relative max-w-208px bg-PurpleGradient py-3 rounded-20px rounded-br-xl">
    <p className={`break-all text-white text-sm ${locked && "filter blur"}`}>
      {children}
    </p>
    {locked && (
      <>
        <img
          className={`absolute ${children.length < 50 && "top-1"}`}
          src={lockIcon}
          alt="lock"
        />
        <div className="flex pt-5 items-center font-bold text-black-light text-xl">
          <img className="mr-1.5" src={coinIcon} alt="coin" />
          {coins}
        </div>
      </>
    )}
    <div
      style={{
        position: "absolute",
        right: 10,
        bottom: 0,
        fontSize: "9px",
        color: "white",
      }}
    >
      {time}
    </div>
  </div>
);

const PictureMessage = ({ locked, id, imgSrc, coins, wasLocked,time }) => {
  const [isLoading, setisLoading] = useState(true);
  const [isOpen, setisOpen] = useState(false);
  return (
    <>
      <div
        className={`flex flex-col justify-center items-center w-140px box-content px-2.5 py-3 bg-PurpleGradient rounded-20px rounded-br-xl ${
          locked && ""
        }`}
      >
        {isLoading && (
          <div className="rounded-10px h-180px w-140px animate-pulse bg-gray-veryLight/50 absolute" />
        )}
        <img
          onClick={() => setisOpen(true)}
          className={`rounded-10px object-cover object-center transition-opacity w-140px h-180px ${
            locked && "filter blur-[12px]"
          }  ${isLoading && "opacity-0"}`}
          src={imgSrc}
          alt={id && id}
          onLoad={() => setisLoading(false)}
        />
        {locked && (
          <>
            <img className="absolute" src={lockIcon} alt="lock" />
            <div
              className={`flex pt-3 items-center font-bold text-black-light text-xl ${
                isLoading && "opacity-0"
              }`}
            >
              <img className="mr-1.5" src={coinIcon} alt="coin" />
              {coins}
            </div>
          </>
        )}
        {!locked && wasLocked && (
          <div
            className={`flex pt-2.5 items-center font-bold text-white text-xl ${
              isLoading && "opacity-0"
            }`}
          >
            Unlocked
          </div>
        )}
      </div>
      <div
        style={{
          fontSize: "9px",
          color: "white",
          position: "relative",
          bottom: "-192px",
          left: "-44px",
        }}
      >
        {time}
      </div>
      {!locked && (
        <OpenImage imgSrc={imgSrc} isOpen={isOpen} setisOpen={setisOpen} />
      )}
    </>
  );
};

const VoiceMessage = ({ wasLocked, locked, audioSrc, coins,time }) => {
  const [isPlaying, setisPlaying] = useState(false);
  const voiceRef = useRef(null);
  const play = () => {
    voiceRef.current.play();
    setisPlaying(!isPlaying);
  };
  const stop = () => {
    voiceRef.current.pause();
    setisPlaying(!isPlaying);
  };
  return (
    <>
      <div className="flex flex-col w-[170px] justify-center items-center box-content px-2.5 py-3 bg-PurpleGradient rounded-20px rounded-br-xl">
        <div
          className={`flex w-full px-2 rounded-60px justify-between items-center ${
            locked && "filter blur-[10px]"
          }`}
        >
          <div className="max-w-fit items-center mt-2">
            {isPlaying ? (
              <button type="button" onClick={stop}>
                <img src={pauseBtn} alt="stop" />
              </button>
            ) : (
              <button type="button" onClick={play}>
                <img src={playBtn} alt="play" />
              </button>
            )}
          </div>
          <audio
            onEnded={() => setisPlaying(!isPlaying)}
            className="hidden"
            ref={voiceRef}
            src={audioSrc}
          />
          <div className="max-w-fit items-center">
            <img className="ml-2" src={voiceGraph} alt="voice" />
          </div>
        </div>
        {locked && (
          <>
            <img className="absolute" src={lockIcon} alt="lock" />
            <div className="flex pt-3 items-center font-bold text-black-light text-xl">
              <img className="mr-1.5" src={coinIcon} alt="coin" />
              {coins}
            </div>
          </>
        )}
        {!locked && wasLocked && (
          <div className="flex pt-2.5 items-center font-bold text-white text-xl">
            Unlocked
          </div>
        )}
        <div
          style={{
            fontSize: "9px",
            color: "white",

            position: "relative",
            bottom: "-9px",
            right: "-70px",
          }}
        >
          {time}
        </div>
      </div>

      {/* {!locked && (
        <OpenImage imgSrc={imgSrc} isOpen={isOpen} setisOpen={setisOpen} />
      )} */}
    </>
  );
};

const VideoMessage = ({ wasLocked, locked, videoSrc, id, coins,time }) => {
  const [isLoading, setisLoading] = useState(true);
  const [isOpen, setisOpen] = useState(false);
  return (
    <>
      <div
        className={`flex flex-col justify-center items-center w-140px box-content px-2.5 py-3 bg-PurpleGradient rounded-20px rounded-br-xl ${
          locked && ""
        }`}
      >
        {isLoading && (
          <div className="rounded-10px h-180px w-140px animate-pulse bg-gray-veryLight/50 absolute" />
        )}
        <img
          onClick={() => setisOpen(true)}
          className={`rounded-10px object-cover object-center w-140px transition-opacity h-180px ${
            locked && "filter blur-[10px]"
          }  ${isLoading && "opacity-0"}`}
          src={videoSrc.thumbnail}
          alt={id && id}
          onLoad={() => setisLoading(false)}
        />
        {!locked && (
          <img
            onClick={() => setisOpen(true)}
            className="absolute"
            src={playBtn}
            alt="play"
          />
        )}
        {locked && (
          <>
            <img className="absolute" src={lockIcon} alt="lock" />
            <div
              className={`flex pt-3 items-center font-bold text-black-light text-xl ${
                isLoading && "opacity-0"
              }`}
            >
              <img className="mr-1.5" src={coinIcon} alt="coin" />
              {coins}
            </div>
          </>
        )}
        {!locked && wasLocked && (
          <div
            className={`flex pt-2.5 items-center font-bold text-white text-xl ${
              isLoading && "opacity-0"
            }`}
          >
            Unlocked
          </div>
        )}
        <div
          style={{
            position: "absolute",
            right: 10,
            bottom: 0,
            fontSize: "9px",
            color: "white",
          }}
        >
          {time}
        </div>
      </div>
      {!locked && (
        <OpenVideo
          id={id}
          thumbnail={videoSrc.thumbnail}
          videoSrc={videoSrc.video}
          isOpen={isOpen}
          setisOpen={setisOpen}
        />
      )}
    </>
  );
};

const SentMessage = ({
  children,
  type,
  mediaSrc,
  id,
  date,
  isLocked,
  coins,
  wasLocked,
  translateText,
  deleteMessage,
  fetchUserChat,
  // props required for message translation:
  setMessageToEdit,
  setIsEdited,
  editedMessage,
}) => {
  const [value, setValue] = useState(children);
  const [loading, setLoading] = useState(false);
  const [translated, setTranslated] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const time = moment(date).format("h:mma");
  const handleTranslate = () => {
    setLoading(true);
    if (!translated) {
      translateText(children, "DE", "EN")
        .then((translatedText) => {
          setValue(translatedText);
          setTranslated(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setValue(children);
      setTranslated(false);
      setLoading(false);
    }
  };

  const handleOptionsClick = () => {
    setShowOptions(!showOptions);
  };

  const handleDeleteMessage = () => {
    deleteMessage(id).then(() => {
      setShowOptions(false);
      fetchUserChat();
    });
  };
  const handleEditMessage = () => {
    setMessageToEdit({ id, message: children });
    setShowOptions(false);
    setIsEdited(true);
  };

  useEffect(() => {
    if (editedMessage && editedMessage.id === id) {
      setValue(editedMessage.message);
    }
  }, [editedMessage]);

  let buttonContent;
  if (loading) {
    buttonContent = "Loading...";
  } else if (translated) {
    buttonContent = "Revert";
  } else {
    buttonContent = "To English";
  }

  let component;
  switch (type) {
    case "text":
      component = (
        <TextMessage locked={isLocked} coins={coins} time={time}>
          {value}
        </TextMessage>
      );
      break;
    case "image":
      component = (
        <PictureMessage
          locked={isLocked}
          id={id}
          imgSrc={mediaSrc}
          coins={coins}
          wasLocked={wasLocked}
          time={time}
        />
      );
      break;
    case "video":
      component = (
        <VideoMessage
          id={id}
          videoSrc={mediaSrc}
          locked={isLocked}
          imgSrc={mediaSrc}
          coins={coins}
          wasLocked={wasLocked}
          time={time}
        />
      );
      break;
    case "audio":
      component = (
        <VoiceMessage
          locked={isLocked}
          id={id}
          audioSrc={mediaSrc}
          coins={coins}
          wasLocked={wasLocked}
          time={time}
        />
      );
      break;
    default:
      console.log("none");
  }
  return (
    <div className="flex justify-end">
      {showOptions && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {id && ( // Only render "Delete" if id exists
            <div
              style={{
                border: "1px solid lightgrey",
                backgroundColor: "#f4f4f4",
                padding: "5px",
                borderRadius: "5px",
                marginRight: "10px", // Add margin to create space between options
              }}
            >
              <button type="button" onClick={handleDeleteMessage}>
                Delete
              </button>
            </div>
          )}
          {type === "text" &&
            id && ( // Only render "Edit" for text messages with id
              <div
                style={{
                  border: "1px solid lightgrey",
                  backgroundColor: "#f4f4f4",
                  padding: "5px",
                  borderRadius: "5px",
                  marginRight: "10px", // Add margin to create space between options
                }}
              >
                <button type="button" onClick={handleEditMessage}>
                  Edit
                </button>
              </div>
            )}
          {type === "text" && ( // Only render "To English" for text messages
            <div
              style={{
                border: "1px solid lightgrey",
                backgroundColor: "#f4f4f4",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <button type="button" onClick={handleTranslate}>
                {buttonContent}
              </button>
            </div>
          )}
        </div>
      )}
      <div
        style={{ cursor: "pointer" }}
        onClick={handleOptionsClick}
        role="button"
        tabIndex="0"
      >
        ...
      </div>
      {component}
    </div>
  );
};

export default SentMessage;
