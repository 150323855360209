import React, { createContext, useState } from "react";

export const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [userList, setuserList] = useState([]);
  const [filterUser, setFilterUser] = useState([]);
  const [broadcastUserList, setbroadcastUserList] = useState([]);
  const [updatedUserList, setupdatedUserlist] = useState([]);
  const [readId, setreadId] = useState("");

  const states = {
    initialState: [userList, setuserList],
    filteredState: [filterUser, setFilterUser],
    initialBroadcastState: [broadcastUserList, setbroadcastUserList],
    updatedState: [updatedUserList, setupdatedUserlist],
    readState: [readId, setreadId],
  };
  const value = React.useMemo(() => states, [states]);
  return (
    <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
  );
};
