import React from 'react'
import ModalLayout from '../../../../templates/ModalLayout'
import SettingsPanel from '../../SettingsPanel'

const ControPanel = ({ isOpen, setisOpen }) => (
  <ModalLayout title="ControlPanel" isOpen={isOpen} setisOpen={setisOpen}>
    <section className="px-5 mt-5"
      style={{ borderBottom: '1px solid' }}
    >
      <div style={{ fontSize: "22px", fontWeight: "bold" }}>
        IOS
      </div>
      <div className="flex justify-between mb-5 mt-14">
        <SettingsPanel name="Picks" navigate="/picks" />
        <SettingsPanel name="ReadyToMeet" navigate="/ready-to-meet" />
      </div>
    </section>

    <section className="px-5 mt-5"
      style={{ borderBottom: '1px solid' }}
    >
      <div style={{ fontSize: "22px", fontWeight: "bold" }}>
        Android
      </div>
      <div className="flex justify-between mb-5 mt-14">
        <SettingsPanel name="Picks" navigate="/picks?platform=android" />
        <SettingsPanel name="ReadyToMeet" navigate="/ready-to-meet?platform=android" />
      </div>
    </section>
  </ModalLayout>
)

export default ControPanel
