/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

const ffmpeg = createFFmpeg({ log: true });

const VideoCompressor = () => {
  const [ready, setReady] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [outputVideo, setOutputVideo] = useState(null);
  const [progress, setProgress] = useState(0);
  const [converting, setConverting] = useState(false);
  const [error, setError] = useState(null);
  const [resolution, setResolution] = useState(720); // Default resolution 720p

  useEffect(() => {
    const loadFFmpeg = async () => {
      try {
        await ffmpeg.load();
        setReady(true);
      } catch (e) {
        setError('Failed to load ffmpeg.');
        console.error(e);
      }
    };

    loadFFmpeg();
  }, []);

  const handleFileChange = (event) => {
    setVideoFile(event.target.files[0]);
  };

  const handleConvert = async () => {
    try {
      setConverting(true);
      setError(null);
      setProgress(0);

      // Turn file data into Uint8Array
      const fileData = await fetchFile(videoFile);

      // Write the file to the filesystem
      ffmpeg.FS('writeFile', 'input.mp4', fileData);

      // Start the conversion process with resolution selection
      ffmpeg.setProgress(({ ratio }) => setProgress(Math.round(ratio * 100)));
      await ffmpeg.run(
        '-i',
        'input.mp4',
        '-vf',
        `scale=-2:${resolution}`,
        '-c:v',
        'libx264',
        '-crf',
        '28',
        '-preset',
        'fast',
        'output.mp4'
      );

      // Read the output file
      const data = ffmpeg.FS('readFile', 'output.mp4');
      const videoBlob = new Blob([data.buffer], { type: 'video/mp4' });
      const videoURL = URL.createObjectURL(videoBlob);

      setOutputVideo(videoURL);
      setConverting(false);
    } catch (er) {
      setError('An error occurred during conversion.');
      setConverting(false);
      console.error(er);
    }
  };

  const saveFile = () => {
    const link = document.createElement('a');
    link.href = outputVideo;
    link.setAttribute('download', 'compressed_video.mp4');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  if (!ready) {
    return <p>Loading ffmpeg...</p>;
  }

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '40px',
      backgroundColor: '#f9f9f9',
      height: '100vh',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      fontFamily: 'Arial, sans-serif',
    },
    heading: {
      marginBottom: '20px',
      fontSize: '2rem',
      color: '#333',
    },
    input: {
      marginBottom: '20px',
    },
    select: {
      padding: '5px',
      fontSize: '1rem',
      marginTop: '10px',
      marginBottom: '20px',
      borderRadius: '5px',
      border: '1px solid #ccc',
    },
    button: {
      marginTop: '10px',
      padding: '10px 20px',
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
    },
    buttonHover: {
      backgroundColor: '#0056b3',
    },
    progressText: {
      fontSize: '1rem',
      marginTop: '10px',
    },
    video: {
      marginTop: '20px',
      border: '2px solid #ccc',
      borderRadius: '8px',
    },
    errorText: {
      color: 'red',
      fontSize: '1rem',
      marginTop: '10px',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Video Compressor</h1>

      {!videoFile && (
        <div>
          <input type="file" accept="video/mp4" onChange={handleFileChange} style={styles.input} />
        </div>
      )}

      {videoFile && !outputVideo && (
        <div>
          <label htmlFor="resolution">Select Resolution:</label>
          <select
            id="resolution"
            value={resolution}
            onChange={(e) => setResolution(e.target.value)}
            style={styles.select}
          >
            <option value={480}>480p</option>
            <option value={720}>720p</option>
            <option value={1080}>1080p</option>
          </select>
        </div>
      )}

      {videoFile && !outputVideo && !converting && (
        <button
          onClick={handleConvert}
          style={styles.button}
          onMouseOver={(e) => (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)}
          onMouseOut={(e) => (e.target.style.backgroundColor = styles.button.backgroundColor)}
        >
          Convert Video
        </button>
      )}

      {converting && (
        <div>
          <p style={styles.progressText}>Converting: {progress}%</p>
        </div>
      )}

      {outputVideo && (
        <div>
          <video src={outputVideo} controls width="400" style={styles.video} />
          <br />
          <button
            onClick={saveFile}
            style={styles.button}
            onMouseOver={(e) => (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)}
            onMouseOut={(e) => (e.target.style.backgroundColor = styles.button.backgroundColor)}
          >
            Save Video
          </button>
        </div>
      )}

      {error && <p style={styles.errorText}>{error}</p>}
    </div>
  );
};

export default VideoCompressor;
