import React from 'react'
import IOSDialog from '../../../../shared/IOSDialog'
import useAuth from '../../../../hooks/useAuth'
import axios from '../../../../api/axios'

const DelProfile = ({ isOpen, setisOpen }) => {
  const { getAccessToken, logout } = useAuth()
  const token = getAccessToken()
  const DELETE_URL = '/user'
  const update = () => {
    setisOpen(!isOpen)
  }
  const deleteUser = async () => {
    try {
      const response = await axios.delete(DELETE_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (response?.data?.Message) {
        console.log(response?.data?.Message)
        console.log('delete user')
        update()
        logout()
      }
    } catch (e) {
      console.log('error', e)
    }
  }
  return (
    <IOSDialog update={update} isOpen={isOpen} title="Delete Account?">
      <p className="mt-0.5 text-sm text-center px-5">
        This action deletes all your data and is irreversible.
      </p>
      <div className="mt-5 border-t-0.5px border-gray-divider px-4 grid grid-cols-2 w-full">
        <button
          type="button"
          className="border-r-0.5px border-gray-divider py-3 text-[#007AFF] text-lg"
          onClick={update}
        >
          Cancel
        </button>
        <button
          type="button"
          className="text-[#FF6464] text-lg font-semibold"
          onClick={deleteUser}
        >
          Delete
        </button>
      </div>
    </IOSDialog>
  )
}

export default DelProfile
