import React, { useEffect, useState } from 'react'
import ModalLayout from '../../../../templates/ModalLayout'

const Name = ({ isOpen, setisOpen, content, setuser, callApi }) => {
  const [value, setvalue] = useState('')
  useEffect(() => {
    console.log('Name Intiatiated')
    setvalue(content)
  }, [isOpen])

  const update = () => {
    if (content !== value) {
      callApi({ firstname: value })
      setuser((prev) => ({ ...prev, firstname: value, isUpdated: true }))
    }
  }
  return (
    <ModalLayout
      title="Name"
      hasTick
      isOpen={isOpen}
      setisOpen={setisOpen}
      handleClick={update}
    >
      <div className="mt-5 mx-5">
        <p className="text-xs">What would you like to be called ?</p>
        <input
          spellCheck={false}
          className="mt-1.5 pb-1.5 border-b-0.5px w-full font-semibold text-sm textbox-highlight"
          type="text"
          value={value}
          placeholder="Type something"
          onChange={(e) => setvalue(e.target.value)}
        />
      </div>
    </ModalLayout>
  )
}

export default Name
