import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import BackBtn from '../shared/BackBtn'
import Tick from '../assets/SVG/profileTick.svg'

const ModalLayout = ({
  hasTick,
  children,
  title,
  isOpen,
  setisOpen,
  handleClick,
}) => {
  const close = () => {
    setisOpen(!isOpen)
  }
  const update = async () => {
    await handleClick()
    setisOpen(!isOpen)
  }
  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={() => setisOpen(true)}
      >
        <Dialog.Overlay className="fixed inset-0 -z-10 bg-white" />
        <div className="flex p-5 justify-between items-center">
          <div className="space-x-5 flex">
            <BackBtn handleClick={close} />
            <Dialog.Title className="font-bold text-2xl">{title}</Dialog.Title>
          </div>
          <button
            type="button"
            className={`${hasTick ? 'block' : 'hidden'}`}
            onClick={update}
          >
            <img src={Tick} alt={Tick} />
          </button>
        </div>
        {children}
      </Dialog>
    </Transition>
  )
}

export default ModalLayout
